(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('orders', {
            parent: 'entity',
            url: '/orders?page&sort&search&state&id&sampleCount&turnaroundTimes&dateReceivedLower&dateReceivedUpper&states',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Orders'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/orders/orders.html',
                    controller: 'OrdersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'dateReceived,desc',
                    squash: true
                },
                search: null,
                state: {
                    value: 'ALL',
                    squash: true
                },
                items: null,

                // Order Filters
        		id: null,
        		sampleCount: null,
        		turnaroundTimes: null,
        		dateReceivedLower: null,
        		dateReceivedUpper: null,
        		states: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        state: $stateParams.state,
                        items: $stateParams.items,

                        // Order Filters
                		id: $stateParams.id ? parseInt($stateParams.id) : null,
                		sampleCount: $stateParams.sampleCount ? parseInt($stateParams.sampleCount) : null,
                		turnaroundTimes: $stateParams.turnaroundTimes,
                		dateReceivedLower: $stateParams.dateReceivedLower,
                		dateReceivedUpper: $stateParams.dateReceivedUpper,
                		states: $stateParams.states
                    };
                }],
            }
        })
        .state('orders.view', {
            parent: 'orders',
            url: '/{orderId}/view',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/orders/orders-dialog-old.html',
                    controller: 'OrdersDialogController',
                    controllerAs: 'vm',
                    // comment this to close modal on outside click backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CustomerOrder', function(CustomerOrder) {
                            return CustomerOrder.get({id : $stateParams.orderId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('customer-order', null, { reload: 'customer-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        });

    }


})();
