(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('FaqCategoryDetailController', FaqCategoryDetailController);

    FaqCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity','FAQService' ];

    function FaqCategoryDetailController($scope, $rootScope, $stateParams, previousState, entity, FAQService ) {
        var vm = this;

        vm.categories = entity;
        vm.previousState = previousState.name;
        vm.deleteFaq = deleteFaq;

        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "FAQ";
        }

        function deleteFaq(id){
            FAQService.deleteFaq(id).then(function (response) {
                const index = vm.categories.faq.findIndex( function(element){
                    return element === id
                });
                vm.categories.faq.splice(index,1)
            }).catch(function (error) {
                console.log(error)
            })
        }

        var unsubscribe = $rootScope.$on('anrescowebApp:FaqManage', function(event, result) {
            vm.categories = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
