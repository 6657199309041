(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('DialogSampleColumns', DialogSampleColumns);

    DialogSampleColumns.$inject = ['$state', '$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'Principal', 'Auth'];

    function DialogSampleColumns ($state, $timeout, $scope, $rootScope, $stateParams, $uibModalInstance, Principal, Auth) {

        var vm = this;
        vm.userAccount = null;

        vm.clear = function(){
        	$uibModalInstance.dismiss('cancel');
        }
        vm.save = function(){
        	if (!vm.userAccount.userSettings) {
        		vm.userAccount.userSettings = JSON.stringify({sample_columns: vm.columns});
        	} else {
        		var userSettings = JSON.parse(vm.userAccount.userSettings);
        		userSettings.sample_columns = vm.columns;
        		vm.userAccount.userSettings = JSON.stringify(userSettings);
        	}
        	
            Auth.updateAccount(vm.userAccount).then(function() {
            	vm.clear();
            }).catch(function() {
                console.log("ERR");
            });
        	
        }
        
        vm.columns = [
    		{
    			display_name: 'Sample No.',
    			sort_by_name: 'id',
    			shown: true,
    		},
    		{
    			display_name: 'Name',
    			sort_by_name: 'name',
    			shown: true,
    		},
    		{
    			display_name: 'Category (Sub)',
    			sort_by_name: 'productType.name',
    			shown: true,
    		},
    		{
    			display_name: 'THC',
    			sort_by_name: 'thc',
    			shown: true,
    		},
    		{
    			display_name: 'CBD',
    			sort_by_name: 'cbd',
    			shown: true,
    		},
    		{
    			display_name: 'Completed Date',
    			sort_by_name: 'customerOrder.dateCompleted',
    			shown: true,
    		},
    		/*
    		{
    			display_name: 'Public',
    			sort_by_name: 'isPublic',
    			shown: true,
    		},
    		*/
    		{
    			display_name: 'Downloads',
    			sort_by_name: '',
    			shown: true,
    		}
    	];

        Principal.identity().then(function(account) {
            vm.userAccount = account;
            if (account.userSettings) {
            	var userSettings = JSON.parse(vm.userAccount.userSettings);
            	if (userSettings.sample_columns){
            		vm.columns = userSettings.sample_columns;
            	}
            }
        });

        $rootScope.$on('closeModal', function (event, data) {
            vm.clear();
        });

    }
})();
