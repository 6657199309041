angular.module('anrescowebApp')

    .factory('FAQService',['$http', function ($http) {
        return {
            getAllFaq: function () {
                return $http.get('/api/faq').then(function (response) {
                    return response;
                });
            },
            getFaq: function (id) {
                return $http.get('/api/faq/'+id).then(function (response) {
                    return response;
                });
            },
            deleteFaq: function (id) {
                return $http.delete('/api/faq/'+id).then(function (response) {
                    return response;
                });
            },
            getAllFaqCategories: function () {
                return $http.get('/api/faq-category').then(function (response) {
                    return response;
                });
            },
            createNewFaq: function (data) {
                return $http.post('/api/faq', data).then(function (response) {
                    return response;
                });
            },
            updateFaq: function (data) {
                return $http.put('/api/faq', data).then(function (response) {
                    return response;
                });
            }
        };
    }]);
