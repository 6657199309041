(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('SignupController', SignupController);

    SignupController.$inject = ['$state', '$stateParams', 'SquareService', 'SweetAlert', 'CustomerAccount', 'Config', 'CustomerById', 'Quotation', '$scope'];

    function SignupController ($state, $stateParams, SquareService, SweetAlert, CustomerAccount, Config, CustomerById, Quotation, $scope) {

        var vm = this;

        angular.element(document).ready(function() {
            angular.element('#wrap').removeClass("wrap");
            $('#t_and_c').on('scroll', chk_scroll);
        });

        $state.INTERNAL_KEY = null;
        vm.loading_quotation = true;
        vm.quotation_valid = null;
        vm.error_message = null;
        vm.ip_info = null;
        vm.submitting = false;
        var square = {};

        vm.nav = {
            next_enabled: true,
            prev_enabled: false,
            show_next: true,
            show_prev: false
        }

        const squareCardInputStyles = {
            '.input-container': {
                borderColor: '#e6ebf0',
                borderRadius: '4px',
              },
              '.input-container.is-focus': {
                borderColor: '#e6ebf0'
              },
              'input::placeholder': {
                color: '#c1c5c9'
              },
              input: {
                color: '#3d5269'
              }
        }

        if($state.$current.data.pageTitle == 'Signup') {
            vm.state = 'quotation';

            fetch("https://ipinfo.io/json?token=818194d3986134").then(
                function(res) {
                    return res.json();
                }
            ).then( function(data) {
                vm.ip_info = data;
            });
        }
        else {
            vm.state = 'success';
            $state.go('signup.success');
        }

        function getInternalKey() {
            Config.generic({data: 'INTERNAL_KEY'},
                function(data) {
                    $state.INTERNAL_KEY = data.data;
                },
                function(e) {
                    console.log(e);
                }
            )
        }

        function getQuotationById(id) {
            Quotation.get({id: id},
                function(data) {
                    if(validRes(data)) {
                        vm.quotation = data;
                        getCustomer(data.customer_id);
                        return data;
                    }
                },
                function(e) {
                    console.log(e);
                });
        }

        if(vm.state == 'quotation' && $stateParams.quotationId == 'new' ) {
            vm.loading_quotation = false;
            vm.quotation_valid = true;
        }
        else if(vm.state == 'quotation' && $stateParams.quotationId != 'success' ) {
            if($stateParams.quotationId.length < 15) {
                showErrorMessage('Error', 'Invalid quotation ID.')
                vm.nav.next_enabled = false;
                vm.quotation_valid = false;
            }
            else {
                vm.quotation_valid = true;

                Quotation.get({id: $stateParams.quotationId},
                    function(data) {
                        if(validRes(data)) {
                            if(data.expiration_date) {
                                var today = new Date(moment())
                                var expiration_date = new Date(data.expiration_date)

                                if(today > expiration_date) {
                                    showErrorMessage('Error', 'Quotation is expired.');
                                    return;
                                }
                            }

                            vm.quotation = data;

                            if(vm.quotation.status != 'ACCEPTED') {
                                Quotation.update({id: vm.quotation.id, status: 'ACCEPTED'},
                                    function(d) {
                                        setTimeout(function(){ getQuotationById(vm.quotation.id); }, 3000);
                                    },
                                    function(e) {
                                        // show error message here and quit process
                                        showErrorMessage('Error', 'Could not update quotation.');
                                        console.log(e);
                                    }
                                );
                            }
                            else {
                                getCustomer(vm.quotation.customer_id)
                            }
                        }
                    },
                    function(e) {
                        console.log(e);
                    }
                );
            }
        }
        // remove this
        vm.changeState = function() {
            $state.go('signup.success');
        }

        function validRes(res) {
            if(res.message) {
                if(res.message == 'A validation error has occurred.') {
                    showErrorMessage('Error', res.data);
                    return false;
                }
            }
            return true;
        }

        function invalidatePage() {
            vm.nav.next_enabled = false;
            vm.current_step.next_active = false;
            vm.current_step.prev_active = false;
            vm.steps.terms_and_conditions.submit_active = false;
            vm.quotation_valid = false;
            vm.loading_quotation = false;
        }

        function getCustomer(customer_id) {
            vm.steps.company_info.company.id = customer_id;
            CustomerById.get({id: customer_id},
                function(data) {
                    if(validRes(data)) {
                    // extract contact id
                        vm.steps.company_info.company.business_name = data.company_name ? data.company_name : null;
                        if(data.company_name) {
                            vm.steps.company_info.company.business_name = data.company_name;
                        }
                        else {
                            vm.steps.company_info.company.business_name = data.name
                        }
                        if(data.contacts) {
                            vm.steps.primary_contact.contact.id = data.contacts[0].id ? data.contacts[0].id : null;
                            vm.steps.primary_contact.contact.first_name = data.contacts[0].first_name ? data.contacts[0].first_name : null;
                            vm.steps.primary_contact.contact.last_name = data.contacts[0].last_name ? data.contacts[0].last_name : null;
                            vm.steps.primary_contact.contact.email_address = data.contacts[0].email ? data.contacts[0].email : null;

                        }
                    }
                    vm.steps.company_info.checkRequiredFields();
                    vm.loading_quotation = false;
                },
                function(e) {
                    // show error message here and quit processx
                    vm.steps.company_info.checkRequiredFields();
                    showErrorMessage('Error', 'Could not retrieve customer.')
                    console.log(e)
                }
            );
        }

        vm.getCustomerComments = function() {
            var comments = '--- Onboarding Completed via Portal --- ';
            comments += '\n Signed by: ' + vm.steps.terms_and_conditions.signed_by;
            comments += '\n Date: ' + moment().format('MMMM Do YYYY, h:mm:ss a');

            if(vm.ip_info) {
                comments += '\n IP Address: ' + vm.ip_info.ip;
                comments += '\n ' + vm.ip_info.city + ', ' + vm.ip_info.region + ' ' + vm.ip_info.postal + ' (estimated)'
                comments += '\n Location: ' + vm.ip_info.loc + ' (estimated)';
            }

            comments += '\n --- End of Onboarding Info --- ';

            return comments;
        }

        function chk_scroll(e) {
            var elem = $(e.currentTarget);
            if ((elem[0].scrollHeight - elem.scrollTop()) < (elem.outerHeight()+500)) {
                angular.element('#read_terms').removeClass("disabled-signup");
            }
        }

        vm.steps = {
            submitAll: function() {
                vm.submitting = true;
                var payload = {}
                var company_info = this.company_info.serializeCustomer()
                var billing_info = {
                    selected_payment_method: this.billing_info.selected_payment_method.display ? this.billing_info.selected_payment_method.display : null,
                    nonce: this.billing_info.square_info.nonce ? this.billing_info.square_info.nonce : null,
                    cardholder_name: this.company_info.company.business_name ? this.company_info.company.business_name : null,
                    customer_name: this.company_info.company.business_name ? this.company_info.company.business_name : null,
                    invoicing_notes : this.billing_info.invoicing_notes ? this.billing_info.invoicing_notes : null
                };

                var primary_contact = this.primary_contact.serializePrimaryContact();

                var contacts = [];
                this.additional_contacts.contacts.forEach( function(contact) {
                    contacts.push(vm.steps.additional_contacts.serializeContact(contact))
                });

                payload = {
                    customer_info: company_info,
                    billing_info: billing_info,
                    primary_contact: primary_contact,
                    contacts_info: contacts
                }

                CustomerAccount.onboard(payload,
                    function(data) {
                        invalidatePage()
                        vm.submitting = false;
                        $state.go('signup.success');
                    },
                    function(e) {
                        vm.submitting = false;
                        var message = e.data && e.data.message ? e.data.message : 'An unhandled exception occurred.'
                        invalidatePage();
                        showErrorMessage('Error', message)
                        console.log(e);
                    }
                );
            },
            company_info : {
                prev: '',
                current: 'company_info',
                next: 'primary_contact',
                title: 'Company Information',
                description: '',
                status: 'in-progress',
                is_active: false,
                show_next: true,
                show_prev: false,
                next_active: false,

                industries: ['Cannabis', 'Food', 'Hemp', 'Kratom', 'FDA DWPE'],
                company: {
                    id: null,
                    business_name: '',
                    industry: '',
                    industry: '',
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    country: '',
                    main_phone: '',
                    license_number: ''
                },
                signed_by: '',
                checkRequiredFields: function() {
                    if(
                        this.company.business_name &&
                        this.company.industry &&
                        this.company.address &&
                        this.company.city &&
                        this.company.state &&
                        this.company.zip_code &&
                        this.company.country &&
                        this.company.main_phone
                    ) {
                        this.next_active = true;
                    }
                    else {
                        this.next_active = false;
                    }
                },
                createCustomerAddress: function() {
                    var address = '';
                    address += this.company.business_name + '\n';
                    address += this.company.address + '\n';
                    address += this.company.city + ', ' + this.company.state + ' ' + this.company.zip_code;
                    if(this.company.license_number) {
                        address += '\n' + this.company.license_number;
                    }

                    return address;
                },
                serializeCustomer: function() {
                    // console.log("Country data???",this);
                    return {
                        id: this.company.id,
                        customer_name: this.company.business_name.toUpperCase(),
                        country_name: this.company.country,
                        company_name: this.company.business_name,
                        customer_type: this.company.industry,
                        phone: this.company.main_phone,
                        language: this.company.country.toLowerCase().includes('jap') ? 'Japanese' : 'English',
                        address: this.createCustomerAddress(),
                        comments: vm.getCustomerComments()
                    }
                }
            },
            primary_contact : {
                prev: 'company_info',
                current: 'primary_contact',
                next: 'additional_contacts',
                title: 'Primary Contact',
                description: 'The Primary Contact will automatically have Admin access to edit, add, and delete other users on the company account.',
                status: 'not-started',
                is_active: false,
                show_next: true,
                show_prev: true,
                next_active: false,

                contact: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    title: '',
                    title: '',
                    email_address: '',
                    direct_phone: '',
                    receive_reports: true,
                    receive_invoices: true,
                    is_admin: true
                },
                checkRequiredFields: function() {
                    if(
                        this.contact.first_name &&
                        this.contact.last_name &&
                        this.contact.email_address &&
                        this.contact.direct_phone
                    ) {
                        this.next_active = true;
                    }
                    else {
                        this.next_active = false;
                    }
                },
                serializePrimaryContact: function () {
                    return {
                        id: this.contact.id,
                        first_name: this.contact.first_name,
                        last_name: this.contact.last_name,
                        email_address: this.contact.email_address,
                        phone: this.contact.direct_phone,
                        receive_email: this.contact.receive_reports,
                        receive_invoice_email: this.contact.receive_invoices,
                        is_admin: true
                    }
                }
            },
            additional_contacts : {
                prev: 'primary_contact',
                current: 'additional_contacts',
                next: 'billing_info',
                title: 'Additional Contacts',
                description: '',
                status: 'not-started',
                is_active: false,
                show_next: true,
                show_prev: true,
                next_active: true,


                enable_add_contact: false,
                contact: {
                    first_name: '',
                    last_name: '',
                    title: '',
                    email_address: '',
                    direct_phone: '',
                    receive_reports: false,
                    receive_invoices: false,
                    is_admin: ''
                },
                contacts: [
                ],
                checkRequiredFields: function() {
                    if(this.contact.first_name || this.contact.last_name || this.contact.email_address) {
                        if (this.contact.first_name && this.contact.last_name && this.contact.email_address) {
                            this.enable_add_contact = true;
                        }
                        else {
                            this.enable_add_contact = false;
                        }
                    }
                    else {
                        this.enable_add_contact = false;
                    }
                },
                serializeContact: function (contact) {
                    return {
                        first_name: contact.first_name,
                        last_name: contact.last_name,
                        email_address: contact.email_address,
                        phone: contact.direct_phone,
                        receive_email: contact.receive_reports,
                        receive_invoice_email: contact.receive_invoices,
                        is_admin: contact.is_admin ? true : false
                    }
                },
                addContact: function() {
                    this.contacts.push(this.contact);
                    this.contact = {
                        first_name: '',
                        last_name: '',
                        title: '',
                        email_address: '',
                        direct_phone: '',
                        receive_reports: false,
                        receive_invoices: false,
                        is_admin: ''
                    }
                    this.checkRequiredFields();
                },
                editContact: function(index) {
                    this.contact = this.contacts[index];
                    this.deleteContact(index);
                    this.checkRequiredFields();
                },
                deleteContact: function(index) {
                    this.contacts.splice(index, 1);
                    this.checkRequiredFields();
                }
            },
            billing_info : {
                prev: 'additional_contacts',
                current: 'billing_info',
                next: 'terms_and_conditions',
                title: 'Billing Information',
                description: 'All new clients are required to prepay for their first submission. Please contact your Anresco representative after your first submission if you prefer to be invoiced.',
                status: 'not-started',
                is_active: false,
                show_next: true,
                show_prev: true,
                next_active: false,
                terms_and_conditions_checked: false,
                selected_payment_method: {id: null, display: null},
                payment_methods: [
                    {id: 'credit_card', display: 'Credit Card'},
                    {id: 'cash', display: 'Cash'},
                    {id: 'check', display: 'Check'},
                    {id: 'wire_transfer', display: 'Wire transfer'},
                ],
                wire_transfer_info: [
                    {left: 'Beneficiary:', right: 'ANRESCO, INC.'},
                    {left: 'Bank Account number:', right: '0033618315'},
                    {left: 'Bank Routing:', right: '122000496'},
                    {left: 'Bank Swift Code:', right: 'BOFCUS33'},
                    {left: 'Bank Name:', right: 'Union BANK'},
                    {left: 'Bank Address:', right: '350 California St. Fl 1'},
                    {left: 'Bank City/State:', right: 'San Francisco, CA 94104'},
                ],
                square_info: {
                    nonce: null,
                    api: null,
                    app_id: null,
                    location_id: null
                },
                checkRequiredFields: function() {
                    if(this.selected_payment_method.id) {
                        if(this.selected_payment_method.id == 'credit_card') {
                            createSquarePaymentForm();
                        }
                        else {
                            resetPaymentForm(false);
                        }

                        if(this.selected_payment_method.id == 'credit_card' && this.square_info.nonce != null) {
                            this.next_active = true;
                        }
                        else if(this.selected_payment_method.id == 'credit_card' && this.square_info.nonce == null) {
                            this.next_active = false;
                        }
                        else {
                            this.next_active = true;
                        }
                    }
                    else {
                        this.next_active = false;
                    }
                }
            },
            terms_and_conditions : {
                prev: 'billing_info',
                current: 'terms_and_conditions',
                next: '',
                title: 'Terms and Conditions',
                description: 'Please read the terms and conditions below and sign digitally',
                status: 'not-started',
                is_active: false,
                show_next: false,
                show_prev: true,

                agree_terms: false,
                signed_by: '',
                submit_active: false,

                checkRequiredFields: function() {
                    if(this.signed_by.length > 5 && this.agree_terms) {
                        this.submit_active = true;
                    }
                    else {
                        this.submit_active = false;
                    }
                }
            }
        };

        function createSquarePaymentForm() {
            vm.steps.billing_info.square_info.api = Square.payments(vm.steps.billing_info.square_info.app_id, vm.steps.billing_info.square_info.location_id);

            vm.steps.billing_info.square_info.api.card({style:squareCardInputStyles}).then(data => {
                vm.steps.billing_info.square_info.card_api = data;

                vm.steps.billing_info.square_info.card_api.attach('#card-container').then(data => {
                    square.cardButton = document.getElementById('card-button');
                    square.cardButton.addEventListener('click', eventHandler);
                    square.cardButton.disabled = false;
                })
            })

            function eventHandler(event) {
                event.preventDefault();
                resetPaymentForm();

                try {
                    square.cardButton.disabled = true;

                    vm.steps.billing_info.square_info.card_api.tokenize().then(data => {
                        if (data.status === 'OK') {
                            vm.steps.billing_info.square_info.nonce = data.token;

                            SweetAlert.swal({
                                title: 'Card was processed successfully.',
                                text: error_message,
                                type: 'success',
                                confirmButtonColor: '#4f8fcc',
                                confirmButtonText: 'Ok'
                            });
                            vm.steps.billing_info.square_info.card_api.destroy();
                        }
                        else if(data.errors) {
                            var error_message = '';

                            data.errors.forEach(function (error) {
                                console.error('  ' + error.message);
                                error_message = error_message + '- ' + error.message + '\n';
                            });

                            SweetAlert.swal({
                                title: 'There was an issue with your card.',
                                text: error_message,
                                type: 'warning',
                                confirmButtonColor: '#4f8fcc',
                                confirmButtonText: 'Try Again'
                            });
                            resetPaymentForm();
                        }
                        square.cardButton.disabled = false;
                    });
                }
                catch (e) {
                    console.error(e);
                    SweetAlert.swal({
                        title: 'There was an issue with your card.',
                        text: JSON.stringify(e),
                        type: 'warning',
                        confirmButtonColor: '#4f8fcc',
                        confirmButtonText: 'Ok'
                    });
                }
            };
        }

        const resetPaymentForm = (reset=true) => {
            const card_form = document.getElementsByClassName('sq-card-wrapper');
            const card = vm.steps.billing_info.square_info.card_api

            if(card_form.length) {
                const cardButton = document.getElementById('card-button')
                cardButton.replaceWith(cardButton.cloneNode(true));
                card_form[0].remove();
                if(reset){
                    createSquarePaymentForm();
                }
            }
        }

        $scope.$watch('vm.steps.billing_info.square_info.nonce', function (newVal, oldVal) {
            vm.steps.billing_info.checkRequiredFields();
        });

        function appendSquareAPI() {
            Config.generic({data: 'STAGE'},
                function(data) {
                    var script = document.createElement('script');
                    var square_url = null;

                    if(data.data == 'dev') {
                        square_url = 'https://sandbox.web.squarecdn.com/v1/square.js';
                    }
                    else {
                        square_url = 'https://web.squarecdn.com/v1/square.js';
                    }

                    script.type = 'text\/javascript';
                    script.src = square_url;
                    script.id = 'square-api'
                    $("head").append(script)
                },
                function(e) {
                    console.log(e);
                }
            );
        }

        function showErrorMessage(error_title, error_message) {
            vm.error_message = error_message;
            invalidatePage()
            SweetAlert.swal({
                title: error_title,
                text: error_message,
                type: 'error',
                confirmButtonColor: '#4f8fcc',
                confirmButtonText: 'Ok'
            });
        }

        if(vm.state == 'quotation') {
            SquareService.params({},
                function(data) {
                    vm.steps.billing_info.square_info.app_id = data.square_application_id;
                    vm.steps.billing_info.square_info.location_id = data.square_location_id;
                    appendSquareAPI()
                },
                function(e) {
                    console.log(e)
                }
            );
        }

        // change this to change the first page visible

        vm.steps.company_info.is_active = true;
        vm.current_step = vm.steps.company_info;

        // vm.steps.billing_info.is_active = true;
        // vm.current_step = vm.steps.billing_info;


        vm.nav.next = function(event) {
            event.preventDefault();

            if(vm.current_step.next) {
                vm.steps[vm.current_step.current].status = 'complete'
                vm.steps[vm.current_step.next].status = 'in-progress';
                vm.current_step = vm.steps[vm.current_step.next];

                vm.current_step.next ? vm.nav.next_enabled = true : vm.nav.next_false;
                vm.current_step.prev ? vm.nav.prev_enabled = true : vm.nav.prev_false;

                vm.nav.show_next = vm.current_step.show_next;
                vm.nav.show_prev = vm.current_step.show_prev;
            }
        }

        vm.nav.prev = function(event) {
            event.preventDefault();

            if(vm.current_step.prev) {
                vm.steps[vm.current_step.current].status = 'not-started'
                vm.steps[vm.current_step.prev].status = 'in-progress';
                vm.current_step = vm.steps[vm.current_step.prev];

                vm.current_step.next ? vm.nav.next_enabled = true : vm.nav.next_false;
                vm.current_step.prev ? vm.nav.prev_enabled = true : vm.nav.prev_false;

                vm.nav.show_next = vm.current_step.show_next;
                vm.nav.show_prev = vm.current_step.show_prev;
            }
        }

        vm.nav.stepDotsStatus = function(status) {
            if(status == 'not-started') {
                return '<i class=\"fa fa-circle position-background-circle dark-background-circle\"></i><i class=\"fa fa-circle-o position-main-circle main-circle-not-started\"></i>'
            }
            else if(status == 'in-progress') {
                return '<i class=\"fa fa-circle position-background-circle dark-background-circle\"></i><i class=\"fa fa-circle-o position-main-circle main-circle-in-progress\"></i>'
            }
            else if(status == 'complete') {
                return '<i class=\"fa fa-circle position-background-circle white-background-circle\"></i><i class=\"fa fa-check-circle position-main-circle main-circle-complete\"></i>'
            }
        }
    }
})();
