(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth', 'LoginService', 'Contact', 'AlertService'];

    function ResetFinishController ($stateParams, $timeout, Auth, LoginService, Contact, AlertService) {
        var vm = this;

        Contact.getByResetKey({reset_key: $stateParams.key},
            function(data) {
                if(data.reset_key !== $stateParams.key) {
                    vm.disable_page = true;
                }
                else if(data.reset_date) {
                    var expirey_date = moment(data.reset_date).add(1, 'days');
                    var today = moment();
                    if(today > expirey_date) {
                        vm.disable_page = true;
                    }
                }

                if(vm.disable_page) {
                    AlertService.error('This reset link has expired. Please generate a new one.', null, 'top right');
                }

            },
            function(e) {
                console.log(e);
                AlertService.error('This reset link has expired. Please generate a new one.');
                vm.disable_page = true;
            }
        )

        vm.disable_page = false;
        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;

        $timeout(function (){angular.element('#password').focus();});

        angular.element(document).ready(function() {
            angular.element('#wrap').removeClass("wrap");
        });

        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
