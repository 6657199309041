(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sync-qb-log', {
            parent: 'entity',
            url: '/sync-qb-log?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'SyncQbLogs'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sync-qb-log/sync-qb-logs.html',
                    controller: 'SyncQbLogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        })
        .state('sync-qb-log-detail', {
            parent: 'sync-qb-log',
            url: '/sync-qb-log/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'SyncQbLog'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sync-qb-log/sync-qb-log-detail.html',
                    controller: 'SyncQbLogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'SyncQbLog', function($stateParams, SyncQbLog) {
                    return SyncQbLog.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'sync-qb-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('sync-qb-log-detail.edit', {
            parent: 'sync-qb-log-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sync-qb-log/sync-qb-log-dialog.html',
                    controller: 'SyncQbLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SyncQbLog', function(SyncQbLog) {
                            return SyncQbLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sync-qb-log.new', {
            parent: 'sync-qb-log',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sync-qb-log/sync-qb-log-dialog.html',
                    controller: 'SyncQbLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                syncDateTime: null,
                                success: null,
                                updatedContacts: null,
                                updatedCustomers: null,
                                updatedTests: null,
                                updatedSamples: null,
                                updatedOrders: null,
                                deletedContacts: null,
                                deletedCustomers: null,
                                deletedTests: null,
                                deletedSamples: null,
                                deletedOrders: null,
                                errorsGlobal: null,
                                errorsContacts: null,
                                errorsCustomers: null,
                                errorsOrders: null,
                                errorLog: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('sync-qb-log', null, { reload: 'sync-qb-log' });
                }, function() {
                    $state.go('sync-qb-log');
                });
            }]
        })
        .state('sync-qb-log.edit', {
            parent: 'sync-qb-log',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sync-qb-log/sync-qb-log-dialog.html',
                    controller: 'SyncQbLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SyncQbLog', function(SyncQbLog) {
                            return SyncQbLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sync-qb-log', null, { reload: 'sync-qb-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sync-qb-log.delete', {
            parent: 'sync-qb-log',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sync-qb-log/sync-qb-log-delete-dialog.html',
                    controller: 'SyncQbLogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SyncQbLog', function(SyncQbLog) {
                            return SyncQbLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sync-qb-log', null, { reload: 'sync-qb-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
