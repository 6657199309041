(function () {
    'use strict';

    angular.module('anrescowebApp').controller('CustomerPublicPageController', CustomerPublicPageController);

    CustomerPublicPageController.$inject = ['$rootScope', '$scope', '$state', '$localStorage', 'CustomerPublicService', 'data', 'ProductCategory', 'SampleFilter', 'ParseLinks', 'CustomerByUser', 'AlertService'];

    function CustomerPublicPageController($rootScope, $scope,  $state, $localStorage, CustomerPublicService, data, ProductCategory, SampleFilter, ParseLinks, CustomerByUser, AlertService) {

        var vm = this;
        $rootScope.pageTitle = $state.current.data.pageTitle;

        // Pagination vars
        vm.itemsPerPage = 12;
        vm.page = 1;
        vm.totalitems = 0;
        vm.sort = 'customerOrder.dateCompleted,desc';

        // Assign functions
        vm.searchSamples = searchSamples;
        vm.searchClear = searchClear;
        vm.getPotencyValue = getPotencyValue;

        // Retrieve categories
        vm.categories = [{id: -1, name: "All"}];
        vm.selectedCategoryId = -1;
        ProductCategory.query({}, function(categories){
        	if (categories) {
        		for (var i = 0; i < categories.length; i++) {
        			vm.categories.push(categories[i]);
        		}
        	}
        });

        // Process customer data
        if (data) {
        	setCustomerInfo(data);

        } else {
        	CustomerByUser.get({}, function(data) {
        		$localStorage.customer = {id: data.id, dbaName: data.dbaName};
        		$state.go('customerPublic', {dbaName: data.dbaName}, {location: 'replace'})
        	}, function(err){ $state.go('publicCustomerNotFound', {}, {location: 'replace'}) });
        }

        function setCustomerInfo(data) {
        	vm.customer = data;
        	$rootScope.pageTitle = data.name;

        	if (data.logo) {
        		vm.companyLogo = 'images/logo/' + vm.customer.logo;
        	}

        	if (data.address && data.address !== ',,,, ') {
        		var splits = data.address.split('@');
        		vm.customerStreet = splits[0].trim();
        		vm.customerCity = splits[1].trim();
        		vm.customerState = splits[2].trim();
        		vm.customerCountry = splits[3].trim();
        		vm.customerZip = splits[4].trim();

        		if (vm.customerStreet || vm.customerCity || vm.customerState || vm.customerZip){
        			data.address = '';
        			if (vm.customerStreet) {
        				data.address += vm.customerStreet + ', ';
        			}
        			if (vm.customerCity) {
        				data.address += vm.customerCity + (vm.customerState ? ' ' : ', ');
        			}
        			if (vm.customerState) {
        				data.address += vm.customerState + (vm.customerZip ? ', ' : '');
        			}
        			if (vm.customerZip) {
        				data.address += vm.customerZip;
        			}
        		} else {
        			data.address = null;
        		}

        		vm.mapImageUrl = "/api/public/customers/" + data.id + "/map";
        	}

        	searchSamples();
        }
        function createQueryParams() {
        	var params = {};
        	params.customerId = vm.customer.id;
        	if (vm.searchTerm) params.searchTerm = vm.searchTerm;
        	params.categoryId = vm.selectedCategoryId !== -1 ? vm.selectedCategoryId : null;

        	// Set paging params
        	params.page = vm.page - 1;
            params.size = vm.itemsPerPage;
            params.sort = vm.sort;

        	return params;
        }

        function searchSamples() {
        	document.activeElement.blur();
        	SampleFilter.publicQuery(createQueryParams(), function(samples, headers){
        		vm.publicSamples = samples;
                vm.links = headers('link') == null ? null : ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');

        	}, function(error) {
        		AlertService.error("Could not load samples");
        	});
        }

        function searchClear() {
        	vm.searchTerm = null;
        	searchSamples();
        }

        function getPotencyValue(sample, cannabinoid) {
        	if (!sample || !sample.sampleTests) return 'N/A';

        	if (cannabinoid === 'THC') {
        		cannabinoid = 'total_thc_percent';
        	} else if (cannabinoid === 'CBD') {
        		cannabinoid = 'total_cbd_percent';
        	} else if (cannabinoid === 'TOTAL') {
        		cannabinoid = 'total_percent';
        	} else {
        		return "Unknown Cannabinoid";
        	}

        	for (var i = 0; i < sample.sampleTests.length; i++) {
        		var t = sample.sampleTests[i];
        		if (!t.assay || !t.assay.portalIdentifier) continue;
        		
        		if (t.assay.portalIdentifier === "POTENCY_LCDAD" || t.assay.portalIdentifier === "POTENCY_LCDAD_NON_PLANT") {
        			if (t.worksheetData && t.worksheetData[cannabinoid]) {
        				return t.worksheetData[cannabinoid].twoDecimalFormattedValue + ' %';
        			}
        		}
        	}

        	return 'N/A';
        }
    }

})();
