(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('InvoiceAttachDownload', InvoiceAttachDownload);

    InvoiceAttachDownload.$inject = ['$resource'];

    function InvoiceAttachDownload ($resource) {
        var resourceUrl =  '/api/invoices/attachment/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                responseType: 'blob',
                transformResponse: function (data) {
                    return { data: data };
                }
            },
            'merged': {
                method: 'GET',
                url: '/api/invoices/attachment/pdf/merged',
                responseType: 'blob',
                transformResponse: function (data) {
                    return { data: data };
                }
            }
        });
    }
})();
