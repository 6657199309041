//https://stackoverflow.com/questions/21628378/angularjs-display-blob-pdf-in-an-angular-app
angular.module('anrescowebApp')

    .factory('SampleExportService',['$http', function ($http) {
	     return {
	    	 downloadCsv: function (params) {
	             return $http.get('api/samples/export/csv', { responseType: 'arraybuffer', params: params }).then(function (response) {
	                 return response;
	             });
	    	 },
             downloadCoa: function (params) {
                 return $http.get('api/samples/export/coa', { responseType: 'arraybuffer', params: params }).then(function (response) {
                     return response;
                 });
             }
         };
     }]);
