(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('CustomPanel', CustomPanel)

    CustomPanel.$inject = ['$resource'];

    function CustomPanel ($resource) {
        var resourceUrl =  'api/custompanels/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT',
                url: 'api/custompanels/:id'
            },
            'delete': {
                method: 'DELETE',
                url: 'api/custompanels/:id'
            }
        });
    }


})();
