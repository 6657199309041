(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('DialogCustomerManageController', DialogCustomerManageController);

    DialogCustomerManageController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$localStorage', 'entity', 'ContactForUser', '$state'];

    function DialogCustomerManageController ($timeout, $scope, $stateParams, $uibModalInstance, $localStorage, entity, ContactForUser, $state) {

        var vm = this;
        var customerId = $localStorage.customerId;
        vm.contact = entity;
        vm.disablePassword = false;
        vm.disableLogin = false;

        vm.admin = $state.admin;

        if (vm.contact.id == null) {
            vm.newContact = true;
        }
        else {
            vm.newContact = false;
        }
        console.log(vm.contact.email);
        console.log(vm.newContact);
        if (vm.newContact == false && vm.contact.email == null) {
            vm.contactWithoutUser = true;
        }
        else {
            vm.contactWithoutUser = false;
        }

        //if this is existing Contact
        if (vm.newContact == false) {

            if (vm.contactWithoutUser == false) {
                //if contact has assigned User, disable login
                vm.disableLogin = true;
            }

            if (!vm.contact.currentUser && vm.contactWithoutUser == false )
                vm.disablePassword = true

            if (!vm.contact.mainUser && vm.contact.currentUser )
                vm.disableReceive = true

            for (var i = 0; i < vm.contact.contactCustomers.length; i++) {
            	var cc = vm.contact.contactCustomers[i];
            	if (cc.customer_id != $localStorage.customerId) continue;

            	if (cc.receive_coa && cc.receive_invoice) {
            		vm.receive = 'both';
            	} else if (cc.receive_coa) {
            		vm.receive = 'coa';
            	} else if (cc.receive_invoice) {
            		vm.receive = 'inv';
            	} else {
            		vm.receive = 'none';
            	}
            	
            	vm.isAdmin = cc.role === "ADMIN" ? true : false;
            }
        }

        vm.clear = clear;
        vm.save = save;

        vm.doNotMatch = null;
        vm.noPassword = null;
        vm.errorUserExists = null;
        vm.errorEmailExists = null;
        vm.error = null;


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {

            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            if (vm.newContact == false && vm.contact.email == null) {
                vm.contactWithoutUser = true;
            }
            if (vm.newContact == false && vm.contact.password != vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.noPassword = null;
                vm.doNotMatch = 'ERROR';
                return;
            }
            else {
                vm.doNotMatch = null;
            }

            // we need password for new user or contact without user
            // if (vm.newContact==true || vm.contactWithoutUser==true ){
            //    if(vm.contact.password == null ||  vm.contact.password == ''){
            //        vm.error = null;
            //        vm.success = null;
            //        vm.noPassword = 'ERROR';
            //        vm.doNotMatch = null;
            //        return;
            //    }
            // }

            vm.isSaving = true;

            if (vm.newContact) {
                if(vm.receive == 'coa'){
                	vm.contact.receive_coa = true;
                	vm.contact.receive_invoice = false;
                } else if(vm.receive == 'inv'){
                	vm.contact.receive_coa = false;
                	vm.contact.receive_invoice = true;
                } else if(vm.receive == 'both'){
                	vm.contact.receive_coa = true;
                	vm.contact.receive_invoice = true;
                } else {
                	vm.contact.receive_coa = false;
                	vm.contact.receive_invoice = false;
                }
            } else {
                for (var i = 0; i < vm.contact.contactCustomers.length; i++) {
                	var cc = vm.contact.contactCustomers[i];
                	if (cc.customer_id != customerId) continue;

                    if(vm.receive == 'coa'){
                        cc.receive_coa = true;
                        cc.receive_invoice = false;
                    } else if(vm.receive == 'inv'){
                    	cc.receive_coa = false;
                        cc.receive_invoice = true;
                    } else if(vm.receive == 'both'){
                        cc.receive_coa = true;
                        cc.receive_invoice = true;
                    } else {
                    	cc.receive_coa = false;
                    	cc.receive_invoice = false;
                    }
                    
                    if (vm.isAdmin) {
                    	cc.role = "ADMIN"
                    } else {
                    	cc.role = null;
                    }
                }
            }


            vm.contact.name = vm.contact.first_name+" "+vm.contact.last_name;

            if (vm.newContact == false) {
                ContactForUser.update(vm.contact, onSaveSuccess, onSaveError);
            } else {
                ContactForUser.save(vm.contact, onSaveSuccess, onSaveError);
           }

       }

        function onSaveSuccess (result) {

            $uibModalInstance.close(result);
            vm.isSaving = false;

        }

        function onSaveError (response) {
            vm.isSaving = false;
            vm.success = null;
            if (response.status === 400 && response.data.data === 'login already in use') {
                vm.errorUserExists = 'ERROR';
            }
            else if (response.status === 400 && response.data.data === 'This username is already in use') {
                vm.errorUserExists = 'ERROR';
            } else if (response.status === 400 && response.data.data === 'e-mail address already in use') {
                vm.errorEmailExists = 'ERROR';
            } else {
                vm.error = 'ERROR';
            }
        }

    }
})();
