(function() { 
    'use strict'; 
    angular 
        .module('anrescowebApp') 
        .factory('SampleAttachDownload', SampleAttachDownload); 
 
    SampleAttachDownload.$inject = ['$resource']; 
 
    function SampleAttachDownload ($resource) { 
        var resourceUrl =  '/api/samples/attachment/:id'; 
 
        return $resource(resourceUrl, {text: '@text', state: '@state'}, { 
            'query': { method: 'GET', isArray: true}, 
            'get': { 
                method: 'GET', 
                responseType: 'blob', 
                //params: {id_hash: '@id_hash'}, 
                transformResponse: function(data) { 
                    return { data: data } 
                } 
            }, 
            getBadge: { 
                url: 'api/samples/qrcode/badge',
                method: 'GET', 
                responseType: 'blob', 
                params: {text: '@text', state:'@state'}, 
                transformResponse: function(data) { 
                    return { data: data } 
                } 
            },
        }); 
    } 
})(); 