(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('SquareService', SquareService)

    SquareService.$inject = ['$resource'];


    function SquareService ($resource) {
        var resourceUrl =  'api/square/';

        return $resource(resourceUrl, {}, {
            'createPayment': {
                method: 'POST',
                url: 'api/square/payment',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'params': {
                method: 'GET',
                url: 'api/square/params',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
