(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .factory('SampleFilter', SampleFilter);

    SampleFilter.$inject = ['$resource', 'DateUtils'];

    function SampleFilter($resource, DateUtils) {
        var resourceUrl =  'api/samples/filter';

        return $resource(resourceUrl, {}, {
            'query': {
            	method: 'GET',
            	isArray: true
            },
            'queryAll': { // Get all samples without pages
            	method: 'GET',
            	isArray: true,
            	url: 'api/samples/filter/all'
            },
            'calendar': {
                method: 'GET',
            	isArray: true,
            	url: 'api/samples/filter/calendar'
            },
            'publicQuery' : {
            	method: 'GET',
            	isArray: true,
            	url: 'api/public/samples/filter'
            },
            'attributeCounts' : {
                method: 'GET',
                isArray: false,
                url: 'api/samples/count/attribute'
            },
            'attribute' : {
                method: 'GET',
                isArray: true,
                url: 'api/samples/filter/attribute'
            },
            'autofill' : {
                method: 'GET',
                isArray: true,
                url: 'api/samples/filter/autofill'
            }
        });
    }
})();
