(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('SyncQbLogDialogController', SyncQbLogDialogController);

    SyncQbLogDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'SyncQbLog'];

    function SyncQbLogDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, SyncQbLog) {
        var vm = this;

        vm.syncQbLog = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $rootScope.$on('closeModal', function (event, data) {
            clear();
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.syncQbLog.id !== null) {
                SyncQbLog.update(vm.syncQbLog, onSaveSuccess, onSaveError);
            } else {
                SyncQbLog.save(vm.syncQbLog, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:syncQbLogUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.syncDateTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
