(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('QRCode', QRCode);

    QRCode.$inject = ['$resource'];

    function QRCode ($resource) {
        var resourceUrl =  'api/samples/qrcode/generate';

        return $resource(resourceUrl, {}, {
            'generate': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
