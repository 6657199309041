(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('FaqEditDialogController', FaqEditDialogController);

    FaqEditDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'FaqManageService','FAQService'];

    function FaqEditDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, FaqManageService, FAQService) {

        var vm = this;

        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "FAQ Manage";
        }

        vm.faq = {
                  answer: null,
                  faqCategory: {
                     categoryTitle: null,
                     id: $stateParams.id
                    },
                  id: null,
                  question: null
                };

        function getFaqById () {
            FAQService.getFaq($stateParams.questionId).then(function (response) {
                vm.faq.answer = response.data.answer;
                vm.faq.question = response.data.question;
                vm.faq.id = response.data.id;
            }).catch(function (error) {
                $uibModalInstance.close(result);
            })
        }

        getFaqById();

        $scope.tinymceOptions = {
            plugins: [
                'advlist autolink lists link image charmap print preview anchor textcolor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table contextmenu paste code'
            ],
            toolbar: ' formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ',
            inline: false,
            menubar: false,
        };
        vm.category = entity;
        vm.clear = clear;
        vm.save = save;

        $rootScope.$on('closeModal', function (event, data) {
            clear();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            FAQService.updateFaq(vm.faq).then(function (response) {
                onSaveSuccess(response);
            }).catch(function(error){
                onSaveError();
            } )
        }

        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:faqUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
