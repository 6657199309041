(function() {
    'use strict';
    /* TODO: Clean all this because unnecessary page params */
    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('companypage', {
            parent: 'app',
            url: '/companypage?page&hash&sort&search&productCategoryId&items&id&orderId&sampleName&lotNumber&metrcId&categoryIdList&orderType&thcLower&thcUpper&cbdLower&cbdUpper&isPass&isFail&isUndetermined&isWeedmapsSuccess&isWeedmapsFail' +
            	'&isWeedmapsNone&visibility&completeDateLower&completeDateUpper&estimatedDueDateLower&estimatedDueDateUpper&galleryView&starred',
            data: {
                authorities: [],
                pageTitle: 'Company Page'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/company-page/companypage.html',
                    controller: 'CompanyPageController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,

                productCategoryId: {
                    value: '0',
                    squash: true
                },
                items: null,
                /* Filter params */
        		id: null,
        		orderId: null,
        		sampleName: null,
        		lotNumber: null,
        		metrcId: null,
        		categoryIdList: null,
        		category: null,
        		subCategory: null,
        		orderType: null,
        		thcLower: null,
        		thcUpper: null,
        		cbdLower: null,
        		cbdUpper: null,
        		isPass: null,
        		isFail: null,
        		isUndetermined: null,
        		isWeedmapsSuccess: null,
        		isWeedmapsFail: null,
        		isWeedmapsNone: null,
        		visibility: null,
        		completeDateLower: null,
        		completeDateUpper: null,
        		estimatedDueDateLower: null,
        		estimatedDueDateUpper: null,
        		galleryView: null,
        		starred:null,
        		hash:null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {

                    return {
                    	/* Paging Params */
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        items: $stateParams.items,
                        /* Filter Params */
                        id: $stateParams.id ? parseInt($stateParams.id) : null,
                        orderId: $stateParams.orderId ? parseInt($stateParams.orderId) : null,
                        sampleName: $stateParams.sampleName,
                        hash: $stateParams.hash,
                        lotNumber: $stateParams.lotNumber,
                        metrcId: $stateParams.metrcId,
                        categoryIdList: $stateParams.categoryIdList,
                        category: $stateParams.category,
                        subCategory: $stateParams.subCategory,
                        orderType: $stateParams.orderType,
                        thcLower: $stateParams.thcLower ? parseInt($stateParams.thcLower) : null,
                        thcUpper: $stateParams.thcUpper ? parseInt($stateParams.thcUpper) : null,
                        cbdLower: $stateParams.cbdLower ? parseInt($stateParams.cbdLower) : null,
                        cbdUpper: $stateParams.cbdUpper ? parseInt($stateParams.cbdUpper) : null,
                        isPass: $stateParams.isPass,
                        isFail: $stateParams.isFail,
                        isUndetermined: $stateParams.isUndetermined,
                        isWeedmapsSuccess: $stateParams.isWeedmapsSuccess,
                        isWeedmapsFail: $stateParams.isWeedmapsFail,
                        isWeedmapsNone: $stateParams.isWeedmapsNone,
                        visibility: $stateParams.visibility,
                        completeDateLower: $stateParams.completeDateLower,
                        completeDateUpper: $stateParams.completeDateUpper,
                        estimatedDueDateLower: $stateParams.estimatedDueDateLower,
                        estimatedDueDateUpper: $stateParams.estimatedDueDateUpper,
                        galleryView: $stateParams.galleryView,
                        starred: $stateParams.starred
                    }
                }],
            }
        })
        
    }


})();
