(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('NotificationsController', NotificationsController);

    NotificationsController.$inject = ['$state','$stateParams','$scope', '$rootScope','$http','$sce', '$filter', '$location','LoginService',
        'Auth', 'ParseLinks','ProfileService','paginationConstants', 'pagingParams','Principal', 'CustomerByUser', 'CustomerOrder', 'NotificationService' ];

    function NotificationsController($state, $stateParams, $scope, $rootScope, $http,$sce, $filter, $location, LoginService, Auth,ParseLinks,
                                      ProfileService,  paginationConstants,pagingParams, Principal,  CustomerByUser, CustomerOrder, NotificationService  ) {

        var vm = this;

        $scope.to_trusted = function(html_code) {
            return $sce.trustAsHtml(html_code);
        }

        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        $scope.itemArray = [
            {id: 0, name: 'All', value: null},
            {id: 1, name: 'Order Placed', value: 'ORDER_PLACED'},
            {id: 2, name: 'Sample Received', value: 'SAMPLE_RECEIVED'},
            {id: 3, name: 'Preliminary Results', value: 'PRELIMINARY_RESULTS'},
            {id: 4, name: 'Results Completed', value: 'RESULTS_COMPLETED'},
            {id: 5, name: 'Invoice Available', value: 'INVOICE_AVAILABLE'},
            {id: 6, name: 'Payment Received', value: 'PAYMENT_RECEIVED'}
        ];

        $scope.selected = { value: $scope.itemArray[0] };

        $scope.notificationSettings = function(){
            $rootScope.notificationTab = 2;
            $location.path('customer-manage');
        }
        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "Notifications";
        }

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.itemsPerPage = "20";

        if(pagingParams.items != null){
            vm.itemsPerPage = pagingParams.items;
        }

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });


        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.setpagesize = setpagesize;
        vm.setFilter = setFilter;

        if(!vm.isAuthenticated()){
            $state.go('login');
        }

        loadAll();

        function loadAll () {

            if (pagingParams.search) {
                NotificationService.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    type: vm.type,
                }, onSuccess, onError);
            }
            else {
                NotificationService.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    type: vm.type,
                    productCategoryId: vm.productCategoryId
                }, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                $rootScope.allNotifications = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {

            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                items: vm.itemsPerPage,
                type: vm.type
            });
        }

        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function setpagesize(pagesize) {
            vm.itemsPerPage = pagesize;
            loadAll ();
        }

        function setFilter(filter) {
            vm.page = 0;
            pagingParams.page = 0;
            $stateParams['page']= 0;
            $rootScope.notificationFilter =  filter;
            vm.type = filter.value;
            loadAll ();
        }

        $scope.markAllAsRead = function(){

            NotificationService.markAllAsRead({}, onSuccess, onError);
            function onSuccess(data, headers) {
                $rootScope.notifications = data;

                for( var j = 0; j < $rootScope.allNotifications.length; j++ ) {
                    $rootScope.allNotifications[j].notificationRead = true;
                }

                for( var i = 0; i < $rootScope.topNotifications.length; i++ ) {
                    $rootScope.topNotifications[i].notificationRead = true;
                }

                $rootScope.newNotification =  $filter('filter')( $rootScope.notifications, {notificationRead: false}).length
            }
            function onError(error) {}
        }


        $scope.readNotification = function(id){
            $http.put('api/notifications/mark-as-read/'+id).then(
                function(response) {

                    for( var i = 0; i < $rootScope.topNotifications.length; i++ ) {
                        if ($rootScope.topNotifications[i].id === id ){
                            $rootScope.topNotifications[i] = response.data;
                        }
                    }

                    for( var j = 0; j < $rootScope.allNotifications.length; j++ ) {
                        if ($rootScope.allNotifications[j].id === id ){
                            $rootScope.allNotifications[j] = response.data;
                        }
                    }

                    $rootScope.newNotification =  $filter('filter')( $rootScope.topNotifications, {notificationRead: false}).length
                }
            );
        }


    }
})();
