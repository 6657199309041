(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('CustomerManageController', CustomerManageController);

    CustomerManageController.$inject = ['$scope','$rootScope','$state','$localStorage','Customer','Auth', 'Principal', 'ProfileService',
        'LoginService','CustomerByUser','Contact','ContactForUser', 'BusinessType','Country','CountryState',
        'CountryStateByCode', 'NotificationService','SubscriptionService', 'FileUpload', '$http', 'SweetAlert', 
        'ContactForCustomer', 'Contact2ForUser', '$translate', 'AddressList'];

    function CustomerManageController($scope,$rootScope,$state,$localStorage,Customer,Auth, Principal, ProfileService, LoginService,
                                      CustomerByUser, Contact, ContactForUser, BusinessType,Country,CountryState,
                                      CountryStateByCode, NotificationService, SubscriptionService, FileUpload, $http, SweetAlert, 
                                      ContactForCustomer, Contact2ForUser, $translate, AddressList) {

        var vm = this;

    vm.setLogo = setLogo;
    vm.companyLogo = null;
    vm.activeTab = 'contact_list';
    $scope.downloadOptionList = [
      {
        name: "COA Summary",
        value: "coaSummary",
        selected: false,
        disabled: false
      },
      {
        name: "Consolidated COA",
        value: "consolidatedCoa",
        selected: false,
        disabled: false
      },
      {
        name: "Test COA(s)",
        value: "testCoa",
        selected: false,
        disabled: false
      },
      {
        name: "Pictures",
        value: "pictures",
        selected: false,
        disabled: false
      },
      {
        name: "QR Code",
        value: "qrCode",
        selected: false,
        disabled: false
      },
      {
        name: "All",
        value: "all",
        selected: false,
        disabled: false
      }
    ];

    $scope.updateDownloadOptionList = function (option) {
     if (option.value == "all") {
        if (option.selected) {
          angular.forEach($scope.downloadOptionList, function (option) {
            option.selected = true;
          });
        } else {
          angular.forEach($scope.downloadOptionList, function (option) {
            option.selected = false;
          });
        }
      } else {
        var array = $scope.downloadOptionList.some(e => e.selected == true && e.value != "all");
        if(array){
            $scope.downloadOptionList.find(e => e.value == "all").disabled = true;
            $scope.downloadOptionList.find(e => e.value == "all").selected = false;
        }
        else{
            $scope.downloadOptionList.find(e => e.value == "all").disabled = false;
            $scope.downloadOptionList.find(e => e.value == "all").selected = false;
        }
      }
      if($scope.download_option_list.length == 0){
        angular.forEach($scope.downloadOptionList, function (option) {
          var option_object = {
              name:option.name,
              value:option.value,
              selected:option.selected
          };
          $scope.download_option_list.push(option_object);
        });
      }
      else{
        angular.forEach($scope.downloadOptionList, function (option) {
          $scope.download_option_list.find(e => e.value == option.value).selected = option.selected;
        })
      }
    };

        if($rootScope.notificationTab){
            selectTab($rootScope.notificationTab);
            $rootScope.notificationTab = null;
        }

        vm.deleteContact = function(contact) {
            SweetAlert.swal({
                    title: "Delete " + contact.name + "?",
                    text: ""+$translate.instant("This action cannot be undone."),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#EB5757",
                    confirmButtonText: ""+$translate.instant("Delete"),
                    closeOnConfirm: false
                },
                function(confirm) {
                    if(confirm) {
                        Contact.delete({id: contact.id},
                            function(data) {
                                SweetAlert.swal({
                                    title: contact.name + " deleted successfully.",
                                    type: "success"
                                });
                                getCustomerData();
                                getContactData();    
                            },
                            function(e) {
                                console.log(e);
                            }
                        );    
                    }
                }
            );
        }
        

        $scope.onFilesSelected = function(files) {
           var uploadUrl = "/api/customer/logo";
           var fd = new FormData();

           fd.append('file', files[0]);
           $http.post(uploadUrl, fd, {
               transformRequest: angular.identity,
               headers: {'Content-Type': undefined}

           }).success(function(response){    // response from server
        	   var logo = response.logo
        	   setLogo(logo);

           }).error(function(error){
           });
        };
        function setLogo(logo){
            vm.companyLogo = 'images/logo/' + logo;
            vm.customerData.logo = logo;
        }
        
        $scope.removeLogo = function(){
        	if (!vm.companyLogo) return;
        	var removeUrl = "/api/customer/logo/remove";
        	
        	$http.post(removeUrl, {}).success(function(response){
        		vm.companyLogo = null;
        		vm.customerData.logo = null;
        	}).error(function(response){
        		console.log(response);
        	});
        }


        function getNotificationSettings(){
            SubscriptionService.getSubscriptionSettings({}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.notificationSeting = data;
            }
            function onError(error) {
            }
        }

        vm.updateContact = function(contact){
            Contact.updateContactAndUser(contact,
                function(data) {
                    angular.noop;
                },
                function(e) {
                    console.log(e);
                });
        }

        vm.saveSettings = function () {

           SubscriptionService.saveSubscriptionSettings(vm.notificationSeting, onSuccess, onError);
           function onSuccess(data, headers) {
                console.log(data)
           }
           function onError(error) {
           }
            console.log(vm.notificationSeting)
        }

        // add default address changes
        vm.addressData = {}
        function getDataList() {
          AddressList.get({}, onSuccessCustomer, onErrorCustomer);

          function onSuccessCustomer(data, headers) {
              $scope.records = data;
          }

          function onErrorCustomer(e) {
              console.log(e);
          }
        }
        // function invoicing_list_toggle() {
        //   $scope.InvoicingList = "Invoicing_close";
        //   $scope.openInvoicingList = function() {
        //       if ($scope.InvoicingList === "Invoicing_close")
        //           $scope.InvoicingList = "Invoicing_open";
        //       else
        //           $scope.InvoicingList = "Invoicing_close";
        //   };
        // }
        function reporting_list_toggle() {
          $scope.ReportingList = "Reporting_close";
          $scope.openReportingList = function() {
              if ($scope.ReportingList === "Reporting_close")
                  $scope.ReportingList = "Reporting_open";
              else
                  $scope.ReportingList = "Reporting_close";
          };
        }
        function manufacturing_list_toggle() {
          $scope.ManufacturingList = "Manufacturing_close";
          $scope.openManufacturingList = function() {
              if ($scope.ManufacturingList === "Manufacturing_close")
                  $scope.ManufacturingList = "Manufacturing_open";
              else
                  $scope.ManufacturingList = "Manufacturing_close";
          };
        }
        function distribution_list_toggle() {
          $scope.DistributionList = "Distribution_close";
          $scope.openDistributionList = function() {
              if ($scope.DistributionList === "Distribution_close")
                  $scope.DistributionList = "Distribution_open";
              else
                  $scope.DistributionList = "Distribution_close";
          };
        }
        function pickup_list_toggle() {
            $scope.PickupList = "Pickup_close";
            $scope.openpickupList = function() {
                if ($scope.PickupList === "Pickup_close")
                    $scope.PickupList = "Pickup_open";
                else
                    $scope.PickupList = "Pickup_close";
            };
        }
        function sampling_list_toggle() {
            $scope.SamplingList = "Sampling_close";
            $scope.opensamplingList = function() {
                if ($scope.SamplingList === "Sampling_close")
                    $scope.SamplingList = "Sampling_open";
                else
                    $scope.SamplingList = "Sampling_close";
            };
        }
        $scope.copyManufacturingData = function(id, fullLocation) {
          vm.customerData.default_addresses['manufacturingLocation'] = id
          vm.fullLocationManufactur = fullLocation;
          manufacturing_list_toggle();          
        }
        $scope.copyReportingData = function(id, fullLocation) {
          vm.customerData.default_addresses['cultivationLocation'] = id
          vm.fullLocationreporting = fullLocation
          reporting_list_toggle();          
        }
        // $scope.copyInvoicingData = function(address, city, zipCode, name, state) {
        //   if(city == "customer_address"){
        //     vm.fullLocationInvoicing = address;
        //   }
        //   else{
        //     var invoicing_address = createInvoicingAddress(address, city, zipCode, name, state);
        //     vm.invoicing_address = invoicing_address;
        //     vm.fullLocationInvoicing = invoicing_address;
        //   }
        //   console.log(invoicing_address);
        //   invoicing_list_toggle();
        // }
        $scope.copyPickupData = function(address, city, zipCode, name, state,id) {
          vm.customerData.default_addresses['pickupLocation'] = id
          var pickup_address = createInvoicingAddress(address, city, zipCode, name, state);
            vm.pickup_address = pickup_address;
            vm.fullLocationPickup = pickup_address;
            pickup_list_toggle();
        }
        $scope.copySamplingData = function(address, city, zipCode, name, state,id) {
          vm.customerData.default_addresses['samplingLocation'] = id
          var Sampling_address = createInvoicingAddress(address, city, zipCode, name, state);
            vm.Sampling_address = Sampling_address;
            vm.fullLocationSampling = Sampling_address;
            sampling_list_toggle();
        }
        $scope.copyDistributionData = function(id, fullLocation) {  
          vm.customerData.default_addresses['distributionLocation'] = id
            vm.fullLocationComp = fullLocation;
            distribution_list_toggle();
        }
        $scope.removeAddressData = function(address, addressId, element, input) {
            vm.removeAddressData = address;
            vm.removeAddressId = addressId;
            vm.removeAddressElement = element;

            setTimeout(function() {
                if ('DISTRIBUTION' === input)
                    vm.samplingAddress = "";
                else if ('MANUFACTURING' === input) {
                    vm.manufacturingAddress = "";
                    vm.reportingAddress = "";
                }
                else if ('INVOICING' === input) {
                    vm.invoicingAddress = "";
                }
                else if ('PICKUP' === input) {
                    vm.pickupAddress = "";
                }
            }, 200);
        }
        // $scope.newInvoicingAddress = function() {
        //     vm.InvoicingAddress.locationType = "INVOICING_LOCATION";
        //     AddressList.save(vm.InvoicingAddress, onSuccessCustomer, function() {

        //     });

        //     function onSuccessCustomer(data, headers) {
        //         getDataList();
        //     }
        // }

        $scope.newPickupAddress = function() {
            vm.PickupAddress.locationType = "PICKUP_LOCATION";
            AddressList.save(vm.PickupAddress, onSuccessCustomer, function() {

            });

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        $scope.newManufacturingAddress = function() {
            vm.ManufacturingAddress.locationType = "CULTIVATION_LOCATION";
            AddressList.save(vm.ManufacturingAddress, onSuccessCustomer, function() {

            });

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        $scope.newDistributionAddress = function() {
            vm.DistributionAddress.locationType = "DISTRIBUTION_LOCATION";
            AddressList.save(vm.DistributionAddress, onSuccessCustomer, null);

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        $scope.removeAddressQuery = function() {
            AddressList.remove({
                id: vm.removeAddressId
            }, onSuccessCustomer, function() {
            });

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        manufacturing_list_toggle();
        reporting_list_toggle();
        // invoicing_list_toggle();
        distribution_list_toggle();
        pickup_list_toggle();
        sampling_list_toggle();
        getDataList();
        function createAddress(address, city, zipCode, name, medicalLicence) {
          var full_address = name + '\n' + address + '\n' + city + ' ' + zipCode;

          if(medicalLicence) {
              full_address += '\n' + medicalLicence
          }

          return full_address;
        }

        function createInvoicingAddress(address, city, zipCode, name, state) {
            return name + '\n' + address + '\n' + city + ' ' + zipCode + ' ' + state;
        }

        $scope.clearCityZipListDataOnClose = function() {
          $scope.cityRecordsZips = [];
          $scope.cityRecords = [];
        }

        // add default address changes end

        getNotificationSettings();


        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "Manage";
        }

        vm.error = null;
        vm.mainUser = false;

        vm.businesstypes = null//BusinessType.query();
        vm.countries =  [ "USA", "Canada"]
        vm.mainUser = false;
        vm.notificationSeting = null;
        
        vm.showsidebar = true;
        var sidebar = document.getElementById("main-sidebar");
         if (vm.sidebar!=null) {
            if(sidebar.classList.contains("menu-remove")){
                var z = document.getElementsByClassName("content-wrapper");
                for (i = 0; i < z.length; i++) {
                    if (z[i].classList) {
                        z[i].classList.toggle("big-box-remove");
                    }
                }
            }
        }

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;

        if(!vm.isAuthenticated()){
             $state.go('login');
        }

        getCustomerData();
        
    

        vm.submitCustomer = submitCustomer;

        $scope.download_option_list = [];
        function getCustomerData () {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                 vm.customerData = data;
                 if (vm.customerData.businessType)
                 vm.businesstypes = [vm.customerData.businessType];
                 if (vm.customerData.logo!=null) {
                     vm.setLogo(vm.customerData.logo);
                 }

                 if(vm.customerData != null && vm.customerData.address != null){
                     var partsOfStr = vm.customerData.address.split('@');
                     vm.customerData.address_street = partsOfStr[0];
                     vm.customerData.address_city = partsOfStr[1];
                     vm.customerData.address_state = partsOfStr[2];
                     vm.customerData.address_country = partsOfStr[3];
                     vm.customerData.address_zip = partsOfStr[4];
                 }

                 if (vm.customerData.privateSampleByDefault == null) {
                	 vm.customerData.privateSampleByDefault = false;
                 }
                 vm.customerData.downloadable_file_options = vm.customerData.downloadable_file_options && JSON.parse(vm.customerData.downloadable_file_options);
                 if(vm.customerData.downloadable_file_options){
                     angular.forEach(vm.customerData.downloadable_file_options,function(element) {
                         $scope.downloadOptionList.find(e => e.name == element.name).selected = element.selected
                     });
                     $scope.download_option_list = vm.customerData.downloadable_file_options;
                 }
                 vm.customerData.default_addresses = vm.customerData.default_addresses ? JSON.parse(vm.customerData.default_addresses) : {};
                  if(vm.customerData.default_addresses){
                    
                    var addresses = vm.customerData.default_addresses
                    var distribution = $scope.records.find(function(record){
                        return record.id == addresses.distributionLocation
                    })
                    if (distribution) {
                      vm.fullLocationComp = distribution.fullLocation;
                    }
                    var manufacture = $scope.records.find(function(record){
                      return record.id == addresses.manufacturingLocation
                    })
                    if (manufacture) {
                      vm.fullLocationManufactur = manufacture.fullLocation;
                    }

                    var pickup = $scope.records.find(function(record){
                        return record.id == addresses.pickupLocation
                    })
                    if (pickup) {
                      vm.fullLocationPickup = pickup.fullLocation;
                    }

                    var sampling = $scope.records.find(function(record){
                        return record.id == addresses.samplingLocation
                    })
                    if (sampling) {
                      vm.fullLocationSampling = sampling.fullLocation;
                    }

                    var reporting = $scope.records.find(function(record){
                        return record.id == addresses.cultivationLocation
                    })
                    if (reporting) {
                      vm.fullLocationreporting = reporting.fullLocation;
                    }
                  }
                 populateStates();
                 getCurrentContact();

                vm.customer_type = vm.customerData.businessType.name.toLowerCase();
            }
            function onError(error) {
                console.log(error);
            }
        }

    function findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] == value) {
          return i;
        }
      }
      return -1;
    }

    function getContactData() {
      ContactForCustomer.query(
        { customerId: vm.customerData.id },
        function (data, headers) {
          vm.contacts = data;
          vm.mainUser = false;
          vm.custCountry = vm.customerData.country;
          vm.contacts.forEach(function (contact) {
            if (contact.contactCustomers) {
              contact.contact_customer_index = findWithAttr(
                contact.contactCustomers,
                "customer_id",
                vm.customerData.id
              );

              if (
                contact.contactCustomers[contact.contact_customer_index].role ==
                "ADMIN"
              ) {
                contact.portal = "admin";
              } else if (contact.login) {
                contact.portal = "user";
              } else {
                contact.portal = "none";
              }

              if (
                contact.contactCustomers[contact.contact_customer_index]
                  .contact_id == vm.contact_id
              ) {
                contact.is_current_user = true;
                vm.mainUser = contact.mainUser;
              } else {
                contact.is_current_user = false;
              }
            }
          });
          $state.admin = vm.mainUser;
        },
        function (error) {
          console.log(error);
        }
      );
    }

    function getCurrentContact() {
      Contact2ForUser.get(
        {},
        function (data) {
          vm.contact_id = data.id;
          getContactData();
        },
        function (e) {
          console.log(e);
        }
      );
    }

    vm.populateStates = populateStates;

    vm.determineToReceive = function (contact) {
      for (var i = 0; i < contact.contactCustomers.length; i++) {
        var cc = contact.contactCustomers[i];
        if (cc.customer_id != $localStorage.customerId) continue;

            	if (cc.receive_coa && cc.receive_invoice) {
            		return 'Both';
            	} else if (cc.receive_coa) {
            		return 'COA';
            	} else if (cc.receive_invoice) {
            		return 'Invoice';
            	} else {
            		return 'None';
            	}
        	}
        }

        function populateStates(){
            var country = vm.customerData.address_country;
            if (country == 'Canada') {
                vm.countryStates = [
                    {"code": "Canada", "name": "Canada"}
               ]
            }
            else {
                 vm.countryStates = [
                      {"code": "AK", "name": "Alaska"},
                      {"code": "AL", "name": "Alabama"},
                      {"code": "AR", "name": "Arkansas"},
                      {"code": "AZ", "name": "Arizona"},
                      {"code": "CA", "name": "California"},
                      {"code": "CO", "name": "Colorado"},
                      {"code": "CT", "name": "Connecticut"},
                      {"code": "DC", "name": "Washington DC"},
                      {"code": "DE", "name": "Delaware"},
                      {"code": "FL", "name": "Florida"},
                      {"code": "GA", "name": "Georgia"},
                      {"code": "GU", "name": "Guam"},
                      {"code": "HI", "name": "Hawaii"},
                      {"code": "IA", "name": "Iowa"},
                      {"code": "ID", "name": "Idaho"},
                      {"code": "IL", "name": "Illinois"},
                      {"code": "IN", "name": "Indiana"},
                      {"code": "KS", "name": "Kansas"},
                      {"code": "KY", "name": "Kentucky"},
                      {"code": "LA", "name": "Louisiana"},
                      {"code": "MA", "name": "Massachusetts"},
                      {"code": "MD", "name": "Maryland"},
                      {"code": "ME", "name": "Maine"},
                      {"code": "MI", "name": "Michigan"},
                      {"code": "MN", "name": "Minnesota"},
                      {"code": "MO", "name": "Missouri"},
                      {"code": "MS", "name": "Mississippi"},
                      {"code": "MT", "name": "Montana"},
                      {"code": "NC", "name": "North Carolina"},
                      {"code": "ND", "name": "North Dakota"},
                      {"code": "NE", "name": "Nebraska"},
                      {"code": "NH", "name": "New Hampshire"},
                      {"code": "NJ", "name": "New Jersey"},
                      {"code": "NM", "name": "New Mexico"},
                      {"code": "NV", "name": "Nevada"},
                      {"code": "NY", "name": "New York"},
                      {"code": "OH", "name": "Ohio"},
                      {"code": "OK", "name": "Oklahoma"},
                      {"code": "OR", "name": "Oregon"},
                      {"code": "PA", "name": "Pennsylvania"},
                      {"code": "PR", "name": "Puerto Rico"},
                      {"code": "RI", "name": "Rhode Island"},
                      {"code": "SC", "name": "South Carolina"},
                      {"code": "SD", "name": "South Dakota"},
                      {"code": "TN", "name": "Tennessee"},
                      {"code": "TX", "name": "Texas"},
                      {"code": "UT", "name": "Utah"},
                      {"code": "VA", "name": "Virginia"},
                      {"code": "VI", "name": "Virgin Islands"},
                      {"code": "VT", "name": "Vermont"},
                      {"code": "WA", "name": "Washington"},
                      {"code": "WI", "name": "Wisconsin"},
                      {"code": "WV", "name": "West Virginia"},
                      {"code": "WY", "name": "Wyoming"}

               ]

            }
        }

        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function submitCustomer(){
            if(vm.customerData.country.name == 'Japan'){
              vm.customerData.country.id = 3
            }
            if(vm.customerData.country.name == 'USA'){
              vm.customerData.country.id = 1
            }
            if(vm.customerData.country.name == 'Canada'){
              vm.customerData.country.id = 2
            }

            vm.isSaving = true;
            vm.error = null;
            vm.customerData.address = vm.customerData.address_street + '@' + vm.customerData.address_city + '@' + vm.customerData.address_state + '@' + vm.customerData.address_country + '@' + vm.customerData.address_zip;
            vm.customerData.downloadable_file_options = angular.toJson($scope.download_option_list);
            vm.customerData.default_addresses = JSON.stringify(vm.customerData.default_addresses)
            Customer.update(vm.customerData, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.addressData = {}
        }

        function onSaveError () {
            vm.isSaving = false;
            vm.error = "SOMETHING WENT WRONG!";
        }

    }
})();
