(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('CustomerOrder', CustomerOrder);

    CustomerOrder.$inject = ['$resource', 'DateUtils'];

    function CustomerOrder ($resource, DateUtils) {
        var resourceUrl =  'api/customer-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
//                        data.dateReceived = DateUtils.convertLocalDateFromServer(data.dateReceived);
                        data.dateReceived = DateUtils.utcToLocal(data.dateReceived);
                        data.dateCreated = DateUtils.utcToLocal(data.dateCreated);
                        data.dateCompleted = DateUtils.utcToLocal(data.dateCompleted);
                        data.datePreliminaryResults = DateUtils.utcToLocal(data.datePreliminaryResults);
                        data.checkoutDate = DateUtils.convertLocalDateFromServer(data.checkoutDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateReceived = DateUtils.convertLocalDateToServer(copy.dateReceived);
                    copy.checkoutDate = DateUtils.convertLocalDateToServer(copy.checkoutDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.checkoutDate = DateUtils.convertLocalDateToServer(copy.checkoutDate);
                    var result = angular.toJson(copy);

                    return result;
                }
            },
            'filter': {
            	url: 'api/customer-orders/filter',
            	method: 'GET',
            	isArray: true
            },
            'filterAll': { // Get all orders without pages
            	url: 'api/customer-orders/filter/all',
            	method: 'GET',
            	isArray: true,
            	url: 'api/samples/filter/all'
            },
            'openSubmissions': {
            	url: 'api/customer-orders/count/opensubmissions',
            	method: 'GET'
            },
            'getCustomerEmails': {
            	url: 'api/customer-orders/get_customer_emails',
            	method: 'GET'
            }
        });
    }
})();

