(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('InvoiceDetailsController', InvoiceDetailsController);

    InvoiceDetailsController.$inject = ['$timeout', '$scope', '$rootScope', 'Invoice', 'InvoiceAttachDownload', 'InvoiceAttachDownloadBytes',
                                        'SquareService', 'Payment', 'SweetAlert', 'CustomerSquare'];

    function InvoiceDetailsController ($timeout, $scope, $rootScope, Invoice, InvoiceAttachDownload, InvoiceAttachDownloadBytes,
                                         SquareService, Payment, SweetAlert, CustomerSquare) {
        var vm = this;

        function getPageData() {
            vm.data = Invoice.get({id : $scope.$parent.toStateParams.id},
                function(data) {
                    pageLoad();
                    return data;
                },
                function(e) {
                    console.log(e)
                }
            );
        }

        function pageLoad() {
            $('#invoiceNo').html('Invoice No. ' + vm.data.invoice.custom_formated_id);
            initializeVars();
            parseAddresses();
            processInvoiceItems();
            createPaymentDict();
            setShowLotBatch();
            setInvoiceStatus();
            if(vm.invoice_is_payable) {
                getSquareAccount();
            }
        }

        function initializeVars() {
            vm.invoice_payment = [];
            vm.emails = null;
            vm.account_address = [];
            vm.distributor_address = [];
            vm.manufacturing_address = [];

            vm.payment_processing = false;

            vm.show_lot_batch = false;

            vm.invoice_is_payable = ["INVOICED", "APPROVED"].includes(vm.data.invoice.status);

            vm.card_info = null;

            vm.payment = {
                card: null,
                amount: null,
                note: null,
                id: null
            }

            vm.invoice_data = {
                invoice_items: [],
                flat_surcharges: [],
                percent_surcharges: [],
                flat_discounts: [],
                percent_discounts: [],
                subtotals: [],
                total: 0
            }
        }

        function getSquareAccount() {
            CustomerSquare.retrieveCustomer({},
                function(data) {
                    if(data && data.customer && data.customer.cards) {
                        data.customer.cards.forEach( function(card) {
                            if(card.id == vm.data.customer.square_primary_card_id) {
                                vm.card_info = card;
                            }
                        });
                    }
                },
                function(e) {
                    console.log(e);
                }
            );
        }

        function setShowLotBatch() {
            if(vm.data.sampleList.length > 0) {
                vm.show_lot_batch = vm.data.sampleList.some( function(el) {
                    if(el.lotBatchNumber) {
                        return true
                    }
                });
            }
        }

        function setInvoiceStatus() {
            vm.payment_status = "<div></div>"

            if(vm.data.invoice.ach_status && vm.data.invoice.ach_status == 'PENDING') {
                vm.payment_status = "<div class=\"invoice-status unpaid\">ACH Pending</div>";
                vm.invoice_is_payable = false;
            }
            else if(!vm.invoice_is_payable) {
                vm.payment_status = "<div class=\"invoice-status unpaid\">Awaiting Approval</div>";
            }
            else if(vm.data.invoice.paid) {
                vm.payment_status = "<div class=\"invoice-status paid\">Paid</div>";
            }
            else {
                var today = new Date(moment())
                var date_to_compare = vm.data.invoice.due_date ? vm.data.invoice.due_date : vm.data.invoice.invoice_date;


                if(today > new Date(date_to_compare)) {
                    vm.payment_status = "<div class=\"invoice-status past-due\">Past Due</div>";
                }
                else {
                    vm.payment_status = "<div class=\"invoice-status unpaid\">Unpaid</div>";
                }
            }
        }

        function addErrorMessage(error_message, new_error) {
            return error_message + new_error + '\n'
        }

        function processSquareRequestErrors(title, errors) {
            var error_message = '';

            angular.forEach(errors, function(item, index) {
                if(item.detail) {
                    error_message = addErrorMessage(error_message, item.detail);
                }
            });

            SweetAlert.swal({
                title: title,
                text: error_message,
                type: 'warning',
                confirmButtonColor: '#4f8fcc',
                confirmButtonText: 'Try Again'
            });
            vm.payment_processing = false;
        }

        function createPaymentDict() {
            vm.payment.card = vm.data.customer.square_primary_card_id ? vm.data.customer.square_primary_card_id : null;
            vm.payment.amount = vm.data.invoice.outstanding_amount;
            vm.payment.note = vm.data.customer.company_name + ': ' + vm.data.invoice.order.id + ' (Portal Payment)';

            vm.invoice_payment = [{ invoice_id: vm.data.invoice.id, applied_amount: vm.payment.amount }];

            vm.emails = vm.data.invoice.email_to;
        }

        getPageData();

        vm.createPayment = function() {
            if(!vm.payment.card) {
                SweetAlert.swal({
                    title: "No Card on File",
                    text: "Please add a credit card to the 'Payment Methods' tab of the Billing page before attempting to submit a payment.",
                    type: "warning",
                    confirmButtonColor: "#27ae60",
                    confirmButtonText: "Ok",
                });
            }
            else {
                SweetAlert.swal({
                        title: "Confirm Payment",
                        text: "Your primary credit card on file will be automatically charged. Are you sure you want to submit a payment for $" + vm.payment.amount.toFixed(2).toString() + '?',
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#27ae60",
                        confirmButtonText: "Submit Payment",
                        closeOnConfirm: true
                    },
                    function(confirm) {
                        if(confirm) {
                            vm.payment_processing = true;

                            SquareService.createPayment({
                                'nonce': vm.payment.card,
                                'amount': vm.payment.amount,
                                'note' : vm.payment.note
                            },
                                function(data) {
                                    if(data.errors) {
                                        processSquareRequestErrors('There was an issue processing your payment.', data.errors);
                                    }
                                    else {
                                        applyQBenchPayment(data.payment.id);
                                    }
                                },
                                function(e) {
                                    vm.payment_processing = false;
                                    console.log(e);
                                }
                            );
                        }
                    }
                );
            }
        }

        function applyQBenchPayment(square_payment_id) {
            Payment.createPayment({
                payment_dict: {
                    amount: vm.payment.amount,
                    payment_type_id: 34,
                },
                invoices_dict : {
                    invoice_payments: vm.invoice_payment
                },
                params: {
                    square_payment_id: square_payment_id,
                    card_info: vm.card_info ? vm.card_info : false
                },
            },
            function(data) {
                sendPaymentReceipt(data.id)
                createInvoicesToPayArray();
                SweetAlert.swal({
                    title: 'Payment processed successfully.',
                    text: 'Thank you for your payment. All billing contacts will be emailed a copy of the payment receipt shortly. The document will also be accessible and available for download via the \'Payment History\' subtab of the portal.',
                    type: 'success',
                    confirmButtonColor: '#4f8fcc',
                    confirmButtonText: 'Ok'
                });
            },
            function(e) {
                console.log(e)
            }
        );
        }

        function sendPaymentReceipt(id) {
            Payment.sendReceipt({ id: id, emails: vm.emails },
                function(data) {
                    getPageData();
                    setTimeout(() => {
                        vm.payment_processing = false;
                    }, 2000);
                },
                function(e) {
                    console.log(e);
                }
            );
        }

        function parseAddresses() {
            vm.account_address.push(vm.data.customer.company_name)
            if(vm.data.customer.address) {
                var customer_address = vm.data.customer.address.split('\n');
                customer_address.forEach( function(line) {
                    vm.account_address.push(line);
                });
            }

            if(vm.data.customerOrder.samplingAddress) {
                var distributor_address = vm.data.customerOrder.samplingAddress.split('\n');
                distributor_address.forEach( function(line) {
                    vm.distributor_address.push(line);
                });
            }

            if(vm.data.customerOrder.manufacturingAddress) {
                var manufacturing_address = vm.data.customerOrder.manufacturingAddress.split('\n');
                manufacturing_address.forEach( function(line) {
                    vm.manufacturing_address.push(line);
                });
            }
        }


        function processInvoiceItems() {
            vm.data.invoice.invoiceItems.forEach( function(item) {
                if(item.invoice_item_type == 'INVOICE_ITEM') {
                    vm.invoice_data.invoice_items.push(item);
                }
                else if(item.invoice_item_type == 'FLAT_SURCHARGE') {
                    vm.invoice_data.flat_surcharges.push(item);
                }
                else if(item.invoice_item_type == 'PERCENT_SURCHARGE') {
                    vm.invoice_data.percent_surcharges.push(item);
                }
                else if(item.invoice_item_type == 'FLAT_DISCOUNT') {
                    vm.invoice_data.flat_discounts.push(item);
                }
                else if(item.invoice_item_type == 'PERCENT_DISCOUNT') {
                    vm.invoice_data.percent_discounts.push(item);
                }
                else if(item.invoice_item_type == 'SUBTOTAL') {
                    vm.invoice_data.subtotals.push(item);
                }
            });

            vm.invoice_data.total = vm.data.invoice.total;
            vm.invoice_data.outstanding_amount = vm.data.invoice.outstanding_amount;
        }


        vm.downloadAttachment = function (id) {
            InvoiceAttachDownload.get({id: id},
                function (data) {
                    var url = window.URL.createObjectURL(data.data);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = "Invoice-" + vm.data.invoice.custom_formated_id + ".pdf";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                },
                function (e) {
                    AlertService.error("Could not download invoice");
                }
            );
        }

        $scope.downloadAttachment = function (id) {
            if(id == ''){return}
            InvoiceAttachDownload.get({id: id}, onSuccess, onError);
            function onSuccess(data, headers) {
	            var a = document.createElement("a");
	            document.body.appendChild(a);
                a.style = "display: none";

	            var fileURL = window.URL.createObjectURL(data.data);
	            a.href = fileURL;
                a.download = 'Invoice ' + vm.data.invoice.custom_formated_id +  '.pdf';
                a.click();
            }
            function onError(error) {

            }
        }
                
        vm.printAttachment = function (id) {
            InvoiceAttachDownload.get({id: id}, onSuccess, onError);
            function onSuccess(data, headers) {
                if (data.data.byteLength == 0){
                    AlertService.error("Could not print invoice");
                    return;
                }
                var url = URL.createObjectURL(data.data);

                setTimeout(function() {
                    window.open(url, '_blank');
                },200);
            }
            function onError(error) {
                AlertService.error("Could not print invoice");
            }
        }
    }
})();
