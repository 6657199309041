(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('CompanyPage', CompanyPage);

    CompanyPage.$inject = ['$resource'];

    function CompanyPage ($resource) {

        return $resource('api/companypage/:id', {id: '@id', public: '@public', emails: '@emails', message: '@message', subject: '@subject', public_link: '@public_link', pic_url: '@pic_url', totalTHC: '@totalTHC', customer_hash: '@customer_hash', params: '@params'}, {
            query: { method: 'GET', isArray: true, params: {hash: '@id', params: '@params'} },
            get: {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            public: {
                url: 'api/companypage/:id:public',
                method: 'PUT'
            },
            getCustomer:{
            	url: 'api/companypage/customer',
            	method: 'GET',
            	params: {customer_hash: 'customer_hash'}
            },
            getBulkPictures: {
            	url: 'api/public/samples/pictures',
            	method: 'GET',
            	isArray:false,
            	params: {ids: '@ids'}
            },
            getAttachments:{
            	url: 'api/public/:id/attachments',
            	method: 'GET',
            	isArray:true,
            },
            sendEmail: {
            	url: 'api/public/email',
            	method: 'PUT',
            	params: {emails: '@emails', message: '@message', public_link: '@public_link', sample_id: '@id', pic_url: '@pic_url', totalTHC: '@totalTHC', subject: '@subject'}
            }
        });

    }

})();
