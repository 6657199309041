(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Sample', Sample);

    Sample.$inject = ['$resource'];

    function Sample ($resource) {

        return $resource('api/samples/:id', {id: '@id', public: '@public', daterange: '@daterage', emails: '@emails', message: '@message', subject: '@subject', public_link: '@public_link', pic_url: '@pic_url', totalTHC: '@totalTHC'}, {
            query: { method: 'GET', isArray: true },
            get: {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            public: {
                url: 'api/sample/:id/:public',
                method: 'PUT'
            },
            passFailCount:{
            	url: 'api/samples/count/passfail',
            	method: 'GET',
            	params: {daterange: '@daterange'}
            },
            incompleteCount: {
            	url: 'api/samples/count/incomplete',
            	method: 'GET'
            },
            toggleVisibility: {
            	url: 'api/samples/:id/togglevisibility',
            	method: 'PUT'
            },
            toggleStarred: {
            	url: 'api/samples/:id/togglestarred',
            	method: 'PUT'
            },
            sendEmail: {
            	url: 'api/sample/email',
            	method: 'PUT',
            	params: {emails: '@emails', message: '@message', public_link: '@public_link', sample_id: '@id', pic_url: '@pic_url', totalTHC: '@totalTHC', subject: '@subject'}
            }
        });

    }

})();
