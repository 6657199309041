(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('faq-manage', {
            parent: 'entity',
            url: '/faq-manage',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'FAQ Manage'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/faq/faq.html',
                    controller: 'FaqManageController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: false
                },
                sort: {
                    value: 'id',
                    squash: false
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        })
        .state('faq-category-detail', {
            parent: 'faq-manage',
            url: '/faq-category/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'FAQ Manage'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/faq/faq-category-detail.html',
                    controller: 'FaqCategoryDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'FaqManageService', function($stateParams, FaqManageService) {
                    return FaqManageService.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'faq-manage',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('faq-category-detail.edit', {
            parent: 'faq-category-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/faq/faq-category-dialog.html',
                    controller: 'FaqCategoryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FaqManageService', function(FaqManageService) {
                            return FaqManageService.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('faq-manage', null, { reload: true });
                }, function() {
                    $state.go('faq-manage');
                });
            }]
        })
        .state('faq-detail', {
            parent: 'faq-category-detail',
            url: '/faq/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/faq/faq-add-new-dialog.html',
                    controller: 'FaqAddNewDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FAQService', function(FAQService) {
                            return FAQService.getFaq({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('faq-category-detail.new', {
            parent: 'faq-manage',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/faq/faq-category-dialog.html',
                    controller: 'FaqCategoryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                categoryTitle: null,
                                faq: [],
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('faq-manage', null, { reload: 'faq-manage' });
                }, function() {
                    $state.go('faq-manage');
                });
            }]
        })
        .state('faq-add-new', {
            parent: 'faq-manage',
            url: '/add-new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/faq/faq-add-new-dialog.html',
                    controller: 'FaqAddNewDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                categoryTitle: null,
                                faq: [],
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('faq-manage', null, { reload: 'faq-manage' });
                }, function() {
                    $state.go('faq-manage');
                });
            }]
        })
        .state('faq-edit', {
            parent: 'faq-category-detail',
            url: '/edit-faq/{questionId}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/faq/faq-edit-dialog.html',
                    controller: 'FaqEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {};
                        }
                    }

                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('faq-category-detail.delete', {
            parent: 'faq-manage',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/faq/faq-category-delete-dialog.html',
                    controller: 'FaqCategoryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['FaqManageService', function(FaqManageService) {
                            return FaqManageService.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('faq-manage', null, { reload: 'faq-manage' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
