(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('OrderDetailsController', OrderDetailsController);

    OrderDetailsController.$inject = ['$timeout', 'CustomerOrderPdfService','$scope', '$rootScope', '$stateParams','CustomerOrder',
         'CustomerOrderDetails', 'Sample', 'Customer','CustomerByUser','SampleAttach','SampleAttachDownload','OrderPicture', 'OrderAttach', 'OrderAttachDownload', 'SampleCoaPdfService'];

    function OrderDetailsController ($timeout, CustomerOrderPdfService, $scope, $rootScope, $stateParams, CustomerOrder,
        CustomerOrderDetails, Sample, Customer, CustomerByUser, SampleAttach, SampleAttachDownload, OrderPicture, OrderAttach, OrderAttachDownload, SampleCoaPdfService) {

            var vm = this;

            vm.idOfSelectedSample = null;
            vm.numOfSelectedSamplePictures = 0;
            vm.getAttachmentsLoader = false;

            vm.due_date_info = {
                same_for_all: false,
                due_date: null
            }

            vm.customerOrder = CustomerOrder.get({id : $scope.toStateParams.orderId},
                function(data) {
                    var type_of_business = data.customer.businessType.name.toLowerCase();

                    afterCustomerOrderGet();

                    if (type_of_business.includes('food')) {
                        vm.consolidated_business_type = 'food';
                    }
                    else {
                        vm.consolidated_business_type = 'cannabis';
                    }

                    parseDueDates(data);

                    return data
                },
                function(e) {console.log(e)
            });

            function parseDueDates(order) {
                var current_due_date = null;
                var sample = null;
                var tests = null;
                var test = null;

                for(var sample_index = 0; sample_index < order.samples.length; sample_index++) {
                    sample = order.samples[sample_index];
                    tests = order.samples[sample_index].sampleTests;

                    for(var test_index = 0; test_index < tests.length; test_index++) {
                        test = tests[test_index];
                        if(current_due_date == null) current_due_date = test.estimatedCompleteDate;

                        if(test.estimatedCompleteDate != current_due_date) {
                            vm.due_date_info = {
                                same_for_all: false,
                                due_date: null
                            }

                            return
                        }
                    }
                }

                vm.due_date_info = {
                    same_for_all: true,
                    due_date: current_due_date
                }
            }

            function afterCustomerOrderGet() {
                setOrderProgress()
                // info hover that will list analyses requested with that consulting assay
                vm.customerOrder.samples.forEach( function(sample) {
                    sample.sampleTests.forEach( function(test) {
                        if (test.assay.portalIdentifier == 'CONSULTING') {
                            test.comments = test.comments.slice(18).split('\n');
                        }
                    });
                });
                getPictures();
                $('#orderNo').html('Order No. ' + $scope.toStateParams.orderId);

            }
            
            function someAsyncFunction() {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const data = vm.customerOrder;
                    resolve(data);
                  },100);
                });
              }
            
            function setTabsVisibility() {
                var current_user = JSON.parse(localStorage.getItem('jhi-oldUserLogin'))
                if(!current_user) return
                current_user = current_user.replace(/\"/g, "")
                let country_id = 0;
                someAsyncFunction()
                .then((result) => {
                  if(result.customer.country && result.customer.country.id) {
                    country_id = result.customer.country.id;
                    console.log(country_id,'country_id');
                }
                //Country is JAPAN
                if(country_id === 3 && current_user === 'daichi@anresco.com') {
                    $scope.invoiceBtnToggle = "true";
                } else if(country_id !== 3) {
                    $scope.invoiceBtnToggle = "true";
                }
                })
                .catch((error) => {
                  console.error(error);
                });

            }

            $rootScope.getOrderAttachments = function (order) {
                var id = $scope.toStateParams.orderId;
                vm.getAttachmentsLoader = true;
                vm.idOfSelectedOrder = id;

                if(order.attachments != null){
                    vm.getAttachmentsLoader = false;
                    return;
                }

                OrderAttach.query({id: id}, onSuccess, onError);
                function onSuccess(data, headers) {
                    vm.getAttachmentsLoader = false;
                    order.attachments = data;
                }

                function onError(error) {
                    console.log(error.data.message)
                    // AlertService.error(error.data.message);
                }
            }

            $rootScope.downloadOrderAttachment = function (id_hash,order) {
                OrderAttachDownload.get({id: id_hash}, onSuccess, onError);

                function onSuccess(data, headers) {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    var fileURL = window.URL.createObjectURL(data.data);
                    a.href = fileURL;
                    a.download = headers('content-disposition').substring(21);
                    a.click();
                    order.select = "";
                }

                function onError(error) {

                }
            }

            function setOrderProgress() {
                vm.progress = 1;

                if(vm.customerOrder.state == "CREATED"){
                    vm.progress = 1
                }
                else if(vm.customerOrder.state == "SAMPLES_RECEIVED"){
                    vm.progress = 2;
                }
                else if(vm.customerOrder.state == "PRELIMINARY_RESULTS"){
                    vm.progress = 3
                }
                else if(vm.customerOrder.state == "COMPLETED"){
                    vm.progress = 4
                }
                else if(vm.customerOrder.state == "INVOICED"){
                    vm.progress = 5
                }
            }


            vm.downloadOrderPdf = downloadOrderPdf;

            //show loading image first, when order is loaded show cached images,finally show all images from server

                if (vm.customerOrder.cachedPictures != null && vm.customerOrder.cachedPictures.length>0) {
                    setPictures(vm.customerOrder.cachedPictures )
                }
                else {
                    var defaultPicture = new Array();
                    defaultPicture.push({id: 0, name: "Empty Sample", url: "content/images/loading.gif"});
                    setPictures(defaultPicture )
                }

            function getPictures () {
                var id = vm.customerOrder.id;

               OrderPicture.query({id: id}, onSuccess, onError);

                function onSuccess(data, headers) {
                      setPictures(data);
                }

                function onError(error) {
                    console.log(error.data.message)
                    //AlertService.error(error.data.message);
                }
            }

            function setPictures(pictures) {

                vm.pictures = pictures;

                vm.numOfSelectedSamplePictures = vm.pictures.length;

                if( vm.numOfSelectedSamplePictures > 1){
                    vm.show = 1;
                }
                else {

                }
                if( vm.numOfSelectedSamplePictures < 1){
                    for(var i = 0; i <(4 - vm.numOfSelectedSamplePictures ); i++ ){
                        vm.pictures.push({id: i, name: "Empty Sample", url: "content/images/no_image_available-small.png"});
                    }
                }
            }

            $timeout(function (){
                angular.element('.form-group:eq(1)>input').focus();
            });


            // function save () {
            //     vm.isSaving = true;
            //     if (vm.customerOrder.id !== null) {
            //         CustomerOrder.update(vm.customerOrder, onSaveSuccess, onSaveError);
            //     } else {
            //         CustomerOrder.save(vm.customerOrder, onSaveSuccess, onSaveError);
            //     }

            // }

            // function onSaveError () {
            //     vm.isSaving = false;
            // }

            // vm.datePickerOpenStatus.dateReceived = false;
            // vm.datePickerOpenStatus.checkoutDate = false;

            // function openCalendar (date) {
            //     vm.datePickerOpenStatus[date] = true;
            // }


            $scope.getAttachments = function (sample, id) {
                vm.getAttachmentsLoader = true;
                vm.idOfSelectedSample = id;

                if(sample.attachments != null){
                    vm.getAttachmentsLoader = false;
                    return;
                }

                SampleAttach.query({id: id}, onSuccess, onError);
                function onSuccess(data, headers) {
                    var processed_attachments = [];

                    vm.getAttachmentsLoader = false;
                    sample.attachments = data;

					// if(vm.consolidated_business_type.includes('food')) {
                    if(!vm.consolidated_business_type.includes('cannabis')) {
						sample.attachments.forEach( function(attachment) {
							if(!attachment.file_name.toLowerCase().includes('label')) {
								processed_attachments.push(attachment)
							}
						})
						sample.attachments = processed_attachments;
                    }
					// }

                }
                function onError(error) {
                    console.log(error.data.message)
                }
            }

            $scope.downloadAttachment = function (id_hash, sample) {
                if(id_hash && id_hash != 'coa'){
                    SampleAttachDownload.get({id: id_hash}, onSuccess, onError);

                    function onSuccess(data, headers) {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";

                        var fileURL = window.URL.createObjectURL(data.data);
                        a.href = fileURL;
                        a.download = headers('content-disposition').substring(21);
                        a.click();
                    }
                    function onError(error) {
                        console.log(error);
                    }
                }
                else {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    SampleCoaPdfService.downloadPdf(sample.hash).then(function(result) {
                        var file = new Blob([result.data], {
                            type: 'application/pdf'
                        });

                        a.href = window.URL.createObjectURL(file);
                        a.download = sample.id + '_COA_Summary.pdf';
                        a.click();
                    });
                }
                sample.select = "";
            }


            function downloadOrderPdf () {
                var id = $scope.toStateParams.orderId

                if (vm.customerOrder.orderType === 'COMPLIANCE') {
                 var fileName = id + " Compliance Submission Form.pdf";
                } else {
                 var fileName = id + " R&D Submission Form.pdf";
                }

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                CustomerOrderPdfService.downloadPdf(id).then(function (result) {
                    var file = new Blob([result.data], {type: 'application/pdf'});
                    var fileURL = window.URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName;
                    a.click();
                }).catch(function(error){
                    if(error.status === 401){
                        //clear();
                    }
                });
            }

            angular.element(document).ready(function() {
                setTabsVisibility(); 

                $(".status-wizard-step a").each(function(index, element) {
                    index++;
                    $(this).attr("id", "open-tooltip-"+index);
                    $("#open-tooltip-"+index).click(function(){
                        $(".status-wizard-step a").not(this).removeClass('open');
                        $(this).toggleClass("open");
                        return this;
                    });
                });

                $(".status-wizard-step.active > .status-wizard-dot span" ).remove();
            });

    }
})();
