(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('CityList', CityList);

    CityList.$inject = ['$resource'];
    function CityList ($resource) {
        var resourceUrl =  'api/city-name/search/:searchString';

        return $resource(resourceUrl, {}, {

            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                isArray: true
            }
        });
    }

})();
