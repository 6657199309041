(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth'];

    function LoginController ($rootScope, $state, $timeout, Auth) {
        var vm = this;
        angular.element(document).ready(function() {
            angular.element('#wrap').removeClass("wrap");
        });

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.showOldUsers = false;
        vm.loginAsSelected = false;
        vm.signOutCompletly = signOutCompletly
        vm.oldUser = null;

        vm.anotherUser = anotherUser;
        vm.step = 'oldUsers';

        function signOutCompletly(){
            vm.step = 'login';
            vm.username = "";
            localStorage.removeItem('oldUserLogin')
            localStorage.removeItem('oldUserFirstName')
        }

        function anotherUser(){
            vm.step = 'login';
            vm.username = "";
        }

        function oldUsersCheck (){
            vm.step = 'login';
            try {
                if(localStorage.getItem('oldUserLogin')){
                    vm.oldUserLogin = JSON.parse(localStorage.getItem('oldUserLogin'))
                    vm.oldUserFirstName = JSON.parse(localStorage.getItem('oldUserFirstName'))
                    vm.step = 'loginAs';
                    vm.username = vm.oldUserLogin;
                    vm.password = '';


                } else {
                    vm.step = 'login';
                }
            }
           catch(err) {}

        }

        oldUsersCheck();

        $timeout(function (){angular.element('#username').focus();});

        //setUpContentWidth();
        /*$scope.$on('$viewContentLoaded', function(){
         // Run after view loaded.
            setUpContentWidth();
        });*/

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            //$uibModalInstance.dismiss('cancel');
        }



        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                //$uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                //if (Auth.getPreviousState()) {
                    //var previousState = Auth.getPreviousState();
                    //Auth.resetPreviousState();
                    //$state.go(previousState.name, previousState.params);
                //}
                $state.go('home',{}, {reload:true});
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function register () {
            //$uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            //$uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }

        // function setUpContentWidth(){
        //     var x = document.getElementsByClassName("remove-all");
        //     var y = document.getElementsByClassName("main-sidebar");
        //     var z = document.getElementsByClassName("content-wrapper");
        //
        //
        //     for (var i = 0; i < z.length; i++) {
        //          if (z[i].classList) {
        //              z[i].classList.toggle("big-box-gone");
        //          }
        //     }
        //
        //
        // }

    }
})();
