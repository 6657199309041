(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('SubscriptionService', Subscription);

    Subscription.$inject = ['$resource'];
    function Subscription($resource) {
        var resourceUrl =  'api/notifications/:id';

        return $resource(resourceUrl, {}, {
            'getSubscriptionSettings': {
                method: 'GET',
                url: 'api/notifications/subscriptions'
            },
            'saveSubscriptionSettings': {
                method: 'POST',
                url: 'api/notifications/subscribe'
            }
        });
    }
})();
