(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('DialogCompSampleSubmitController', DialogCompSampleSubmitController);

    DialogCompSampleSubmitController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Sample',
        'SampleTest', 'ProductType', 'CustomerOrder','ProductCategory','ProductTypeByCategory','StrainNameSearch', 'Panel'];

    function DialogCompSampleSubmitController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, Sample, SampleTest,
                                           ProductType, CustomerOrder,ProductCategory,ProductTypeByCategory,StrainNameSearch,Panel) {

        var vm = this;
        var update = false;


        $rootScope.$on('closeModal', function (event, data) {
            clear();
        });

        vm.strainTypes  = [{
             'id': 1,
             'name': 'Sativa'
           }, {
              'id': 2,
              'name': 'Indica'
           }, {
             'id': 3,
             'name': 'Hybrid'
           },
           {
             'id': 4,
             'name': 'CBD'
           }];

        vm.panels = {}
        vm.phases = [];
        Panel.query({}, function(data){
        	if (data) {
        		for (var i = 0; i < data.length; i++) {
        			if (data[i].portalIdentifier) {
        				vm.panels[data[i].portalIdentifier] = data[i];
        			}
        		}
        		vm.phases = [
        	        	/* Plant */
        	            {
        		           'id': vm.panels['PLANT_COMPLIANCE_PHASE_3'].id,
        		           'name': 'Plant Matrix',
        		           'value':'PLANT_COMPLIANCE_PHASE_3'
        	            },

        	            /* Inhalable Extract */
        	            {
        	            	'id': vm.panels['INHALABLE_EXTRACT_COMPLIANCE_PHASE_3'].id,
        	            	'name': 'Inhalable Extract Matrix',
        	            	'value':'INHALABLE_EXTRACT_COMPLIANCE_PHASE_3'
        	            },

        	            /* Non-Inhalable */
        	            {
        	            	'id': vm.panels['EDIBLE_COMPLIANCE_PHASE_3'].id,
        	            	'name': 'Liquid Edible/Topical Matrix',
        	            	'value':'EDIBLE_COMPLIANCE_PHASE_3'
        	            },

        	            /* Solid Edible */
        	            {
        	            	'id': vm.panels['SOLID_EDIBLE_COMPLIANCE_PHASE_3'].id,
        	            	'name': 'Solid/Semi-Solid Edible Matrix',
        	            	'value':'SOLID_EDIBLE_COMPLIANCE_PHASE_3'
        	            },

        	            /* Pre-Roll Compliance */
        	            {
        	            	'id': vm.panels['PRE_ROLL_COMPLIANCE_PHASE_3'].id,
        	            	'name': 'Pre-Roll Matrix',
        	            	'value': 'PRE_ROLL_COMPLIANCE_PHASE_3'
        	            }
        	            ];
        	}
        });



         vm.inhalable_edible_array  = [{
            'id': 1,
            'name': 'Inhalable',
            'value':'INHALABLE'
            },
            {
            'id': 2,
            'name': 'Non-Inhalable',
            'value':'NONINHALABLE'
            }];

        vm.sample = entity;

        vm.productCategories = ProductCategory.query();

        vm.populateTypes = populateTypes;

        vm.loadStrains = loadStrains;

        vm.setStrainName = setStrainName;

        vm.resetProductAndHarvestInputs = resetProductAndHarvestInputs;

        vm.samples = $scope.$root.samplesComp;

        if(vm.sample.id != null){

            var sampleOld = vm.samples[vm.sample.id];

            vm.sample.name = sampleOld.name;
            vm.sample.lotBatchNumber = sampleOld.lotBatchNumber;
            vm.sample.productCategory = sampleOld.productCategory;
            vm.populateTypes();
            vm.sample.productType = sampleOld.productType;
            vm.sample.strainName = sampleOld.strainName;
            vm.sample.strainType = sampleOld.strainType;
            vm.sample.sCode = sampleOld.sCode;
            vm.sample.addTerpenes = sampleOld.addTerpenes;
            vm.sample.nrUnits = sampleOld.nrUnits;
            vm.sample.sampleWeight = sampleOld.sampleWeight;
            vm.sample.batchType = sampleOld.batchType;
            vm.sample.productBatchSize = sampleOld.productBatchSize;
            vm.sample.harvestBatchSize = sampleOld.harvestBatchSize;
            vm.sample.harvestDate = sampleOld.harvestDate;
            vm.sample.manufactureDate = sampleOld.manufactureDate;

            update = true;
        }

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.datePickerOpenStatus = {
        		harvestDate: false,
        		manufactureDate: false
        }
        vm.openCalendar = openCalendar;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function resetProductAndHarvestInputs(batchType){
        	if (batchType === "PRODUCT_BATCH"){
        		vm.sample.harvestBatchSize = null;
        		vm.sample.sampleWeightPerIncrement = null;
        		vm.sample.harvestDate = null;
        		vm.sample.manufactureDate = null;

        	} else if (batchType === "HARVEST_BATCH"){
        		vm.sample.productBatchSize = null;
        		vm.sample.harvestDate = null;
        		vm.sample.manufactureDate = null;
        	}
        };


        function save (param) {
            if(param == 1){
                if(vm.sample.lotBatchNumber == ''){vm.sample.lotBatchNumber = null}
                if(update){
                    vm.samples[vm.sample.id] = vm.sample;
                    $uibModalInstance.close("");
                }else{
                      if(vm.sample.batchType == 'PRODUCT_BATCH') {
                         vm.sample.harvestBatchSize = null;
                      }

                      if(vm.sample.batchType == 'HARVEST_BATCH') {
                       vm.sample.productBatchSize = null;
                      }

                    vm.sample.id = vm.samples.length;
                    vm.samples.push(vm.sample);
                    $uibModalInstance.close("");
                }
            }

            else if(param == 2 && update){

                if(vm.sample.lotBatchNumber == ''){vm.sample.lotBatchNumber = null}
                    vm.samples[vm.sample.id] =angular.copy(vm.sample);
                    vm.sample.id = vm.samples.length;
                    vm.sample.name = '';
                    vm.sample.lotBatchNumber = null;
                    vm.sample.productCategory = null;
                    vm.sample.productType = null;
                    vm.sample.strainName = null;
                    vm.sample.strainType = null;
                    vm.sample.phase = null;

                    vm.addTerpenes = null;
                    vm.sample.sCode = null;
                    vm.sample.nrUnits = null;
                    vm.sample.sampleWeight = null;
                    vm.productTypes = null;
                    vm.sample.harvestDate = null;
                    vm.sample.manufactureDate = null;
                    update = false;
            }

            else if(param == 2){
                if(vm.sample.lotBatchNumber == ''){
                    vm.sample.lotBatchNumber = null;
                }
                if(update){
                    vm.samples[vm.sample.id] = vm.sample;

                }else{
                     if(vm.sample.batchType == 'PRODUCT_BATCH') {
                        vm.sample.harvestBatchSize = null;
                      }

                      if(vm.sample.batchType == 'HARVEST_BATCH') {
                       vm.sample.productBatchSize = null;
                       vm.sample.sampleWeightPerIncrement = null;
                      }

                    vm.sample.id = vm.samples.length;
                    vm.sampleForArray = angular.copy(vm.sample);
                    vm.samples.push(vm.sampleForArray);

                    vm.sample.name = '';
                    vm.sample.lotBatchNumber = null;
                    vm.sample.productCategory = null;

                    vm.sample.productType = null;
                    vm.sample.strainName = null;
                    vm.sample.strainType = null;
                    vm.sample.phase = null;

                    vm.sample.addTerpenes = null;
                    vm.sample.sCode = null;
                    vm.sample.nrUnits = null;
                    vm.sample.sampleWeight = null;

                    vm.productTypes = null;

                    vm.harvestDate = null;
                    vm.manufactureDate = null;

                    update = false;

                }
            }

        }
        function populateTypes () {
            vm.productTypes = ProductTypeByCategory.query(vm.sample.productCategory);
        }

        function loadStrains(){
           vm.suggestedStrains = StrainNameSearch.query({searchString: vm.sample.strainName.name});
        }

        function setStrainName(name){
            vm.sample.strainName.id = 0;
            vm.sample.strainName.name = name;
            vm.suggestedStrains = [];
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
