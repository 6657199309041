(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('InvoiceLogController', InvoiceLogController);

     InvoiceLogController.$inject = ['$state', '$stateParams','$scope','$rootScope', '$http','CustomerOrder','CustomerOrderSearch',
    'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','Auth', 'Principal', 'ProfileService',
    'LoginService','CustomerByUser', 'Invoice', 'InvoiceAttachDownload', 'STAGE', 'SquareService',
    'SweetAlert', 'CustomerSquare', 'Payment', 'PaymentById', 'DateUtils', 'Customer', 'PaymentReceipt', 'Config'];

    function InvoiceLogController($state, $stateParams, $scope,$rootScope, $http, CustomerOrder,CustomerOrderSearch, ParseLinks,
                              AlertService, paginationConstants, pagingParams, Auth, Principal, ProfileService,
                              LoginService,CustomerByUser, Invoice, InvoiceAttachDownload, STAGE, SquareService,
                              SweetAlert, CustomerSquare, Payment, PaymentById, DateUtils, Customer, PaymentReceipt, Config) { /*,Auth, Principal, ProfileService, LoginService*/

        var vm = this;
        var square = {};
        
        function appendSquareAPI() {
            Config.generic({data: 'STAGE'},
                function(data) {
                    var script = document.createElement('script');
                    var square_url = null;

                    if(data.data == 'dev') {
                        square_url = 'https://sandbox.web.squarecdn.com/v1/square.js';
                    }
                    else {
                        square_url = 'https://web.squarecdn.com/v1/square.js';
                    }

                    script.type = 'text\/javascript';
                    script.src = square_url;
                    script.id = 'square-api'
                    $("head").append(script)
                    setTimeout(function() {
                        createSquarePaymentForm();
                    }, 2000);
                },
                function(e) {
                    console.log(e);
                }
            );
        }

        const squareCardInputStyles = {
            '.input-container': {
                borderColor: '#e6ebf0',
                borderRadius: '4px',
              },
              '.input-container.is-focus': {
                borderColor: '#e6ebf0'
              },
              'input::placeholder': {
                color: '#c1c5c9'
              },
              input: {
                color: '#3d5269'
              }
        }

        const createSquarePaymentForm = () => {
            vm.payment.api = Square.payments(vm.payment.squareAppId, vm.payment.squareLocationId);

            vm.payment.api.card({style:squareCardInputStyles}).then(data => {
                square.card = data;
                square.card.attach('#card-container').then(data => {
                    square.cardButton = document.getElementById('card-button');
                    square.cardButton.addEventListener('click', cardEventHandler);
                    square.cardButton.disabled = false;
                });
            });

            const achButton = document.getElementById('ach-button');

            vm.payment.api.ach({ redirectURI: window.location.href}).then(data => {
                vm.ach = data;
                achButton.addEventListener('click', achEventHandler);
            });

            function achEventHandler(event) {
                event.preventDefault();
                vm.payment_processing = true;

                console.log('calling tokenize');
                vm.ach.tokenize({accountHolderName: vm.customerData.name}).then(data => {

                    if (data.error) {
                        vm.payment_processing = false;
                        try{
                            vm.payment.processSquareRequestErrors('There was an issue connecting with your bank.', data.error);
                        }
                        catch (e) {
                           console.log(e)
                        }
                    }
                    else if (data.status == 'OK') {
                        vm.payment.ach_info = data;
                        vm.initiatePayment('ach');
                    }
                });
            }

            function cardEventHandler(event) {
                event.preventDefault();
                resetPaymentForm();

                try {
                    square.cardButton.disabled = true;
                    square.card.tokenize().then(data => {
                        if (data.status === 'OK') {
                            vm.saveCard(data.token, vm.customerData.company_name, true);
                        }
                        else if(data.errors) {
                            console.log(data);
                            delete vm.payment.api;
                            vm.payment.processSquareRequestErrors('There was an issue saving your card.', data.errors);
                        }
                    })

                    square.cardButton.disabled = false;
                }
                catch (e) {
                    console.error(e);
                    vm.payment.processSquareRequestErrors('There was an issue saving your card.', e);
                }
            };
        }

        const resetPaymentForm = () => {
            const card_form = document.getElementsByClassName('sq-card-wrapper');

            if(card_form.length) {
                const cardButton = document.getElementById('card-button')
                cardButton.replaceWith(cardButton.cloneNode(true));
                card_form[0].remove();
                createSquarePaymentForm();
            }
        }

        SquareService.params({},
            function(data) {
                vm.payment.squareAppId = data.square_application_id;
                vm.payment.squareLocationId = data.square_location_id;
                appendSquareAPI()
            },
            function(e) {
                console.log(e)
            }
        );


        vm.downloadSelectedPaymentReceipts = function() {
            var payment_list = [];
            vm.payment_history.payments.forEach( function(payment) {
                if(payment.checked) {
                    payment_list.push(payment.id);
                }
            });

            PaymentReceipt.merged({payments: payment_list, order: determineOrder(vm.payment_history)},
                function (data, headers) {
                    var url = window.URL.createObjectURL(data.data);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = 'Payments.pdf';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                },
                function (e) {
                    AlertService.error("Could not print payments");
                }
            );
        }

        vm.downloadPaymentReceipt = function(id) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            PaymentReceipt.get({id: id},
                function(data){
                    var url = window.URL.createObjectURL(data.data);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = 'Payment_Receipt_' + id + '.pdf';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                },
                function(e) {
                    console.log(e)
                }
            );
        }

        vm.downloadOpenBalance = function() {
            Invoice.openBalance({customer_id:  vm.customerData.id},
                function (data, headers) {
                    var contentDisposition = headers('content-disposition');
                    var filename = contentDisposition.split('=')[1]
                    var url = window.URL.createObjectURL(data.data);
                    var a = document.createElement("a");

                    document.body.appendChild(a);
                    a.download = filename;
                    a.href = url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                },
                function (e) {
                    AlertService.error("Could not print payments.");
                }
            );
        }

        vm.printSelectedPaymentReceipts = function() {
            var payment_list = [];
            vm.payment_history.payments.forEach( function(payment) {
                if(payment.checked) {
                    payment_list.push(payment.id);
                }
            });

            PaymentReceipt.merged({payments: payment_list, order: determineOrder(vm.payment_history)},
                function (data, headers) {
                    if (data.data.byteLength == 0){
                        return;
                    }
                    var url = URL.createObjectURL(data.data);

                    setTimeout(function() {
                        window.open(url, '_blank');
                    }, 200);

                },
                function (e) {
                    AlertService.error("Could not print payments");
                }
            );
        }

        vm.printPaymentReceipt = function(id) {
            PaymentReceipt.merged({payments: [id], order: determineOrder(vm.payment_history)},
                function (data, headers) {
                    if (data.data.byteLength == 0){
                        return;
                    }
                    var url = URL.createObjectURL(data.data);

                    setTimeout(function() {
                        window.open(url, '_blank');
                    }, 200);

                },
                function (e) {
                    AlertService.error("Could not print payments");
                }
            );
        }

        vm.payment = {
            cardHolder: null,
            selectedCard: null,
            amount: null,
            cards: null,
            squareAppId: null,
            primary_card: null,
            pay_selected_total: 0,
            emails: null,
            id: null
        }

        vm.autopay_checked = null;
        vm.autopay_by_date = null;
        vm.show_all_tabs = true;
        vm.activeSnackbar = false;
        vm.make_primary_payment_method = true;
        vm.payment_processing = false;
        vm.autopay_processing = false;
        vm.autopay_by_date_processing = false;
        vm.new_card_autopay_checkbox = false;

        vm.generic = {};
        var generic = {};

        vm.payment_methods = {};

        function determineOrder(tabDict) {
            if(tabDict.sort_filters.filter == 'id') {
                return tabDict.sort_filters.order
            }
            else {
                return tabDict.default_sort.order
            }
        }
        function removeModal(params) {
            if(document.getElementById("plaid-link-iframe-1") && document.getElementById("plaid-link-iframe-1").style.display === "block"){
                $('#infoModal').modal('hide');
            }
        }
        vm.open_invoices = {
            table: 'open_invoices',
            loading: false,
            total_balance: 0,
            total_items: 0,
            page_plus_one: 1,
            invoices: [],
            invoices_to_pay: [],
            all_invoices_checked: false,
            open_filters: false,
            date_picker: {
                invoice_date_lower: null,
                invoice_date_upper: null,
                due_date_lower: null,
                due_date_upper: null,
            },
            filters: {
                amount_lower: null,
                amount_upper: null,
                balance_lower: null,
                balance_upper: null,
                invoice_date_lower: null,
                invoice_date_upper: null,
                due_date_lower: null,
                due_date_upper: null,
            },
            base_filters: {
                size: 20,
                paid: false,
                status: ['APPROVED', 'INVOICED'],
                balance_lower: 0,
                amount_lower: 0
            },
            sort_filters: {
                field: null,
                order: 'desc'
            },
            default_sort: {
                field: 'invoice_date',
                order: 'desc'
            }
        };

        vm.payment_history = {
            table: 'payment_history',
            loading: false,
            total_items: 0,
            page_plus_one: 1,
            payments: [],
            all_payments_checked: false,
            any_payments_checked: false,
            open_filters: false,
            date_picker: {
                invoice_date_lower: null,
                invoice_date_upper: null,
                due_date_lower: null,
                due_date_upper: null,
                date_paid_lower: null,
                date_paid_upper: null
            },
            filters: {
                payment_amount_lower: null,
                payment_amount_upper: null,
                payment_date_lower: null,
                payment_date_upper: null,
            },
            base_filters: {
                size: 20,
            },
            sort_filters: {
                field: null,
                order: 'desc'
            },
            default_sort: {
                field: 'id',
                order: 'desc'
            }
        }

        vm.closed_invoices = {
            table: 'closed_invoices',
            loading: false,
            total_items: 0,
            page_plus_one: 1,
            invoices: [],
            all_invoices_checked: false,
            any_invoices_checked: false,
            open_filters: false,
            date_picker: {
                invoice_date_lower: null,
                invoice_date_upper: null,
                due_date_lower: null,
                due_date_upper: null,
                date_paid_lower: null,
                date_paid_upper: null
            },
            filters: {
                amount_lower: null,
                amount_upper: null,
                invoice_date_lower: null,
                invoice_date_upper: null,
                due_date_lower: null,
                due_date_upper: null,
                date_paid_lower: null,
                date_paid_upper: null,
            },
            base_filters: {
                size: 20,
                paid: true,
                status: ['INVOICED', 'APPROVED'],
            },
            sort_filters: {
                field: null,
                order: 'desc'
            },
            default_sort: {
                field: 'invoice_date',
                order: 'desc'
            }
        };

        vm.generic.changeSort = function(tabDict, field) {
            if(tabDict.sort_filters.field == field) {
                if(tabDict.sort_filters.order == 'asc') {
                    tabDict.sort_filters.order = 'desc';
                }
                else if(tabDict.sort_filters.order == 'desc'){
                    tabDict.sort_filters.order = 'asc';
                };
            }
            else {
                tabDict.sort_filters.order = 'desc';
            }

            tabDict.sort_filters.field = field;

            if(tabDict.table == 'payment_history') {
                vm.payment_history.query(false);
            }
            else {
                vm.generic.query(tabDict, false);
            }
        }

        generic.bundleFilters = function(tabDict) {
            var filters = {};

            // if(tabDict.table.includes('invoices')) {
            //     filters['only_return_invoices'] = true;
            // }

            if(tabDict.sort_filters.field == null) {
                filters['sort'] = tabDict.default_sort.field + ',' + tabDict.default_sort.order;
            }
            else {
                filters['sort'] = tabDict.sort_filters.field + ',' + tabDict.sort_filters.order;
            }

            if(tabDict.filters) {
                angular.forEach(tabDict.filters, function(value, key) {
                    filters[key] = value;
                });
            }

            angular.forEach(tabDict.filters, function(value, key) {
                filters[key] = value;
            });

            angular.forEach(tabDict.base_filters, function(value, key) {
                filters[key] = value;
            });

            filters['page'] = tabDict.page_plus_one - 1;

            filters['size'] = parseInt(filters['size'])

            return filters
        }

        function processInvoices(invoices) {
            var processed_invoices = []
            processed_invoices = setInvoicesStatus(invoices);

            return processed_invoices;
        }


        vm.payment_history.checkedInvoices = function() {
            for( var i = 0; i < vm.payment_history.payments.length; i++) {
                    if(vm.payment_history.payments[i].checked) {
                        vm.payment_history.any_invoices_checked = true;
                        return;
                    }
            }
            vm.payment_history.any_invoices_checked = false;
        }

        vm.payment_history.checkAll = function() {
            vm.payment_history.any_invoices_checked = vm.payment_history.all_payments_checked;
            vm.payment_history.payments.forEach( function(payment) {
                payment.checked = vm.payment_history.all_payments_checked;
            });
        }

        vm.payment_history.query = function(query) {
            if(query) {
                vm.payment_history.page_plus_one = 1
            }
            Payment.filter(generic.bundleFilters(vm.payment_history),
                function(data, headers) {
                    vm.payment_history.payments = data.data;
                    vm.payment_history.total_items = data.total_count;
                },
                function(e) {
                    console.log(e);
                }
            );
        }

        vm.payment_history.clearFilters = function() {
            angular.forEach(vm.payment_history.filters, function(value, key) {
                vm.payment_history.filters[key] = null;
            });
           vm.payment_history.query(true);
        }

        vm.generic.clearFilters = function(tabDict) {
            angular.forEach(tabDict.filters, function(value, key) {
                tabDict.filters[key] = null;
            });
           vm.generic.query(tabDict, true);
        }

        vm.generic.query = function(tabDict, query) {
            if(query) {
                tabDict.page_plus_one = 1
            }
            tabDict.loading = true;

            Invoice.filter(generic.bundleFilters(tabDict),
                function(data, headers) {
                    tabDict.invoices = processInvoices(data);
                    tabDict.total_items = headers('X-Total-Count');

                    if("total_balance" in tabDict) {
                        getUnpaidInvoices();
                    }
                    tabDict.loading = false;
                },
                function(e) {
                    console.log(e);
                    tabDict.loading = false;
                }
            );

        }

        vm.activeTab = {
            open_invoices: true,
            closed_invoices: false,
            payment_methods: false,
            payment_history: false,
        }


        function achPaymentPopup() {
            SweetAlert.swal({
                title: 'Payment processed successfully.',
                text: 'Thank you for your payment. It will be in the \'Payment History\' subtab of the portal when it clears, typically in 3-5 business days.',
                type: 'success',
                confirmButtonColor: '#4f8fcc',
                confirmButtonText: 'Ok',
                closeOnConfirm: true
            },
            function(confirm) {
                queryAll();
            });
            $('#infoModal').modal('hide');
            vm.open_invoices.invoices_to_pay = [];
            vm.payment.pay_selected_total = 0;
            vm.emails = null;
            vm.payment.id = null;
            vm.payment_processing = false;
            vm.open_invoices.all_invoices_checked = false;
        }


        function updateInvoices(square_payment_id) {
            Payment.createAchPayment({
                    total: vm.payment.amount,
                    square_payment_id: square_payment_id,
                    invoices: vm.open_invoices.invoices_to_pay_array
                },
                function() {
                    achPaymentPopup();
                },
                function(e) {
                    console.log(e)
                    vm.payment.receipt_number = null;
                    vm.payment_processing = false;
                }
            );
        }


        function applyQBenchPayment(square_payment_id) {
            Payment.createPayment({
                payment_dict: {
                    amount: vm.payment.amount,
                    payment_type_id: 34,
                },
                invoices_dict : {
                    invoice_payments:  vm.open_invoices.invoices_to_pay_array
                },
                params: {
                    square_payment_id: square_payment_id,
                    card_info: vm.payment.primary_card_info
                },
            }, applyPaymentsInBatchSuccess, applyPaymentsInBatchFail);
        }

        function createInvoicesToPayArray() {
            var invoice_payments = [];

            if(vm.open_invoices.invoices_to_pay.length > 0) {
                vm.open_invoices.invoices_to_pay.forEach( function(invoice) {
                    if(!hasPendingAch(invoice)) {
                        invoice_payments.push({
                            invoice_id: invoice.id,
                            applied_amount: invoice.outstanding_amount
                        });
                    }
                });
                vm.emails = vm.open_invoices.invoices_to_pay[0].email_to
                vm.payment.amount = vm.payment.pay_selected_total;
            }
            else {
                vm.open_invoices.unpaid_invoices.forEach( function(invoice) {
                    if(!hasPendingAch(invoice)) {
                        invoice_payments.push({
                            invoice_id: invoice.id,
                            applied_amount: invoice.outstanding_amount
                        });
                    }
                });
                vm.emails = vm.open_invoices.unpaid_invoices[0].email_to;
                vm.payment.amount = vm.open_invoices.total_balance;
            }

            console.log(vm.payment);
            vm.open_invoices.invoices_to_pay_array = invoice_payments;
        }

        vm.addCheckedInvoices = function() {
            var total = 0;

            vm.payment.pay_selected_total = 0;
            vm.open_invoices.invoices_to_pay = [];

            vm.open_invoices.invoices.forEach( function(invoice) {
                if(invoice.checked) {
                    vm.open_invoices.invoices_to_pay.push(invoice);

                    if(!hasPendingAch(invoice)) total += invoice.outstanding_amount;
                }
            });
            vm.payment.pay_selected_total = total;
            createInvoicesToPayArray();
        }

        vm.closed_invoices.changeInvoicesToPayAll = function() {
            vm.closed_invoices.any_invoices_checked = vm.closed_invoices.all_invoices_checked;
            vm.closed_invoices.invoices.forEach( function(invoice) {
                invoice.checked = vm.closed_invoices.all_invoices_checked;
            });
        }

        vm.closed_invoices.checkedInvoices = function() {
            for( var i = 0; i < vm.closed_invoices.invoices.length; i++) {
                    if(vm.closed_invoices.invoices[i].checked) {
                        vm.closed_invoices.any_invoices_checked = true;
                        return;
                    }
            }
            vm.closed_invoices.any_invoices_checked = false;
        }


        vm.open_invoices.changeInvoicesToPayAll = function() {
            vm.open_invoices.invoices.forEach( function(invoice) {
                invoice.checked = vm.open_invoices.all_invoices_checked;
            });
            vm.addCheckedInvoices()
        }

        vm.open_invoices.changeInvoicesToPay = function() {
            vm.open_invoices.invoices.forEach( function(invoice) {
                if(invoice.checked) {
                    vm.open_invoices.invoices_to_pay.push(invoice);
                }
            });
            vm.addCheckedInvoices()
        }

        vm.changeAutopayDate = function() {
            vm.autopay_by_date_processing = true;
            Customer.updateQbench({'autopay_by_date': vm.autopay_by_date},
                function(data){
                    vm.autopay_by_date_processing = false;
                },
                function(e) {
                    console.log(e);
                    vm.autopay_by_date_processing = false;
                }
            )
        }

        vm.setAutopay = function(value) {
            vm.autopay_processing = true;
            CustomerSquare.toggleAutopay({'autopay': value},
                function(data) {
                    vm.autopay_processing = false;
                    vm.autopay_checked = data.autopay;
                    vm.new_card_autopay_checkbox = false;
                    if(vm.autopay_checked) {
                        $('#snackbar').addClass('activeSnackbar')
                        setTimeout(function()  {
                            $('#snackbar').removeClass('activeSnackbar')
                        },3000);
                    }
                },
                function(e) {
                    vm.autopay_processing = false;
                    console.log(e);
                }
            );
        }

        vm.toggleAutopay = function() {
            if(vm.autopay_checked && !vm.payment.cards) {
                SweetAlert.swal({
                    title: "No Card on File",
                    text: "Please add a primary card using the 'Payment Methods' tab before enabling autopay.",
                    type: "warning",
                    confirmButtonColor: "#27ae60",
                    confirmButtonText: "Ok",
                });
                vm.autopay_checked = false;
            }
            else {
                vm.autopay_processing = true;
                CustomerSquare.toggleAutopay({},
                    function(data) {
                        vm.autopay_processing = false;
                        vm.autopay_checked = data.autopay;
                        if(vm.autopay_checked) {
                            $('#snackbar').addClass('activeSnackbar')
                            setTimeout(function()  {
                                $('#snackbar').removeClass('activeSnackbar')
                            },3000);
                        }
                    },
                    function(e) {
                        vm.autopay_processing = false;
                        console.log(e);
                    }
                );
            }
        }

        vm.payment.updatePrimaryCard = function(id) {
            CustomerSquare.updatePrimaryCard({'square_primary_card_id': id},
            function(data) {
                if(!vm.payment.cards) {
                    getCustomerSquareAccount();
                }
                else {
                    vm.payment.primary_card = id;

                    vm.payment.cards.forEach( function(card) {
                        card.is_primary = false;
                    });

                    vm.payment.cards.forEach( function(card) {
                        if(card.id == id) {
                            card.is_primary = true;
                        }
                    });
                    sortCards();
                }
            },
            function(e) {
                vm.payment.primary_card = null;
                console.log(e);
            })
        }

        function sortCards() {
            vm.payment.cards.sort( function(card_a ,card_b) {
                if (!card_a.is_primary) {
                    return 1;
                }
                else if (!card_b.is_primary){
                    return -1;
                }
            });
        }

        function queryAll() {
            vm.generic.query(vm.open_invoices, false);
            vm.generic.query(vm.closed_invoices, false);
            vm.payment_history.query(false);
        }

        queryAll();

        vm.switchTab = function(tab) {
            angular.forEach(vm.activeTab, function(value, key) {
                vm.activeTab[key] = false;
            });

            vm.activeTab[tab] = true;
        }

        vm.hideAllTabs = function() {
            angular.forEach(vm.activeTab, function(value, key) {
                vm.activeTab[key] = false;
            });

            vm.show_all_tabs = false;
        }

        vm.payment_methods.cancelPaymentMethod = function() {
            vm.payment_methods.show_tab = false;
            vm.switchTab('payment_methods');
            vm.show_all_tabs = true;
            resetPaymentForm();
            // resetSquarePaymentFormFields();
        }

        vm.payment_methods.addPaymentMethod = function() {
            vm.hideAllTabs();
            vm.payment_methods.show_tab = true;
            vm.show_all_tabs = false;
            vm.make_primary_payment_method = true;
            if(!vm.payment.cards && vm.autopay_by_date) {
                vm.new_card_autopay_checkbox = true;
            }
        }

        vm.account_balance = 0;

        vm.markNaBefore = new Date('5/1/2020')

        $scope.itemArray = [
            {id: 0, name: 'All', value: 'ALL'},
            {id: 1, name: 'Approved', value: 'APPROVED'},
            {id: 2, name: 'Draft', value: 'DRAFT'},
            {id: 3, name: 'Invoiced', value: 'INVOICED'},
            {id: 4, name: 'Canceled', value: 'CANCELLED'},
        ];

        $scope.selected = { value: $scope.itemArray[0]};

        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "Billing";
            $rootScope.searchVm = vm;
        }

        vm.itemsPerPage = "20";
        vm.status = ["INVOICED"];
        vm.filterIsApplied = false;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;

        vm.getCustomerData = getCustomerData;

        vm.showFilterPanel = false;

        getCustomerData();

        vm.datePickerOpenStatus = {};

        vm.toggleFilterPanel = function() {
            vm.showFilterPanel = !vm.showFilterPanel;
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function initialFilterParams() {
            vm.page = 1;
            vm.itemsPerPage = "20";

            vm.orderNumber = null;

            vm.invoiceStatus = {
                DRAFT : false,
                INVOICED : false,
                APPROVED : false,
                CANCELLED: false
            }

            vm.amountRange = {
                UPPER: null,
                LOWER: null
            }

            vm.dateRange = {
                UPPER: null,
                LOWER: null
            }
        }

        initialFilterParams()

        function setInvoicesStatus(invoices) {
            var today = new Date(moment());
            invoices.forEach( function(invoice) {
                var date_to_compare = invoice.due_date ? invoice.due_date : invoice.invoice_date;
                if(today > new Date(date_to_compare)) {
                    invoice.past_due = true;
                }
                else {
                    invoice.past_due = false;
                }

                if(hasPendingAch(invoice)) invoice.payment_status = "<span class=\"invoice-status unpaid\">ACH Pending</span>"
                else if(!invoice.past_due && invoice.paid) invoice.payment_status = "<span class=\"invoice-status paid\">Paid</span>"
                else if(!invoice.past_due && !invoice.paid) invoice.payment_status = "<span class=\"invoice-status unpaid\">Unpaid</span>"
                else if(invoice.past_due) invoice.payment_status = "<span class=\"invoice-status past-due\">Past Due</span>"
            });

            return invoices;
        }

        function getUnpaidInvoices() {
            Invoice.getUnpaid({},
                function(data) {
                    vm.open_invoices.unpaid_invoices = data.invoices;
                    vm.open_invoices.total_balance = 0;
                    data.invoices.forEach( function(invoice) {
                        if(!hasPendingAch(invoice)) vm.open_invoices.total_balance += invoice.outstanding_amount;
                    });
                },
                function(e) {
                    console.log(e);
                }
            );
        }

        vm.payment.cardBrands = {
            VISA: 'fa-cc-visa',
            AMERICAN_EXPRESS: 'fa-cc-amex',
            MASTERCARD: 'fa-cc-mastercard',
            DISCOVER: 'fa-cc-discover',
        }

        vm.payment.squareCardToName = {
            VISA: 'Visa',
            AMERICAN_EXPRESS: 'American Express',
            MASTERCARD: 'Mastercard',
            DISCOVER: 'Discover',
        }

        function getCustomerSquareAccount() {
            CustomerSquare.retrieveCustomer({},
                function(data) {
                    vm.payment.cards = data.customer.cards;

                    if(vm.payment.cards) {
                        vm.payment.cards.forEach( function(card) {
                            card.is_primary = false;
                        });

                        vm.payment.cards.forEach( function(card) {
                            if(card.id == vm.payment.primary_card) {
                                card.is_primary = true;
                                vm.payment.primary_card_info = card;
                            }
                        });

                        sortCards();
                    }
                    else if(vm.autopay_checked){
                        vm.setAutopay(false);
                    }

                    if(!vm.payment.primary_card && vm.payment.cards && vm.payment.cards.length > 0) {
                        vm.payment.updatePrimaryCard(vm.payment.cards[0].id);
                    }
                },
                function(e) {
                    console.log(e);
                }
            );
        }

        vm.payment.setCard = function(card) {
            vm.payment.selectedCard = card;
        }

        function applyPaymentsInBatchSuccess(data) {
            sendPaymentReceipt(data.id)
            queryAll();
            SweetAlert.swal({
                title: 'Payment processed successfully.',
                text: 'Thank you for your payment. All billing contacts will be emailed a copy of the payment receipt shortly. The document will also be accessible and available for download via the \'Payment History\' subtab of the portal.',
                type: 'success',
                confirmButtonColor: '#4f8fcc',
                confirmButtonText: 'Ok'
            });

            vm.open_invoices.invoices_to_pay = [];
            vm.payment.pay_selected_total = 0;
            vm.emails = null;
            vm.payment.id = null;
            vm.payment_processing = false;
        }

        function applyPaymentsInBatchFail(e) {
            console.log(e)
            vm.payment.receipt_number = null;
            vm.payment_processing = false;
        }

        vm.createPayment = function(type) {
            vm.payment_processing = true;
            createInvoicesToPayArray();

            var notes = vm.customerData.name + ': ';

            var nonce = '';
            if(type == 'card') nonce = vm.payment.primary_card;
            else if(type == 'ach') nonce = vm.payment.ach_info.token;

            if(vm.open_invoices.invoices_to_pay.length > 0) {
                vm.open_invoices.invoices_to_pay.forEach( function(invoice) {
                    if(type == 'card'){
                        notes += invoice.customer_order_id + ' (Portal Payment)';
                    }
                    else if(type == 'ach'){
                        notes += invoice.customer_order_id + ' (Portal - ACH)';
                    }
                    // notes += invoice.custom_formated_id + ', ';
                    
                });
            }
            else {
                vm.open_invoices.unpaid_invoices.forEach( function(invoice) {
                    if(type == 'card'){
                        notes += invoice.customer_order_id + ' (Portal Payment)';
                    }
                    else if(type == 'ach'){
                        notes += invoice.customer_order_id + ' (Portal - ACH)';
                    }
                    // notes += invoice.custom_formated_id + ', ';
                });
            }

            SquareService.createPayment({
                'nonce': nonce,
                'amount': vm.payment.amount,
                'note' : notes.length > 149 ? notes.substring(0, 149) : notes.substring(0, notes.length - 1) // square caps note length to 150 char
            },
                function(data) {
                    if(data.errors) {
                        vm.payment.processSquareRequestErrors('There was an issue processing your payment.', data.errors);
                        vm.payment_processing = false;
                    }
                    else {
                        vm.payment.receipt_number = data.payment.receipt_number;
                        if(type == 'card') applyQBenchPayment(data.payment.id);
                        if(type == 'ach') updateInvoices(data.payment.id)
                    }
                    vm.payment.amount = null;
                    vm.open_invoices.invoices_to_pay_array = [];
                },
                function(e) {
                    vm.payment_processing = false;
                    vm.payment.amount = null;
                    vm.open_invoices.invoices_to_pay_array = [];
                    vm.invoices.forEach( function(invoice) {
                        invoice.checked = false;
                    });
                    console.log(e);
                }
            );
        }


        vm.initiatePayment = function(type) {
            var text = '';

            if(type == 'card') {
                if(!vm.payment.primary_card) {
                    SweetAlert.swal({
                        title: "No Card on File",
                        text: "Please add a primary card using the 'Payment Methods' tab before submitting a payment.",
                        type: "warning",
                        confirmButtonColor: "#27ae60",
                        confirmButtonText: "Ok",
                    });

                    return
                }

                text = `Your primary credit card on file ending in ${vm.payment.primary_card_info.last_4} will be automatically charged. Are you sure you want to submit a payment for $${vm.payment.amount.toFixed(2).toString()}?`;
            }
            else if(type == 'ach') {
                text = `Your bank account ending in ${vm.payment.ach_info.details.bankAccount.accountNumberSuffix} will be automatically charged. Are you sure you want to submit a payment for $${vm.payment.amount.toFixed(2).toString()}?`;
            }

            SweetAlert.swal({
                    title: "Confirm Payment",
                    text: text,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#27ae60",
                    confirmButtonText: "Submit Payment",
                    closeOnConfirm: true
                },
                function(confirm) {
                    if(confirm) {
                        vm.createPayment(type);
                    }
                }
            );
        }



        vm.deleteCard = function(card) {
            SweetAlert.swal({
                title: "Confirm Remove Card",
                text: "Are you sure you want to remove your " + vm.payment.squareCardToName[card.card_brand] + " card ending in " + card.last_4 + "?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f01c1c",
                confirmButtonText: "Remove Card",
                closeOnConfirm: false
            },
                function(confirm) {
                    if(confirm) {
                        CustomerSquare.deleteCard({
                            nonce: card.id,
                        },
                            function(data) {
                                if(data.errors) {
                                    vm.payment.processSquareRequestErrors('There was an issue removing your card.', data.errors);   
                                }
                                else {
                                    getCustomerSquareAccount();
                                    // resetSquarePaymentFormFields()
                                    vm.payment.selectedCard = null;

                                    SweetAlert.swal({
                                        title: 'Your card was removed successfully.',
                                        type: 'success',
                                        confirmButtonColor: '#4f8fcc',
                                        confirmButtonText: 'Ok'
                                    });
                                    if(card.is_primary) {
                                        vm.payment.updatePrimaryCard(null);
                                    }
                                }
                                sortCards();
                            },
                            function(e) {
                                console.log(e);
                            }
                        );
                    }
                }
            );
        }

        vm.saveCard = function(nonce, cardholder_name, show_popup) {
            if(vm.new_card_autopay_checkbox) {
                vm.setAutopay(true);
            }
            CustomerSquare.saveCard({
                nonce: nonce,
                cardholder_name: cardholder_name
            },
            function(data) {
                if(data.errors) {
                    vm.payment.processSquareRequestErrors('There was an issue saving your card.', data.errors);
                    resetPaymentForm();
                }
                else {
                    getCustomerSquareAccount();

                    if(vm.make_primary_payment_method) {
                        vm.payment.updatePrimaryCard(data.card.id);
                    }

                    if(show_popup) {
                        SweetAlert.swal({
                            title: 'Card saved successfully.',
                            type: 'success',
                            confirmButtonColor: '#4f8fcc',
                            confirmButtonText: 'Ok'
                        });
                    }
                    vm.hideAllTabs();
                    vm.switchTab('payment_methods');
                    vm.payment_methods.show_tab = false;
                    vm.show_all_tabs = true;
                    resetPaymentForm();
                }
            },
            function(e) {
                console.log(e);
            }
        );
        }

        vm.payment.addErrorMessage = function(error_message, new_error) {
            return error_message + new_error + '\n'
        }

        vm.payment.processSquareRequestErrors = function(title, errors) {
            var error_message = '';

            angular.forEach(errors, function(item, index) {
                if(item.detail) {
                    error_message = vm.payment.addErrorMessage(error_message, item.detail);
                }
                else if(item.message) {
                    error_message = vm.payment.addErrorMessage(error_message, item.message);
                }
            });

            SweetAlert.swal({
                title: title,
                text: error_message,
                type: 'warning',
                confirmButtonColor: '#4f8fcc',
                confirmButtonText: 'Try Again'
            });
        }

        function sendPaymentReceipt(id) {
            Payment.sendReceipt({id: id, emails: vm.emails},
                function(data) {
                    console.log('sent payment receipt')
                },
                function(e) {
                    console.log(e);
                }
            );
        }



        function resetSquarePaymentFormFields() {
            vm.payment.paymentForm.destroy();
            vm.payment.cardHolder = null;
        }

        vm.saveCardFromFrontEnd = function(event) {
            vm.onGetCardNonce(event);

            if(!vm.payment.cards && vm.new_card_autopay_checkbox) {
                vm.setAutopay(true);
                vm.new_card_autopay_checkbox = false;
            }
        }

        vm.onGetCardNonce = function(event) {
            event.preventDefault();
            vm.payment.paymentForm.requestCardNonce();
        }

        vm.payment.toggleMakePrimary = function() {
            vm.make_primary_payment_method = !vm.make_primary_payment_method;
        }


         if(!vm.isAuthenticated()){
              $state.go('login');
         }

        vm.dropDown = function(id, dropDownOption) {
            if(dropDownOption === 'Download') {
                vm.downloadAttachment(id);
            }
            else if(dropDownOption === 'Print')  {
                vm.printAttachment(id);
            }
        }

        vm.downloadSelectedInvoices = function(tabDict) {
            var invoice_list = []
            tabDict.invoices.forEach( function(invoice) {
                if(invoice.checked) {
                    invoice_list.push(invoice.id);
                }
            });

            InvoiceAttachDownload.merged({invoices: invoice_list, order: determineOrder(tabDict)},
                function(data, headers) {
                    var url = window.URL.createObjectURL(data.data);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = "Invoices.pdf";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                },
                function (e) {
                    AlertService.error("Could not download invoice");
                }
            );
        }

        vm.downloadAttachment = function (invoice) {
            if(invoice == ''){return}
            invoice.isDownloading = true;
            InvoiceAttachDownload.get({id: invoice.id}, onSuccess, onError);

            function onSuccess(data, headers) {
                var url = window.URL.createObjectURL(data.data);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = "Invoice-" + invoice.id + ".pdf";
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
            function onError(error) {
                AlertService.error("Could not download invoice");
            }
        }

        vm.printSelectedInvoices = function(tabDict) {
            var invoice_list = [];
            tabDict.invoices.forEach( function(invoice) {
                if(invoice.checked) {
                    invoice_list.push(invoice.id);
                }
            });

            InvoiceAttachDownload.merged({invoices: invoice_list, order: determineOrder(tabDict)},
                function (data, headers) {
                    if (data.data.byteLength == 0){
                        AlertService.error("Could not print invoice");
                        return;
                    }
                    var url = URL.createObjectURL(data.data);

                    setTimeout(function() {
                        window.open(url, '_blank');
                    }, 200);

                },
                function (error) {
                    AlertService.error("Could not print invoices");
                }
            );
        }

        vm.printAttachment = function (invoice) {
            if(invoice == ''){return}
            InvoiceAttachDownload.get({id: invoice.id}, onSuccess, onError);
            function onSuccess(data, headers) {
                if (data.data.byteLength == 0){
                    AlertService.error("Could not print invoice");
                    return;
                }
                var url = URL.createObjectURL(data.data);

                setTimeout(function() {
                    window.open(url, '_blank');
                }, 200);
            }
            function onError(error) {
                AlertService.error("Could not print invoice");
            }
        }

        function hasPendingAch(invoice) {
            if(invoice.ach_status && invoice.ach_status == 'PENDING') return true;
            return false;
        }

        function getCustomerData () {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.customerData = data;
                vm.payment.primary_card = data.square_primary_card_id;
                vm.payment.primary_card_info = null;
                vm.autopay_checked = data.autopay;
                vm.autopay_by_date = data.autopay_by_date;
                getCustomerSquareAccount();

                vm.account_balance = vm.customerData.account_balance;

                if(!vm.customerData.autopay_by_date) {
                    vm.autopay_by_date = "Invoice Date";
                    vm.changeAutopayDate();
                }
            }
            function onError(e) {
                console.log(e)
                $state.go('customerconfigure');
            }
        }

        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        angular.element(document).ready(function() {
            setTimeout(function () {

                $("#table tr:first > th").each(function(index, element) {
                    index++;
                    var addLi = "<li class='tbl-dot-" + index + "'><span class='tbl-dot'></span></li>";
                    $("ul.tbl-dots").append(addLi);
                });

                var num_col = $("#table tr:first > th").length;
                var constant = 2;
                var constant2 = constant + 1;

                var col_width = 100 / num_col;
                $("#table tr:first > th").css("width", col_width+"%");

                $("#col-prev").click(function() {
                    constant -= 1;
                    constant2 -= 1;
                    if (constant == 2 || constant2 == 2) {
                        $("#col-prev").attr('disabled', 'disabled');
                        $("#col-next").prop("disabled", false);
                    }
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    var x = constant2 + 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if(constant == 2 || constant2 >= 2){
                        $("#col-next").prop("disabled", false);
                    }
                });

                $("#col-next").click(function() {
                    constant += 1;
                    constant2 += 1;
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    $('.tbl-dot-' + constant2).removeClass('disabled').addClass('active');
                    var x = constant - 1;
                    var x2 = constant2 - 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if (constant >= num_col || constant2 >= num_col) {
                        $("#col-next").attr('disabled', 'disabled');
                        $("#col-prev").prop("disabled", false);
                    }
                    $("#col-prev").prop("disabled", false);
                });

            }, 1000);
        });
     }
})();
