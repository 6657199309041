(function() {
    'use strict';
    angular.module('anrescowebApp').factory('SampleService', SampleService);

    SampleService.$inject = ['$resource'];

    function SampleService ($resource) {
        var resourceUrl =  'api/public/samples/:hash';

        return $resource(resourceUrl, {hash: "@hash", token: "@token"}, {
            'get': {
                method: 'GET',
                url: 'api/public/samples/:hash/:token'
            },
            'getPictures': {
                method: 'GET',
                url: 'api/samples/:id/pictures',
                isArray: true
            },
            'getPublicPictures': {
                method: 'GET',
                url: 'api/public/samples/:hash/pictures',
                isArray: true
            },
            'getWorksheetParserMap': {
                method: 'GET',
                url: 'api/public/worksheet-parser-map',
                isArray: false,
            }
        });
    }
})();
