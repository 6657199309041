(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('SyncQbLogDeleteController',SyncQbLogDeleteController);

    SyncQbLogDeleteController.$inject = ['$uibModalInstance', 'entity', 'SyncQbLog'];

    function SyncQbLogDeleteController($uibModalInstance, entity, SyncQbLog) {
        var vm = this;

        vm.syncQbLog = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SyncQbLog.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
