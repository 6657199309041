(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoicelog', {
            parent: 'entity',
            url: '/invoicelog?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Billing'
            },
            views: {
               'content@': {
                    templateUrl: 'app/pages/invoice-log/invoiceslog.html',
                    controller: 'InvoiceLogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('invoicelog.view', {
                    parent: 'invoicelog',
                    url: '/{id}/view',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/pages/invoice-log/invoicelog-dialog-old.html',
                            controller: 'InvoiceLogDialogController',
                            controllerAs: 'vm',
                            size: 'lg',
                            resolve: {
                                entity: ['Invoice', function(Invoice) {
                                    return Invoice.get({id : $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function() {
                            $state.go('invoicelog', null, { reload: 'invoicelog' });
                        }, function() {
                            $state.go('^');
                        });
                    }]
                });

    }

})();
