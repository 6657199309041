(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Panel', Panel)
    	.factory('Assay', Assay);

    Panel.$inject = ['$resource'];
    Assay.$inject = ['$resource'];

    function Panel ($resource) {
        var resourceUrl =  'api/panels/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function Assay ($resource) {
    	var resourceUrl = '/api/assays/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

})();
