(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Config', Config);

    Config.$inject = ['$resource'];

    function Config ($resource) {
        var resourceUrl =  'api/config';

        return $resource(resourceUrl, {}, {
            'holidays': {
            	method: 'GET',
                isArray: false,
                url: 'api/config/holidays'
            },
            'generic': {
            	method: 'GET',
                isArray: false,
                url: 'api/config/generic',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

})();
