(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('NotificationService', Notifications);

    Notifications.$inject = ['$resource'];
    function Notifications($resource) {
        var resourceUrl =  'api/notifications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getAllNotification': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getTopNotifications': {
                method: 'GET',
                url: 'api/notifications/top',
                isArray: true
            },
            'getSubscriptionSettings': {
                method: 'GET',
                url: 'api/notifications/subscriptions'
            },
            'saveSubscriptionSettings': {
                method: 'POST',
                url: 'api/notifications/subscribe'
            },
            'getAllNotification': {
                method: 'GET',
                url: '/api/notifications'
            },

            'markAllAsRead': {
                method: 'PUT',
                url: 'api/notifications/mark-as-read/all',
                isArray: true
            },
            'markAsRead': {
                method: 'PUT',
                url: 'api/notifications/mark-as-read/:id'


            },


        });
    }
})();
