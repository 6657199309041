(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'/*,'$scope'*/];

    function stateConfig($stateProvider/*,$scope*/) {
        $stateProvider
        .state('samplesubmit', {
            parent: 'entity',
            url: '/sample-submit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Sample-Submit'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/sample-submit/samplesubmit.html',
                    controller: 'SampleSubmitController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

            }
        })
        .state('samplesubmit.notify', {
            parent: 'entity',
            url: '/sample-submit/notify',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Sample-Submit'
            },
            params: {
                id: 0
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/sample-submit/samplesubmit.notify.html',
                    controller: 'SampleSubmitController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

            }
        })
        .state('samplesubmit.newcomp', {
            parent: 'samplesubmit',
            url: '/sample-submit-comp/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams','$state', '$uibModal', function($stateParams,$state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/sample-submit/dialog.comp.samplesubmit.html',
                    controller: 'DialogCompSampleSubmitController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {

                        entity: function () {
                            return {
                                name: null,
                                lotBatchNumber: null,
                                productCategory: null,
                                productType: null,
                                strainName: null,
                                strainType: null,
                                id: null,
                                batchType: 'PRODUCT_BATCH',
                                nrUnits: null,
                                sampleWeight: null,
                                sCode: null
                            };
                        }
                    }
                }).result.then(function() {

                    $state.go('samplesubmit');
                }, function() {
                    $state.go('samplesubmit');
                });
            }]
        })
        .state('samplesubmit.editcomp', {
            parent: 'samplesubmit',
            url: '/sample-submit-comp/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/sample-submit/dialog.comp.samplesubmit.html',
                    controller: 'DialogCompSampleSubmitController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                lotBatchNumber: null,
                                productCategory: null,
                                productType: null,
                                strainName: null,
                                strainType: null,
                                otherAnalysis: null,
                                reportedUnits: null,
                                id: $stateParams.id,
                                batchType: 'PRODUCT_BATCH',
                                nrUnits: null,
                                sampleWeight: null,
                                sCode: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('samplesubmit');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('samplesubmit.newrnd', {
            parent: 'samplesubmit',
            url: '/sample-submit-rnd/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams','$state', '$uibModal', function($stateParams,$state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/sample-submit/dialog.rnd.samplesubmit.html',
                    controller: 'DialogRndSampleSubmitController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {

                        entity: function () {
                            return {
                                name: null,
                                lotBatchNumber: null,
                                productCategory: null,
                                productType: null,
                                strainName: null,
                                strainType: null,
                                otherAnalysis: null,
                                reportedUnits: null,
                                id: null,
                                productBatchSize:null,
                                harvestBatchSize:null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('samplesubmit');
                }, function() {
                    $state.go('samplesubmit');
                });
            }]
        })
        .state('samplesubmit.editrnd', {
            parent: 'samplesubmit',
            url: '/sample-submit-rnd/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/sample-submit/dialog.rnd.samplesubmit.html',
                    controller: 'DialogRndSampleSubmitController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                lotBatchNumber: null,
                                productCategory: null,
                                productType: null,
                                strainName: null,
                                strainType: null,
                                otherAnalysis: null,
                                reportedUnits: null,
                                id: $stateParams.id,

                                tests: [false,false,false,false,false,false,false,false,false,false,false,false]
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('samplesubmit');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        ;
    }

})();
