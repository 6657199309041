
//https://stackoverflow.com/questions/21628378/angularjs-display-blob-pdf-in-an-angular-app
angular.module('anrescowebApp')

    .factory('InvoiceAttachDownloadBytes',['$http', function ($http) {
             return {
                 downloadPdf: function (id) {
                 return $http.get('api/invoices/documents/pdf/' + id, { responseType: 'arraybuffer' }).then(function (response) {
                     return response;
                 });
             }
         };
     }]);
