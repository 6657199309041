(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Quotation', Quotation);

    Quotation.$inject = ['$resource'];

    function Quotation ($resource) {
        var resourceUrl =  '/api/quotation/:id';

        return $resource(resourceUrl, {id: "@id"}, {
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if(data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update':{
            	method: 'PATCH',
                transformResponse: function(data) {
                    if(data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
