(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('faq', {
            parent: 'entity',
            url: '/faq',
            data: {
              authorities: ['ROLE_USER'],
              pageTitle: 'FAQ'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/faq/faq-page.html',
                    controller: 'FAQPageController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
