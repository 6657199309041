(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('SampleTest', SampleTest);

    SampleTest.$inject = ['$resource', 'DateUtils'];

    function SampleTest ($resource, DateUtils) {
        var resourceUrl =  'api/sample-tests/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.estimetedStartDate = DateUtils.convertLocalDateFromServer(data.estimetedStartDate);
                        data.estimetedCompleteDate = DateUtils.convertLocalDateFromServer(data.estimetedCompleteDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.estimetedStartDate = DateUtils.convertLocalDateToServer(copy.estimetedStartDate);
                    copy.estimetedCompleteDate = DateUtils.convertLocalDateToServer(copy.estimetedCompleteDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.estimetedStartDate = DateUtils.convertLocalDateToServer(copy.estimetedStartDate);
                    copy.estimetedCompleteDate = DateUtils.convertLocalDateToServer(copy.estimetedCompleteDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
