(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Payment', Payment)
        .factory('PaymentById', PaymentById);

    Payment.$inject = ['$resource'];
    PaymentById.$inject = ['$resource'];


    function Payment ($resource) {
        var resourceUrl =  'api/payment';

        return $resource(resourceUrl, {}, {
            'getAllPayments': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'createPayment': {
                method: 'POST',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'createAchPayment': {
                method: 'POST',
                url: 'api/payment/create-ach',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'filter': {
                method: 'GET',
                url: 'api/payment/filter',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'sendReceipt': {
                method: 'POST',
                url: 'api/payment/receipt/send',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }

    function PaymentById ($resource) {
        var resourceUrl =  'api/payment/:paymentId';

        return $resource(resourceUrl, {paymentId: "@paymentId"}, {
            'getPayment': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
