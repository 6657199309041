(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('BackdoorController', BackdoorController);

    BackdoorController.$inject = ['$rootScope', '$state', '$timeout', 'Auth'];

    function BackdoorController ($rootScope, $state, $timeout, Auth) {
        var vm = this;

        angular.element(document).ready(function() {
            angular.element('#wrap').removeClass("wrap");
        });

        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.backdoor_username = null;
        vm.rememberMe = true;
        vm.showOldUsers = false;
        vm.loginAsSelected = false;
        vm.signOutCompletly = signOutCompletly
        vm.oldUser = null;

        vm.anotherUser = anotherUser;
        vm.step = 'oldUsers';

        function signOutCompletly(){
            vm.step = 'login';
            vm.username = "";
            localStorage.removeItem('oldUserLogin')
            localStorage.removeItem('oldUserFirstName')
        }

        function anotherUser(){
            vm.step = 'login';
            vm.username = "";
        }

        function oldUsersCheck (){
            vm.step = 'login';
            try {
                if(localStorage.getItem('oldUserLogin')){
                    vm.oldUserLogin = JSON.parse(localStorage.getItem('oldUserLogin'))
                    vm.oldUserFirstName = JSON.parse(localStorage.getItem('oldUserFirstName'))
                    vm.step = 'loginAs';
                    vm.username = vm.oldUserLogin;
                    vm.password = '';


                } else {
                    vm.step = 'login';
                }
            }
           catch(err) {}

        }

        oldUsersCheck();

        $timeout(function (){angular.element('#username').focus();});


        function login (event) {
            event.preventDefault();
            Auth.backdoor({
                username: vm.username,
                password: vm.password,
                backdoor_username: vm.backdoor_username,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                //$uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');
                $state.go('home',{}, {reload:true});
            }).catch(function () {
                vm.authenticationError = true;
            });
        }
    }
})();
