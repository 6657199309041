(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('FaqCategoryDialogController', FaqCategoryDialogController);

    FaqCategoryDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'FaqManageService'];

    function FaqCategoryDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, FaqManageService) {
        var vm = this;

        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "FAQ Manage";
        }

        vm.category = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addNewFaq = addNewFaq;
        vm.newFaq = {
            question:'',
            answer:'',
        }


        $rootScope.$on('closeModal', function (event, data) {
            clear();
        });


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function addNewFaq () {
            console.log(vm.newFaq)
            vm.category.faq.push(angular.copy(vm.newFaq));
            vm.newFaq.question = '';
            vm.newFaq.answer = '';
        }

        function save () {
            vm.isSaving = true;
            if (vm.category.id !== null) {
                FaqManageService.update(vm.category, onSaveSuccess, onSaveError);
            } else {
                FaqManageService.save(vm.category, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:categoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
