(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('FaqAddNewDialogController', FaqAddNewDialogController);

    FaqAddNewDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'FaqManageService','FAQService'];

    function FaqAddNewDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, FaqManageService, FAQService) {
        var vm = this;

        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "FAQ Manage";
        }


        $scope.faq = {
            answer: "Answer.",
            faqCategory: {
                id: null
            },
            question: "Question ?"
        }


        $scope.setContent = function() {
            $scope.tinymceModel = 'Time: ' + (new Date());
        };

        $scope.tinymceOptions = {
            plugins: [
                'advlist autolink lists link image charmap print preview anchor textcolor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table contextmenu paste code wordcount'
            ],
            toolbar: ' formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ',
            inline: false,
            menubar: false,
        };

        vm.category = entity;
        vm.clear = clear;
        vm.save = save;


        $scope.getAllFaqCategoriesWithQuestions = function () {
            FAQService.getAllFaqCategories().then(function (res) {
                $scope.categories = res.data;
            }).catch(function (err) {
                console.log(err);
            })
        }

        $scope.getAllFaqCategoriesWithQuestions()


        $rootScope.$on('closeModal', function (event, data) {
            clear();
        });


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            console.log($scope.faq)
            vm.isSaving = true;
            if (vm.category.id !== null) {
                FAQService.updateFaq($scope.faq).then(function (response) {
                    onSaveSuccess(response);
                }).catch(function(error){
                } )
            } else {
                FAQService.createNewFaq($scope.faq).then(function (response) {
                    onSaveSuccess(response);
                }).catch(function(error){
                    onSaveError();
                } );
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:faqUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
