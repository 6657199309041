(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('SyncQbLogDetailController', SyncQbLogDetailController);

    SyncQbLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SyncQbLog'];

    function SyncQbLogDetailController($scope, $rootScope, $stateParams, previousState, entity, SyncQbLog) {
        var vm = this;

        vm.syncQbLog = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('anrescowebApp:syncQbLogUpdate', function(event, result) {
            vm.syncQbLog = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
