(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Contact', Contact);

    Contact.$inject = ['$resource'];

    function Contact ($resource) {
        var resourceUrl =  'api/contacts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete': { method: 'DELETE' },
            'update': { method:'PUT' },
            'getByUserId': {
            	method: 'GET',
            	url: 'api/contact/user/:id'
            },
            'updateContactAndUser': {
            	method: 'PUT',
            	url: 'api/contact/user'
            },
            'getByResetKey': {
                method: 'GET',
            	url: 'api/contact/user/reset/:reset_key'
            }
        });
    }
})();
