(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('FAQPageController', FAQPageController);

    FAQPageController.$inject = ['$scope', '$rootScope','$http','FAQService' , 'Customer', 'Principal', 'LoginService', '$state', 'Auth', 'ProfileService', 'CustomerByUser', 'CustomerOrder', 'Invoice'];

    function FAQPageController($scope, $rootScope,$http, FAQService, Customer, Principal, LoginService, $state, Auth, ProfileService, CustomerByUser, CustomerOrder, Invoice) {

        $scope.getFaqResource = function(){
            $scope.getAllFaqCategoriesWithQuestions();
            $scope.getAllFaqQuestions();
        }

        $scope.questions = [];
        $scope.categoriesWithQuestions = [];
        $scope.questionByCategories = [];
        $scope.selectedCategory = 'all';
        $scope.selectedCategoryIndex = null;

        function setPageTitle() {
            $rootScope.pageTitle = "FAQ";
        }

        setPageTitle();


        $scope.allCategory = function(){
            $scope.selectedCategory = 'all';
        }

        $scope.selectedOneCategiry = function(index){
            $scope.selectedCategoryIndex = index;
            $scope.questionByCategories = $scope.categoriesWithQuestions[$scope.selectedCategoryIndex].faq;
            $scope.selectedCategory = 'category';
        }

        $scope.getAllFaqQuestions = function(){
            FAQService.getAllFaq().then(function (res) {
                $scope.questions = res.data;
            }).catch(function (err) {
                console.log(err);
            })
        }

        $scope.getAllFaqCategoriesWithQuestions = function () {
            FAQService.getAllFaqCategories().then(function (res) {
                $scope.categoriesWithQuestions = res.data;
            }).catch(function (err) {
                console.log(err);
            })
        }

        angular.element(document).ready(function() {
            $(".tab_content").hide();
            $(".tab_content:first").show();
        });
    }
})();
