(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('ProductCategory', ProductCategory);

    ProductCategory.$inject = ['$resource'];

    function ProductCategory ($resource) {
        var resourceUrl =  'api/product-categories/:id';

        return $resource(resourceUrl, {}, {
            'query': {
            	method: 'GET',
            	isArray: true,
            	transformResponse: function(data) {
            		// Make sure "Other" is last in the list
            		var dataJson = JSON.parse(data);
            		var otherCatIdx = null;

            		for (var i = 0; i < dataJson.length; i++){
            			if (dataJson[i].name === "Other") otherCatIdx = i;
            		}
            		
            		dataJson.push(dataJson.splice(otherCatIdx, 1)[0]);
            		return dataJson;
            	}
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        console.log('transforming product category: ' + data)
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
