(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('signup', {
            parent: 'entity',
            url: '/signup/{quotationId}',
            data: {
              authorities: [],
              pageTitle: 'Signup'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/signup/signup.html',
                    controller: 'SignupController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('signup.success', {
            parent: 'entity',
            url: '/signup-success',
            data: {
                authorities: [],
                pageTitle: 'Signup Success'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/signup/signup.success.html',
                    controller: 'SignupController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

            }
        });
    }
})();
