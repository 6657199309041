(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('OrdersDialogController', OrdersDialogController);

    OrdersDialogController.$inject = ['$timeout', 'CustomerOrderPdfService', '$scope', '$rootScope', '$stateParams', '$uibModalInstance',
        'entity', 'CustomerOrder', 'Sample', 'Customer','CustomerByUser','SampleAttach','SampleAttachDownload','OrderPicture', 'OrderAttach', 'OrderAttachDownload'];

    function OrdersDialogController ($timeout, CustomerOrderPdfService, $scope, $rootScope, $stateParams, $uibModalInstance, entity,
                                     CustomerOrder, Sample, Customer,CustomerByUser,SampleAttach,SampleAttachDownload,OrderPicture, OrderAttach, OrderAttachDownload) {

        var vm = this;
        vm.idOfSelectedSample = null;
        vm.numOfSelectedSamplePictures = 0;

        vm.getAttachmentsLoader = false;
        vm.customerOrder = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $rootScope.getOrderAttachments = function (order,id) {
        	
	        vm.getAttachmentsLoader = true;
	        vm.idOfSelectedOrder = id;

	        if(order.attachments != null){
		        vm.getAttachmentsLoader = false;
		        return;
	        }
	        
	        OrderAttach.query({id: id}, onSuccess, onError);
	        function onSuccess(data, headers) {
		        vm.getAttachmentsLoader = false;
		        order.attachments = data;
	        }
	        
	        function onError(error) {
	        	
	        	AlertService.error(error.data.message);
	        }
        }

        $rootScope.downloadOrderAttachment = function (id_hash,order) {
		    OrderAttachDownload.get({id: id_hash}, onSuccess, onError);
		
		    function onSuccess(data, headers) {
	            var a = document.createElement("a");
	            document.body.appendChild(a);
	            a.style = "display: none";

	            var fileURL = window.URL.createObjectURL(data.data);
	            a.href = fileURL;
	            a.download = headers('content-disposition').substring(21);
	            a.click();
		        order.select = "";
		    }
		    
		    function onError(error) {
		
		    }
		}
        
        $rootScope.$on('closeModal', function (event, data) {
            clear();
        });

        vm.progress = 1;

        vm.downloadOrderPdf = downloadOrderPdf;

        if(vm.customerOrder.state == "CREATED"){
            vm.progress = 1
        }
        else if(vm.customerOrder.state == "SAMPLES_RECEIVED"){
            vm.progress = 2;
        }
        else if(vm.customerOrder.state == "PRELIMINARY_RESULTS"){
            vm.progress = 3
        }
        else if(vm.customerOrder.state == "COMPLETED"){
            vm.progress = 4
        }
        else if(vm.customerOrder.state == "INVOICED"){
            vm.progress = 5
        }


        //show loading image first, when order is loaded show cached images,finally show all images from server


        if (vm.customerOrder.cachedPictures != null && vm.customerOrder.cachedPictures.length>0) {
            setPictures(vm.customerOrder.cachedPictures )
        }
        else {
            var defaultPicture = new Array();
            defaultPicture.push({id: 0, name: "Empty Sample", url: "content/images/loading.gif"});
            setPictures(defaultPicture )
        }


        getPictures ();

        function getPictures () {
            var id = entity.id;
            OrderPicture.query({id: id}, onSuccess, onError);

            function onSuccess(data, headers) {
                  setPictures(data);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function setPictures(pictures) {

            vm.pictures = pictures;
            vm.numOfSelectedSamplePictures = vm.pictures.length;

            if( vm.numOfSelectedSamplePictures > 1){
                vm.show = 1;
                console.log(vm.show);
            }
            else {

            }
            if( vm.numOfSelectedSamplePictures < 1){
                for(var i = 0; i <(4 - vm.numOfSelectedSamplePictures ); i++ ){
                    vm.pictures.push({id: i, name: "Empty Sample", url: "content/images/no_image_available-small.png"});
                }
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customerOrder.id !== null) {
                CustomerOrder.update(vm.customerOrder, onSaveSuccess, onSaveError);
            } else {
                CustomerOrder.save(vm.customerOrder, onSaveSuccess, onSaveError);
            }

        }

        function closeModal() {
          $uibModalInstance.close();
        }


        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:customerOrderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateReceived = false;
        vm.datePickerOpenStatus.checkoutDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }


        $scope.getAttachments = function (sample,id) {
            vm.getAttachmentsLoader = true;
            vm.idOfSelectedSample = id;

            if(sample.attachments != null){
                vm.getAttachmentsLoader = false;
                return;
            }

            SampleAttach.query({id: id}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.getAttachmentsLoader = false;
                sample.attachments = data;

            }
            function onError(error) {
                console.log(error.status === 401);
                if(error.status === 401){
                    clear();
                }

                AlertService.error(error.data.message);
            }
        }

        $scope.downloadAttachment = function (id_hash,sample) {
            if(id_hash == ''){return}
            SampleAttachDownload.get({id:id_hash}, onSuccess, onError);
            function onSuccess(data, headers) {
	            var a = document.createElement("a");
	            document.body.appendChild(a);
	            a.style = "display: none";

	            var fileURL = window.URL.createObjectURL(data.data);
	            a.href = fileURL;
	            a.download = headers('content-disposition').substring(21);
	            a.click();
		        sample.select = "";
            }
            function onError(error) {
                if(error.status === 401){
                    clear();
                }

            }
        }

        function downloadOrderPdf () {
            var id = vm.customerOrder.id;

            if (vm.customerOrder.orderType === 'COMPLIANCE') {
             var fileName = id + " Compliance Submission Form.pdf";
            } else {
             var fileName = id + " R&D Submission Form.pdf";
            }

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            CustomerOrderPdfService.downloadPdf(id).then(function (result) {
                var file = new Blob([result.data], {type: 'application/pdf'});
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName;
                a.click();
            }).catch(function(error){
                if(error.status === 401){
                    clear();
                }
            });
        }

        angular.element(document).ready(function() {
            $(".status-wizard-step a").each(function(index, element) {
                index++;
                $(this).attr("id", "open-tooltip-"+index);
                $("#open-tooltip-"+index).click(function(){
                    $(".status-wizard-step a").not(this).removeClass('open');
                    $(this).toggleClass("open");
                    return this;
                });
            });

            $(".status-wizard-step.active > .status-wizard-dot span" ).remove();
        });

    }
})();
