(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('PublicView', PublicView);

    PublicView.$inject = ['$resource'];

    function PublicView ($resource) {

        return $resource('api/publicview/:id', {id: '@id', public: '@public', emails: '@emails', samples: '@samples', sample_ids: '@sample_ids', prev_sample_id: '@prev_sample_id', next_sample_id: '@next_sample_id', remove_samples: '@remove_samples', criteria: '@criteria', customer_hash: '@customer_hash'}, {
            query: { method: 'GET', isArray: true },
            get: {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            public: {
                url: 'api/publicview/',
                method: 'PUT'
            },
            saveSamples: {
            	url: 'api/publicview/save_samples',
            	method: 'PUT',
            	data: {samples: '@samples', remove_samples: '@remove_samples'}, 
            	isArray: true 
            },
            addSamples: {
            	url: 'api/publicview/add_samples',
            	method: 'PUT',
            	data: {sample_ids: '@sample_ids'}, 
            },
            setCriteria: {
            	url: 'api/publicview/set_criteria',
            	method: 'PUT',
            	data: {criteria: '@criteria'}, 
            },
            getSampleBoundaries: {
            	url: 'api/publicview/sample_boundaries',
            	method: 'GET',
            	data: {prev_sample_id: '@prev_sample_id', next_sample_id: '@next_sample_id'}, 
            },
            sendEmail: {
            	url: 'api/publicview/email',
            	method: 'PUT',
            	params: {emails: '@emails', message: '@message', public_link: '@public_link', subject: '@subject', customer_hash: '@customer_hash'}
            }
        });

    }

})();
