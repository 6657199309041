(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            }
        }).state('home.dayModal', {
            url: 'home/calendar/day',
            params: {
            	date: null,
            	samples: null,
            	queryParams: null
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$uibModal', function($uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/calendar/calendar-day-modal.html',
                    controller: 'CalendarDayModalController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                    	PreviousState: function() {
                    		return {
                    			name: 'home'
                    		}
                    	}
                    }
                });
            }]
        });
    }
})();
