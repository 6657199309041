(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('CustomerAccount', CustomerAccount)
        .factory('Customer', Customer)
        .factory('CustomerByOrder', CustomerByOrder)
        .factory('CustomerMap', CustomerMap)
        .factory('CustomerSquare', CustomerSquare)
        .factory('CustomerById', CustomerById);

    CustomerAccount.$inject = ['$resource'];
    Customer.$inject = ['$resource'];
    CustomerByOrder.$inject = ['$resource'];
    CustomerMap.$inject = ['$resource'];
    CustomerSquare.$inject = ['$resource'];
    CustomerById.$inject = ['$resource'];

    function CustomerAccount ($resource) {
        var resourceUrl =  'api/customer';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'onboard': {
                method: 'POST',
                url: 'api/customer/onboard',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function Customer ($resource) {
        var resourceUrl =  'api/customers/:id/:orderId';

        return $resource(resourceUrl, {orderId: "@orderId"}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': {
                 method: 'POST',
                 transformRequest: function (data) {
                     var copy = angular.copy(data);
                     copy.dateReceived = ZonedDateTime.parse('2016-03-18T12:38:23.561+01:00');
                     copy.checkoutDate = DateUtils.convertLocalDateToServer(copy.checkoutDate);
                     return angular.toJson(copy);
                 }
             }
        });
    }

    function CustomerByOrder ($resource) {
        var resourceUrl =  'api/public/customers/byorder/:orderId';

        return $resource(resourceUrl, {orderId: "@orderId"}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function CustomerMap($resource) {
    	var resourceUrl = 'api/public/customers/:id/map';

    	return $resource(resourceUrl, {id: "@id"}, {
    		'get': {
    			method: 'GET',
    			responseType: 'arraybuffer'
    		}
    	});
    }

    function CustomerById($resource) {
    	var resourceUrl = 'api/customers/internal/:id';

    	return $resource(resourceUrl, {id: "@id"}, {
            'getInternal': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
    	});
    }

    function CustomerSquare($resource) {
    	var resourceUrl = 'api/customer/square';

    	return $resource(resourceUrl, {}, {
            'retrieveCustomer': {
                method: 'GET',
                url: 'api/customer/square/account',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'saveCard': {
                method: 'POST',
                url: 'api/customer/square/card',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'deleteCard': {
                method: 'DELETE',
                url: 'api/customer/square/card',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'updatePrimaryCard': {
                method: 'PUT',
                url: 'api/customer/square/card/primary',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'toggleAutopay': {
                method: 'POST',
                url: 'api/customer/autopay/toggle',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }

    	});
    }
})();
