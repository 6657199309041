(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Invoice', Invoice);

    Invoice.$inject = ['$resource', 'DateUtils'];

    function Invoice ($resource, DateUtils) {
        var resourceUrl =  'api/invoices/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    copy.paymentDate = DateUtils.convertLocalDateToServer(copy.paymentDate);
                    return angular.toJson(copy);
                }
            },
            'updateInvoices': {
                method: 'POST',
                url: 'api/invoices',
                transformRequest: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUnpaid': {
                method: 'GET',
                url: 'api/invoices/unpaid-invoices',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'filter': {
                method: 'GET',
                url: 'api/invoices/filter',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'openBalance': {
                method: 'GET',
                url: 'api/invoices/open-balance/:customer_id',
                responseType: 'blob',
                transformResponse: function (data) {
                    return { data: data };
                }
            },
        });
    }
})();
