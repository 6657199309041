(function() {
    'use strict';

    angular.module('anrescowebApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider.state('customerPublic', {
            parent: 'app',
            url: '/public/customer/{dbaName}',
            data: {
                authorities: [],
                pageTitle: 'Customer Public'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/customer-public/customer-public.html',
                    controller: 'CustomerPublicPageController',
                    controllerAs: 'vm'
                }
            }
            ,
            resolve: {
                data: function ($state, $stateParams, CustomerPublicService) {
                    var dbaName = $stateParams.dbaName;
                    return CustomerPublicService.get({dbaName: dbaName})
                        .$promise.then(
                            function(data){
                                return data
                            },
                            function(error){
                                /*$state.go('publicCustomerNotFound', {}, {location: 'replace'});*/ });
                }
            }

        });

        $stateProvider.state('publicCustomerNotFound', {
            parent: 'app',
            url: '/customer-public/customer-not-found',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/customer-public/not-found.html'
                }
            }
        });

    }
})();
