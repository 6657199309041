(function() {
//not used!!!
    angular
        .module('anrescowebApp')
        .factory('focus', ['$rootScope','$timeout', function ($rootScope, $timeout) {
          return function(name) {
            $timeout(function (){
              $rootScope.$broadcast('focusOn', name);
            });
  }
}]);
})();
