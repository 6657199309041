(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('ZipList', ZipList);

    ZipList.$inject = ['$resource'];
    function ZipList ($resource) {
        var resourceUrl =  'api/zip-code/search/:searchString';

        return $resource(resourceUrl, {}, {

            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                isArray: true
            }
        });
    }

})();
