(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customerconfigure', {
            parent: 'entity',
            url: '/customer-configure',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Customer-Configure'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/customer-configure/customer-configure.html',
                    controller: 'CustomerConfigureController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        ;
    }

})();
