//THIS FUNCTIONALITY IS NOT USED ON WEB PORTAL
(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('CustomerConfigureController', CustomerConfigureController);

    CustomerConfigureController.$inject = ['$scope','$state','Customer','Auth', 'Principal', 'ProfileService', 'LoginService','CustomerByUser','Contact'];

    function CustomerConfigureController($scope,$state,Customer,Auth, Principal, ProfileService, LoginService, CustomerByUser,Contact) {

        var vm = this;

        setUpContentWidth();
        vm.showsidebar = false;
        $scope.$root.contacts = [];
        vm.contacts = $scope.$root.contacts;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;

        if(!vm.isAuthenticated()){
             $state.go('login');
        }

        vm.customerData;

        vm.submitCustomer = submitCustomer;


        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function submitCustomer(){
           vm.customerData.address = vm.customerData.address_street + '@' + vm.customerData.address_city + '@' + vm.customerData.address_state + '@' + vm.customerData.address_country + '@' + vm.customerData.address_zip;
           Customer.save(vm.customerData, onSaveSuccess, onSaveError);
       }

       function onSaveSuccess (result) {
           $state.go('home');
       }

       function onSaveError () {

       }


    }
})();
