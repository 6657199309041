(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('AddressList', AddressList);

    AddressList.$inject = ['$resource'];
    function AddressList ($resource) {
        var resourceUrl =  'api/locations/:id';

        return $resource(resourceUrl, {}, {

            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                isArray: true
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                  var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'remove': { method:'DELETE' }
        });
    }

})();
