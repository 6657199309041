(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('DialogRndSampleSubmitController', DialogRndSampleSubmitController);

    DialogRndSampleSubmitController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Sample',
        'SampleTest', 'ProductType', 'CustomerOrder','ProductCategory','ProductTypeByCategory','StrainNameSearch', 'Assay'];

    function DialogRndSampleSubmitController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, Sample, SampleTest,
                                           ProductType, CustomerOrder,ProductCategory,ProductTypeByCategory,StrainNameSearch, Assay) {

        var vm = this;
        var update = false;
        
        $rootScope.$on('closeModal', function (event, data) {
            clear();
        });

         vm.strainTypes  = [{
             'id': 1,
             'name': 'Sativa'
           }, {
              'id': 2,
              'name': 'Indica'
           }, {
             'id': 3,
             'name': 'Hybrid'
           },
            {
             'id': 4,
             'name': 'CBD'
            }];

          vm.inhalable_edible_array  = [{
             'id': 1,
             'name': 'Inhalable',
             'value':'INHALABLE'
             },
             {
             'id': 2,
             'name': 'Non-Inhalable',
             'value':'NONINHALABLE'
             }];

        vm.sample = entity;

        resetTests();

        vm.productCategories = ProductCategory.query();

        vm.populateTypes = populateTypes;

        vm.loadStrains = loadStrains;

        vm.setStrainName = setStrainName;

        vm.samples = $scope.$root.samplesRnD;

        vm.assays = {}
        vm.selectedAssays = {}
        vm.assayDescriptions = {}

        vm.takenAssays = [
        	'VITAMIN_E_ACETATE',
        	'POTENCY_LCDAD',
        	'POTENCY_LCMSMS',
        	'FOREIGN_MATERIAL',
        	'HEAVY_METALS',
        	'HOMOGENEITY',
        	'HOMOGENEITY_5',
        	'MICRO_INHALABLES',
        	'MICRO_NON_INHALABLES',
        	'MICRO_QA_SCREEN',
        	'MICRO_PLUS',
        	'MOISTURE',
        	'MYCOTOXINS',
        	'PESTICIDES_PHASE_II',
        	'PESTICIDES_PLUS',
        	'PESTICIDES_MYCOTOXINS',
        	'RESIDUAL_SOLVENT_PHASE_II',
        	'SULFUR',
        	'TERPENE_PROFILE',
        	'WATER_ACTIVITY',
        	'ALKALOID',
        ];
        
        vm.dynamicAssays = [];
        vm.assays_col_1 = [];
        vm.assays_col_2 = [];
        vm.assays_col_3 = [];
        
        Assay.query({}, function(data){
        	if (data) {
        		for (var i = 0; i < data.length; i++) {
        			vm.assays[data[i].portalIdentifier] = data[i];
        			if (data[i].portalIdentifier) {
        				vm.assayDescriptions[data[i].portalIdentifier] = data[i].analysis_description;
        				
        				if (vm.takenAssays.indexOf(data[i].portalIdentifier) == -1 && !data[i].do_not_show_on_portal) {
        					vm.dynamicAssays.push(data[i])
        				}
        			}
        		}
        		var dynamicAssaySize = vm.dynamicAssays.length/3;
        		vm.assays_col_1 = vm.dynamicAssays.slice(0, dynamicAssaySize);
                vm.assays_col_2 = vm.dynamicAssays.slice(dynamicAssaySize, dynamicAssaySize*2);
                vm.assays_col_3 = vm.dynamicAssays.slice(dynamicAssaySize*2, dynamicAssaySize*3);

        	}
        });


        vm.hasSelectedAssay = function(o) {
            if (vm.sample.otherAnalysis) {
                if (vm.sample.otherAnalysis.length >= 2) {
                    o['CONSULTING'] = true;
                    return true;
                }
                else {
                    o['CONSULTING'] = false;
                }

            }
            else {
                o['CONSULTING'] = false;
            }

        	for (var prop in o) {
        		if (o.hasOwnProperty(prop) && o[prop] === true) {
        			return true;
        		}
            }
        	return false;
        }

        if(vm.sample.id != null){

            var sampleOld = vm.samples[vm.sample.id];
            
            vm.sample.name = sampleOld.name;
            vm.sample.lotBatchNumber = sampleOld.lotBatchNumber;
            vm.sample.productCategory = sampleOld.productCategory;
            vm.populateTypes();
            vm.sample.productType = sampleOld.productType;
            vm.sample.strainName = sampleOld.strainName;
            vm.sample.strainType = sampleOld.strainType;
            vm.sample.otherAnalysis = sampleOld.otherAnalysis;
            vm.sample.reportedUnits = sampleOld.reportedUnits;
            vm.sample.harvestDate = sampleOld.harvestDate;
            vm.sample.manufactureDate = sampleOld.manufactureDate;

            sampleOld.sampleTests.forEach(function(sampleTest) {
            	if (sampleTest.assay) {
	            	if (sampleTest.assay.portalIdentifier == 'MOISTURE_CANNABINOID')
	            		return;
	            	vm.selectedAssays[sampleTest.assay.portalIdentifier] = true;
            	}
            });

            update = true;
        }

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.datePickerOpenStatus = {
        		harvestDate: false,
        		manufactureDate: false
        }
        vm.openCalendar = openCalendar;

        function clear () {
            if (!document.activeElement.classList.contains('form-control')) {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function resetTests(){
            vm.selectedAssays = {};
        }

        function save (param) {
            var tagsInputItems = $('#otherAnalysisTagsInput').tagsinput('items')
            vm.sample.otherAnalysis = tagsInputItems

            fillSampleTests();
            resetTests();

            if(param == 1){
                if(vm.sample.lotBatchNumber == ''){vm.sample.lotBatchNumber = null}
                if(update){
                    vm.samples[vm.sample.id] = vm.sample;
                    $uibModalInstance.close("");
                }else{
                    vm.sample.id = vm.samples.length;
                    vm.samples.push(vm.sample);


                     $uibModalInstance.close("");
                }
            }

            else if(param == 2 && update){

                if(vm.sample.lotBatchNumber == ''){vm.sample.lotBatchNumber = null}
                    vm.samples[vm.sample.id] = angular.copy(vm.sample);
                    vm.sample.id = vm.samples.length;
                    vm.sample.name = '';
                    vm.sample.lotBatchNumber = null;
                    vm.sample.productCategory = null;
                    vm.productTypes = null;
                    vm.sample.productType = null;
                    vm.sample.strainName = null;
                    vm.sample.strainType = null;
                    vm.sample.otherAnalysis = [];
                    vm.sample.reportedUnits = null;
                    update = false;
            }

            else if(param == 2){
                if(vm.sample.lotBatchNumber == ''){vm.sample.lotBatchNumber = null}
                if(update){
                    vm.samples[vm.sample.id] = vm.sample;

                }else{
                    vm.sample.id = vm.samples.length;
                    vm.sampleForArray = angular.copy(vm.sample);
                    vm.samples.push(vm.sampleForArray);

                    vm.sample.name = '';
                    vm.sample.lotBatchNumber = null;
                    vm.sample.productCategory = null;
                    vm.productTypes = null;
                    vm.sample.productType = null;
                    vm.sample.strainName = null;
                    vm.sample.strainType = null;

                    vm.sample.otherAnalysis = null;
                    vm.sample.reportedUnits = null;
                    update = false;
                }
            }
        }


        function fillSampleTests() {

        	vm.sample.sampleTests = [];

             var has_cannabinoid_test = false;

             for (var key in vm.selectedAssays) {
            	 if (vm.selectedAssays[key] == false) continue;
            	 var assay = vm.assays[key];
            	 //May handle pushing the Moisture (CAN) in the front end here....
            	 vm.sample.sampleTests.push({assay: angular.copy(assay)});

            	 if (key.includes('POTENCY'))
            		 has_cannabinoid_test = true;
             }

             /* Corner Cases for Different Sample Types */
             if (vm.sample.productCategory.name == "Plant" && has_cannabinoid_test) {
            	 vm.sample.sampleTests.push({assay: angular.copy(vm.assays['MOISTURE_CANNABINOID'])});
             }
        }

        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:sampleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function populateTypes () {
            vm.productTypes = ProductTypeByCategory.query(vm.sample.productCategory);
        }

        function loadStrains(){
           vm.suggestedStrains = StrainNameSearch.query({searchString: vm.sample.strainName.name});
        }

        function setStrainName(name){
            vm.sample.strainName.id = 0;
            vm.sample.strainName.name = name;
            vm.suggestedStrains = [];
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.resetOtherAnalysis= function() {
            $('#otherAnalysisTagsInput').tagsinput('removeAll');
        }

        angular.element(document).ready(function() {
            $('#otherAnalysisTagsInput').tagsinput({ delimiter: ';', confirmKeys: [13],});
        });
        
    }
})();
