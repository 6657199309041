(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('calendar', {
            parent: 'app',
            url: '/calendar',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Estimated Due Date Calendar'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/calendar/calendar.html',
                    controller: 'CalendarController',
                    controllerAs: 'vm'
                }
            }
        }).state('calendar.dayModal', {
            url: '/day',
            params: {
            	date: null,
            	samples: null,
            	queryParams: null
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$uibModal', function($uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/calendar/calendar-day-modal.html',
                    controller: 'CalendarDayModalController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                    	PreviousState: function() {
                    		return {
                    			name: 'calendar'
                    		}
                    	}
                    }
                });
            }]
        });
    }


})();
