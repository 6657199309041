
(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('CompanyPageController', CompanyPageController)

    CompanyPageController.$inject = ['$state','$stateParams','$scope','$rootScope', '$filter', '$timeout', 'Sample', 'SampleSearch',
        'AlertService', 'pagingParams', 'SampleFilter',
        'SampleAttach','SampleAttachDownload', 'SampleExportService', 'ProductCategory','ProductTypeByCategory', 'CompanyPage', 'Principal', 'SampleCoaPdfService'];

    function CompanyPageController($state, $stateParams,$scope, $rootScope, $filter, $timeout, Sample, SampleSearch, AlertService, pagingParams, SampleFilter,
                                 SampleAttach,SampleAttachDownload, SampleExportService, ProductCategory, ProductTypeByCategory, CompanyPage, Principal, SampleCoaPdfService) {

        var vm = this;
		$rootScope.showSearch = pagingParams.search;

		vm.isAuthenticated = Principal.isAuthenticated;

        vm.search = search;
        vm.clear = clear;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.pagingParams = pagingParams;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        $scope.isOtherAnalysis = isOtherAnalysis;
        $scope.hasOtherAnalyses = hasOtherAnalyses;
        $scope.hasCannabinoids = hasCannabinoids;
        $scope.getTotalThc = getTotalThc;
		vm.isinPortal = false
		if(window.top == window.self){
			vm.isinPortal = true
		}
		else{
			vm.isinPortal = false
		}

        $scope.assayIdentifierToName = function(identifier){
        	var assayIdMap = {
        			'POTENCY_LCMSMS': 'Cannabinoid Profile',
        			'POTENCY_GCFID': 'Cannabinoid Profile',
        			'POTENCY_LCDAD': 'Cannabinoid Profile',
        			'POTENCY_LCDAD_NON_PLANT': 'Cannabinoid Profile',
        			'RESIDUAL_SOLVENT_PHASE_II': 'Residual Solvents',
        			'RESIDUAL_SOLVENT_PHASE_I': 'Residual Solvents',
        			'HEAVY_METALS': 'Heavy Metals',
        			'HEAVY_METALS_FOOD': 'Heavy Metals',
        			'TERPENE_PROFILE': 'Terpene Profile',
        			'PESTICIDES_PLUS': 'Pesticide Screen',
        			'PESTICIDES_PHASE_II': 'Pesticide Screen',
        			'MICRO_QA_SCREEN': 'Microbiology',
        			'MICRO_PLUS': 'Microbiology',
        			'MICRO_INHALABLES': 'Microbiology',
        			'MICRO_NON_INHALABLES': 'Microbiology',
        			'MYCOTOXINS': 'Mycotoxins',
        			'WATER_ACTIVITY': 'Water Activity',
        			'FOREIGN_MATERIAL': 'Foreign Materials',
        			'MOISTURE': 'Moisture',
        			'SULFUR': 'Sulfur',
        			'HOMOGENEITY': 'Homogeneity',
        			'HOMOGENEITY_5': 'Homogeneity',
        			'PESTICIDES_MYCOTOXINS': 'Pesticides + Mycotoxins',
        			//'ALKALOID': 'Alkaloid'
        	}

        	var assay = assayIdMap[identifier];
        	if (!assay){
        		return "";
        	} else {
        		return assay;
        	}
        }
        $scope.canSeeOtherAnalyses = function(sample) {
        	var canSee = true;
        	for (var i = 0; i < sample.sampleTests.length; i++) {
        		var test = sample.sampleTests[i];
        		if (!test.assay) continue;
        		if (test.assay.id === 18 || test.assay.id === 19 || test.assay.id === 15) {
        			if (!test.canSeeResults) canSee = false;
        		}
        	}
        	return canSee;
        }
        $scope.canSeeTest = function(sample, testName) {
        	var assayIdMap = {
        			'POTENCY_LCMSMS': 'Cannabinoid Profile',
        			'POTENCY_GCFID': 'Cannabinoid Profile',
        			'POTENCY_LCDAD': 'Cannabinoid Profile',
        			'POTENCY_LCDAD_NON_PLANT': 'Cannabinoid Profile',
        			'RESIDUAL_SOLVENT_PHASE_II': 'Residual Solvents',
        			'RESIDUAL_SOLVENT_PHASE_I': 'Residual Solvents',
        			'HEAVY_METALS': 'Heavy Metals',
        			'HEAVY_METALS_FOOD': 'Heavy Metals',
        			'TERPENE_PROFILE': 'Terpene Profile',
        			'PESTICIDES_PLUS': 'Pesticide Screen',
        			'PESTICIDES_PHASE_II': 'Pesticide Screen',
        			'MICRO_QA_SCREEN': 'Microbiology',
        			'MICRO_PLUS': 'Microbiology',
        			'MICRO_INHALABLES': 'Microbiology',
        			'MICRO_NON_INHALABLES': 'Microbiology',
        			'MYCOTOXINS': 'Mycotoxins',
        			'WATER_ACTIVITY': 'Water Activity',
        			'FOREIGN_MATERIAL': 'Foreign Materials',
        			'MOISTURE': 'Moisture',
        			'SULFUR': 'Sulfur',
        			'HOMOGENEITY': 'Homogeneity',
        			'HOMOGENEITY_5': 'Homogeneity',
        			'PESTICIDES_MYCOTOXINS': 'Pesticides + Mycotoxins',
        			//'ALKALOID': 'Alkaloid'
        	}
        	for (var i = 0; i < sample.sampleTests.length; i++) {
        		var test = assayIdMap[sample.sampleTests[i].assay.portalIdentifier];
        		if (test === testName) {
        			return sample.sampleTests[i].canSeeResults;
        		}
        	}
		}

		vm.downloadSampleCOA =  function(sample_id, sample_hash) {
			var a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display: none";
			SampleCoaPdfService.downloadPdf(sample_hash).then(function(result) {
				var file = new Blob([result.data], {
					type: 'application/pdf'
				});

				a.href = window.URL.createObjectURL(file);
				a.download = sample_id + '_COA_Summary.pdf';
				a.click();
			});
		}

		vm.determinePfDisplay = function(pf) {
			switch(pf){
				case "PASS":
					return "<p class=\"test-pass\" style=\"color: #28d300\">\
								<span class=\"fa fa-check-circle test-result-icon\"></span>\
								<span class=\"test-result-text\">&nbsp;Pass</span>\
							</p>";
				case "FAIL" :
					return "<p class=\"test-fail\" style=\"color: red\">\
								<span class=\"fa fa-times-circle test-result-icon\"></span>\
								<span class=\"test-result-text\">&nbsp;Fail</span>\
							</p>";
				case "IN_PROGRESS":
					return "<p class=\"test-in-progress\" style=\"color: #ff8100\">\
								<span class=\"fa fa-clock-o test-result-icon\"></span>\
								<span class=\"test-result-text\">&nbsp;In Progress</span>\
							</p>";
				case "NO_STATUS":
					return "<p class=\"test-in-progress\" style=\"color: gray\">\
								<span class=\"fa fa-times-circle test-result-icon\"></span>\
								<span class=\"test-result-text\">&nbsp;Not Tested</span>\
							</p>";
				case "RETESTING":
					return "<p class=\"test-retesting\" style=\"color: #5eb1bf\">\
								<span class=\"fa fa-repeat test-result-icon\"></span>\
								<span class=\"test-result-text\">&nbsp;Retesting</span>\
							</p>";
				default:
					return "<p class=\"not-tested\" style=\"color: gray\">\
								<span class=\"fa fa-stop-circle test-result-icon\"></span>\
								<span class=\"test-result-text\">&nbsp;No Status</span>\
							</p>";
			}
		}

		vm.determinePotencyDisplay = function(sample, potencyType, total, show_dash) {
			if (!sample.sampleTests) {
				return '';
			}
			var identifiers = ['POTENCY_LCDAD', 'POTENCY_GCFID', 'POTENCY_LCMSMS', 'POTENCY_LCDAD_NON_PLANT'];
			var potency = '';
			var ws = null;

			if(total) {
				potencyType = 'thc';
			}

        	for (var i = 0; i < sample.sampleTests.length; i++) {
				if(sample.sampleTests[i].assay){
					if(identifiers.includes(sample.sampleTests[i].assay.portalIdentifier)) {
						if(sample.sampleTests[i].worksheetData) {
							ws = sample.sampleTests[i].worksheetData;

							if(ws['total_' + potencyType + '_mgpp']){
								if(!total) {
									potency = ws['total_' + potencyType + '_mgpp'].twoDecimalFormattedValue ? ws['total_' + potencyType + '_mgpp'].twoDecimalFormattedValue + ' mg/pkg' : '';
								}
								else {
									potency = ws['total_mgpp'].twoDecimalFormattedValue ? ws['total_mgpp'].twoDecimalFormattedValue + ' mg/pkg' : '';
								}
							}
							else if(ws.units_per_package == '1' && ws['total_' + potencyType + '_mgpu'] && potency == '') {
								if(!total) {
									potency = ws['total_' + potencyType + '_mgpu'].twoDecimalFormattedValue ? ws['total_' + potencyType + '_mgpu'].twoDecimalFormattedValue + ' mg/serving' : '';
								}
								else {
									potency = ws['total_mgpu'].twoDecimalFormattedValue ? ws['total_mgpu'].twoDecimalFormattedValue + ' mg/serving' : '';
								}
							}
							else if(ws.moisture.value != 0 && ws['total_' + potencyType + '_dry_percent'] && potency == '') {
								if(!total) {
									potency = ws['total_' + potencyType + '_dry_percent'].twoDecimalFormattedValue ? ws['total_' + potencyType + '_dry_percent'].twoDecimalFormattedValue + ' % (dry)' : '';
								}
								else {
									potency = ws['total_dry_percent'].twoDecimalFormattedValue ? ws['total_dry_percent'].twoDecimalFormattedValue + ' % (dry)' : '';
								}
							}
							else {
								if(!total) {
									potency = ws['total_' + potencyType + '_percent'].twoDecimalFormattedValue ? ws['total_' + potencyType + '_percent'].twoDecimalFormattedValue + ' %' : '';
								}
								else {
									potency = ws['total_percent'].twoDecimalFormattedValue ? ws['total_percent'].twoDecimalFormattedValue + ' %' : '';
								}
							}
						}
					}
				}
			}

			if(show_dash) {
				return potency ? potency : '-';
			}
			else {
				return potency;
			}
		}

        vm.downloadSamplesCsv = function downloadSamplesCsv () {

            var fileName = "samples.csv";
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            SampleExportService.downloadCsv().then(function (result) {
                var file = new Blob([result.data], {type: 'application/json'});
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName;
                a.click();
            });


		}
		
		vm.showFile = function(name) {
			var dont_show = ['_Labels', '_QR', 'medium', 'serv', '_slider_', ]
			for( var i = 0; i < dont_show.length; i++) {
				if(name.includes(dont_show[i])) {
					return false;
				}
			}
			return true;
		}

		/* Download Samples */
        function downloadSamplesSuccess(fileName, result) {
    		if (result.data.byteLength == 0) {
    			vm.downloadSamplesNull = true;
    			vm.downloadingSamples = false;
    			return;
    		}

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            var file = new Blob([result.data], {type: 'application/json'});
            var fileURL = window.URL.createObjectURL(file);
            a.href = fileURL;
            a.download = fileName;
            a.click();
            vm.downloadingSamples = false;
        }
        function downloadSamplesError(err) {
        	vm.downloadingSamples = false;
        	vm.downloadSamplesError = true;
        }

        vm.updateSamplePublicity = function(sample) {
            vm.getAttachmentsLoader = false;
            sample.attachments = null;
            Sample.public({id: sample.id, public: ':public'});

        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.downloadSampleStartDate = false;
        vm.datePickerOpenStatus.downloadSampleEndDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.downloadSample = {
        		startDate: null,
        		endDate: null,
        		type: 'COA'
        }

        vm.downloadingSamples = false;
        vm.downloadSamplesError = false;
        vm.downloadSamplesNull = false;

        vm.downloadSamples = function() {
        	vm.downloadSamplesError = false;
        	vm.downloadSamplesNull = false;
        	var sampleExportParams = {
                	startDate: vm.downloadSample.startDate ? vm.downloadSample.startDate.getTime()/1000 : null,
                	endDate: vm.downloadSample.endDate ? vm.downloadSample.endDate.getTime()/1000 : null,
                	type: vm.downloadSample.type
        	}
        	vm.downloadingSamples = true;
        	if (vm.downloadSample.type == "COA") {
            	SampleExportService.downloadCoa(sampleExportParams).then(function (result) {
                    var fileName = "samples";
                    if (sampleExportParams.startDate && sampleExportParams.endDate) {
                    	fileName += " " + $filter('date')(vm.downloadSample.startDate, 'MM-dd-yyyy') + " to " + $filter('date')(vm.downloadSample.endDate, 'MM-dd-yyyy');
                    } else if (sampleExportParams.startDate) {
                    	fileName += " after " + $filter('date')(vm.downloadSample.startDate, 'MM-dd-yyyy');
                    } else if (sampleExportParams.endDate) {
                    	fileName += " before " + $filter('date')(vm.downloadSample.endDate, 'MM-dd-yyyy');
                    }
                    fileName += ".zip";

            		downloadSamplesSuccess(fileName, result);

                }, function(error) {
                	downloadSamplesError(error);
                });
        	} else {
            	SampleExportService.downloadCsv(sampleExportParams).then(function (result) {
            		var fileName = "samples.csv";
            		downloadSamplesSuccess(fileName, result);

                }, function(error) {
                	downloadSamplesError(error);
                });
        	}
        }

        vm.getAttachmentsLoader = false;

        vm.setpagesize = setpagesize;

        setPageTitle();
        $scope.range = new Array(20);

        function setpagesize(pagesize) {
            vm.itemsPerPage = pagesize;
            loadAll ();
        }

		/* Page loads */
        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.sampleFilters = {};
            vm.populateTypes();
            vm.transition();
        }

        vm.toggleWidget = function(name) {
        	if (vm.activeWidget === "sampleEditColumns") {
        		saveSampleColumns();
        	}

        	vm.activeWidget = name === vm.activeWidget ? null : name;
        }

        vm.sampleFilters = {
        		id: pagingParams.id,
        		orderId: pagingParams.orderId,
        		sampleName: pagingParams.sampleName,
        		lotNumber: pagingParams.lotNumber,
        		metrcId: pagingParams.metrcId,
        		categoryIdList: pagingParams.categoryIdList,
        		category: pagingParams.category,
        		subCategory: pagingParams.subCategory,
        		orderType: pagingParams.orderType,
        		thcLower: pagingParams.thcLower,
        		thcUpper: pagingParams.thcUpper,
        		cbdLower: pagingParams.cbdLower,
        		cbdUpper: pagingParams.cbdUpper,
        		isPass: pagingParams.isPass == 'true' ? pagingParams.isPass != null : null,
        		isFail: pagingParams.isFail == 'true' ? pagingParams.isFail != null : null,
        		isUndetermined: pagingParams.isUndetermined == 'true' ? pagingParams.isUndetermined != null : null,
        		isWeedmapsSuccess: pagingParams.isWeedmapsSuccess == 'true' ? pagingParams.isWeedmapsSuccess != null : null,
        		isWeedmapsFail: pagingParams.isWeedmapsFail == 'true' ? pagingParams.isWeedmapsFail != null : null,
        		isWeedmapsNone: pagingParams.isWeedmapsNone == 'true' ? pagingParams.isWeedmapsNone != null: null,
        		visibility: pagingParams.visibility,
        		completeDateLower: pagingParams.completeDateLower ? convertLocalDateFromServer(pagingParams.completeDateLower) : null,
        		completeDateUpper: pagingParams.completeDateUpper ? convertLocalDateFromServer(pagingParams.completeDateUpper) : null,
        		estimatedDueDateLower: pagingParams.estimatedDueDateLower ? convertLocalDateFromServer(pagingParams.estimatedDueDateLower) : null,
        		estimatedDueDateUpper: pagingParams.estimatedDueDateUpper ? convertLocalDateFromServer(pagingParams.estimatedDueDateUpper) : null,
        		starred: pagingParams.starred ? pagingParams.starred : null
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	var params = createFilterQueryParams();

        	if ($scope.starred)
        		params['starred'] = true;
        	else
        		params['starred'] = null;

            $state.transitionTo($state.$current, params);
        }

        function createFilterQueryParams() {
        	var params = {};
            var dateFields = ["completeDateLower", "completeDateUpper", "estimatedDueDateLower", "estimatedDueDateUpper"];
        	for (var key in vm.sampleFilters) {
        		if (dateFields.indexOf(key) !== -1) {
         			params[key] = convertLocalDateToServer(vm.sampleFilters[key]);
         		} else if (vm.sampleFilters[key] !== "" && vm.sampleFilters[key] !== null && vm.sampleFilters[key] !== undefined) {
        			params[key] = vm.sampleFilters[key];
        		}
        	}

        	if ($scope.starred)
        		params['starred'] = true;

        	params.hash = vm.customerHash;
        	params.page = vm.page;
        	params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            params.search = vm.currentSearch;
            params.size = vm.itemsPerPage;
            params.items = params.size;
            params.productCategoryId = vm.productCategoryId;

        	return params;
        }

		$scope.starred = pagingParams.starred ? pagingParams.starred : false;

        vm.filterSubmit = function() {
        	vm.page = 1;
        	vm.transition();
        }

		vm.toggleStarredView = function() {
			$scope.starred = !$scope.starred;

			vm.filterSubmit();
		}

        function setPageTitle(){
            $rootScope.pageTitle = "Company Page";
            $rootScope.searchVm = vm;
        }

        vm.itemsPerPage = "20";
        vm.customerHash = pagingParams.hash ? pagingParams.hash : 100;


        vm.populateTypes = function() {
        	if (vm.sampleFilters.category) {
        		vm.productTypes = ProductTypeByCategory.query(vm.sampleFilters.category);
        	} else {
        		vm.productTypes = [];
        	}

        }

		vm.productCategories = ProductCategory.query();

		
		function showFiles(attachments) {
			var available_attachments = []

			attachments.forEach( function(attachment) {
				if(vm.showFile(attachment.file_name)) {
					available_attachments.push(attachment);
				}
			});

			return available_attachments;
		}


        $scope.getAttachments = function (sample,id) {
	        vm.getAttachmentsLoader = true;
	        vm.idOfSelectedSample = id;


	        if(sample.attachments != null){
		        vm.getAttachmentsLoader = false;
		        return;
	        }

	        CompanyPage.getAttachments({id: sample.hash}, onSuccess, onError);
	        function onSuccess(data, headers) {
		        vm.getAttachmentsLoader = false;
		        sample.attachments = showFiles(data);

	        }
	        function onError(error) {
	        	AlertService.error(error.data.message);
	        }
        }

		$scope.downloadAttachment = function (id_hash, sample) {
		    SampleAttachDownload.get({id: id_hash}, onSuccess, onError);

		    function onSuccess(data, headers) {
	            var a = document.createElement("a");
	            document.body.appendChild(a);
	            a.style = "display: none";

	            var fileURL = window.URL.createObjectURL(data.data);
	            a.href = fileURL;
	            a.download = headers('content-disposition').substring(21);
	            a.click();
		        sample.select = "";
		    }
		    function onError(error) {

		    }
		}

        vm.createSamplePublicLink = function(sample) {
        	var hash = sample.hash;
        	var fullUrl = window.location.origin +"/#/ps/"+ sample.hash;
        	vm.currentPublicSample = sample;
        	return fullUrl;
        }

		function extractPackageValues(samples) {
			samples.forEach( function (sample){
				sample.sampleTests.forEach( function (test){
					if (test.worksheetData){
						if (test.worksheetData['total_thc_mgpp']){
							sample.thc_mgpp = test.worksheetData['total_thc_mgpp'].value;
						}
						if (test.worksheetData['total_cbd_mgpp']){
							sample.cbd_mgpp = test.worksheetData['total_thc_mgpp'].value;
						}
						if (test.worksheetData['total_mgpp']){
							sample.total_mgpp = test.worksheetData['total_mgpp'].value;
						}
					}
				});
			});
		}

        vm.emailsPending = [""];
        vm.emailMessage = "";


        vm.sendPublicEmail = function (form) {
        	vm.emailsPending = $('#testtaginput').tagsinput('items')
        	vm.emailSubject = $('#emailSubject').val();
        	//Send The Email
        	if (form.$valid && vm.emailsPending.length > 0){
        		$('#sendPublicSampleButton').attr("disabled", true);
        		CompanyPage.sendEmail({
        			emails: vm.emailsPending,
        			message: vm.emailMessage,
        			public_link: vm.emailSamplePublicLink,
        			pic_url: vm.currentPublicSample.picture,
        			id: vm.currentPublicSample.hash,
        			totalTHC: getTotalThc(vm.currentPublicSample),
        			subject: vm.emailSubject
        		}, onSuccess, onError);
        	}
        	else {
        		if (vm.emailsPending.length == 0)
        			$('#emailValidation').show();
        		return
        	}
        	function onSuccess(data, headers) {
        		$('#emailModal').modal('hide');
        		$('#emailModalSuccess').modal('show');
        		$('#sendPublicSampleButton').attr("disabled", false);

	        }

	        function onError(error) {
	        	AlertService.error(error.data.message);
	        	vm.emailsPending = [""];
	            vm.emailMessage = "";
	            $('#sendPublicSampleButton').attr("disabled", false);

	        }
        }

    $('#emailModalSuccess').on('hidden.bs.modal', function() {
        	$('#testtaginput').tagsinput('removeAll');
        	$('#testtaginput').tagsinput('input')[0].placeholder = 'email@example.com';
        })


		$('#testtaginput').tagsinput({
			tagClass: function(item) {
				return 'custom-label label-email'
			}
		});

		$('#testtaginput').on('beforeItemAdd', function(event) {
			var email_re = "^\S+@\S+$";
			if (!event.item.match(/\S+@\S+\.\S+/)) {
				$('#emailValidation').show();
				event.cancel = true;
			} else {
				$('#emailValidation').hide();
				$('#testtaginput').tagsinput('input')[0].placeholder = '';
			}
		});

		$('#testtaginput').on('beforeItemRemove', function(event) {
			if ($('#testtaginput').tagsinput('input')[0].value.length > 0)
				event.cancel = true;
			  // event.item: contains the item
			  // event.cancel: set to true to prevent the item getting removed
		});

		$('#testtaginput').tagsinput('input')[0].type = 'email'

        function loadAll () {

        	var params = createFilterQueryParams();

        	CompanyPage.query({
                   page: pagingParams.page - 1,
                   size: vm.itemsPerPage,
                   customer_hash: vm.customerHash,
                   params: params
               }, onSuccess, onError);

	           function sort() {
	               var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
	               if (vm.predicate !== 'id') {
	                   result.push('id');
	               }

	               return result;
	           }

	           function onSuccess(data, headers) {
	               vm.totalItems = headers('X-Total-Count');
	               vm.queryCount = vm.totalItems;
				   vm.samples = data;
	               vm.page = pagingParams.page;
	               vm.pagingParams = pagingParams;

	               loadAllPictures();
	               extractPackageValues(vm.samples);
	           }

	           function onError(error) {
	               AlertService.error(error.data.message);
	           }

	            function loadAllPictures() {
	            	var sample_ids = []
	            	var pictures = []
	    	        for (var i = 0; i < vm.samples.length; i++) {
	    	        	sample_ids.push(vm.samples[i].hash);
	    	        }
	            	CompanyPage.getBulkPictures({ids: sample_ids},onSuccess,onError);

	            	function onSuccess(pictures) {

	                	for (var i = 0; i < vm.samples.length; i++) {
	                		var pic = pictures[vm.samples[i].id][0].slice(-1)[0]
	        	        	vm.samples[i].picture = pic ? pic.url : "content/images/no_image_available-small.png";
	        	        }
	            	}

	            	function onError(error) {

	            	}
	            }


       }

	    function getCustomerData () {
	        CompanyPage.getCustomer({customer_hash: vm.customerHash}, onSuccess, onError); //Does not work for this page.
	        function onSuccess(data, headers) {
	             vm.customerData = data;
	        }
	        function onError(error) {
	        }
	    }

	    getCustomerData();

        /* Hover functions */
        function isOtherAnalysis(test){
        	if (test && test.assay && test.assay.id){
        		if (test.assay.id == 26 || test.assay.id == 15 || test.assay.id == 18 || test.assay.id == 19) {
        			return true;
        		}
        	}
        	return false;
        }
        function hasOtherAnalyses(sample){
        	if (!sample.sampleTests) return false;
        	for (var i=0; i < sample.sampleTests.length; i++){
        		if (!sample.sampleTests[i].assay) continue;
        		if (isOtherAnalysis(sample.sampleTests[i])){
        			return true;
        		}
        	}
        	return false;
        }
        function hasCannabinoids(sample){
			if (!sample.sampleTests) return false;
			if (sample.sample_coa_total_cannabinoids || sample.sample_coa_total_cbd || sample.sample_coa_total_thc ) {
				return true;
			}
        	// for (var i=0; i < sample.sampleTests.length; i++){
        	// 	if (!sample.sampleTests[i].assay) continue;
        	// 	var assayId = sample.sampleTests[i].assay.id
        	// 	if (assayId == 1 || assayId == 2 || assayId == 3 || assayId == 43){
        	// 		return true;
        	// 	}
        	// }
        	return false;
        }

        function getTotalThc(sample) {
        	if (!sample.sampleTests) return;
        	for (var i = 0; i < sample.sampleTests.length; i++){
        		var test = sample.sampleTests[i];
        		if (!test.assay || !test.assay.portalIdentifier) continue;
        		var assay = test.assay.portalIdentifier;
        		if (assay === 'POTENCY_LCDAD' || assay === 'POTENCY_GCFID' || assay === 'POTENCY_LCMSMS' || assay === 'POTENCY_LCDAD_NON_PLANT') {
        			if (test.worksheetData && test.worksheetData.total_percent && test.worksheetData.total_percent.twoDecimalFormattedValue) {
        				return test.worksheetData.total_percent.twoDecimalFormattedValue + '%';
        			}
        		}
        	}
        	return 'N/A';
        }

        vm.copyLink = function(id) {
      	  /* Get the text field */
      	  var copyText = document.getElementById(id);
      	  /* Select the text field */
      	  copyText.select();
      	  copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      	  /* Copy the text inside the text field */
      	  document.execCommand("copy");
        }

       loadAll();

       if (vm.sampleFilters.category) {
       	vm.populateTypes();
       }

       vm.startIndex = 0;
       vm.endIndex = 0;

    }

})();
