(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('UserManagementDialogController',UserManagementDialogController)
    	.controller('UserManagementEditDialogController', UserManagementEditDialogController);

    // Add User
    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User','CustomerSearch', 'ContactForCustomer'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, CustomerSearch, ContactForCustomer) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
       // vm.user = entity;

        vm.loadCustomers = loadCustomers;
        vm.setCustomer = setCustomer;
        vm.selectContact = selectContact;

        vm.populateCustomerContacts = populateCustomerContacts;

        vm.setRole = function(contactCustomer) {
        	if (contactCustomer.role == "ADMIN") {
        		contactCustomer.role = null;
        	} else {
        		contactCustomer.role = "ADMIN";
        	}
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
            
        }

        function onSaveError (error) {
            vm.error = error;
            vm.isSaving = false;
            vm.errorMsg = error.data.data
        }

        function save () {
            if (vm.contact.id==null) {
                vm.noContact = 'ERROR';
                return;
            }
            else {
                vm.noContact = null;
            }

            if (vm.contact.password != vm.contact.confirmPassword) {
                vm.doNotMatch = 'ERROR';
                return;
            }
            else {
               vm.doNotMatch = null;
            }
            vm.currentLoginName = vm.contact.login;
            vm.currentLoginEmail = vm.contact.email;
            
            vm.isSaving = true;
            vm.contact.langKey = 'en';
            vm.contact.activated = true;
            User.save(vm.contact, onSaveSuccess, onSaveError);
        }

        function loadCustomers(){
            if (vm.searchText == null || vm.searchText == "" ){
                vm.suggestedCustomers =  [];
                vm.customer = null;

            }
            else {
            	clearTimeout(vm.customerSearchTimeout);
            	vm.customerSearchTimeout = setTimeout(searchCustomers, 1000);
            }
            
            function searchCustomers() {
            	CustomerSearch.query({searchString: vm.searchText},onCustomerSearchSuccess, onCustomerSearchError)
            }

            function onCustomerSearchSuccess(data, headers) {
                 vm.suggestedCustomers = data;
                 if (vm.suggestedCustomers.length == 1 && vm.suggestedCustomers[0].name.toLowerCase() == vm.searchText.toLowerCase() ) {
                    vm.customer = vm.suggestedCustomers[0] ;
                 }
                 else if (vm.suggestedCustomers.length >1) {

                    for (var i = 0, len = vm.suggestedCustomers.length; i < len; i++) {
                      if (vm.suggestedCustomers[i].name.toLowerCase() == vm.searchText.toLowerCase()) {

                        vm.customer = vm.suggestedCustomers[i];
                        vm.populateCustomerContacts();
                        return;
                      }
                    }
                    clearCustomer();
                 }
                 else {
                    clearCustomer();
                 }

            }
            function onCustomerSearchError(error) {

            }
        }

        function clearCustomer(){
            vm.customer = null;
            vm.customerContacts = null;
            vm.contact = null;

        }
        function setCustomer(customer){
            vm.customer = customer;
            vm.searchText = vm.customer.name;
            vm.suggestedCustomers =  [];
            vm.populateCustomerContacts();
        }

        function populateCustomerContacts () {
            vm.customerContacts = ContactForCustomer.query({customerId: vm.customer.id});
        }
        
        function selectContact(){
        	if (vm.contact.login) {
        		vm.existingAccount = true
        	} else {
        		vm.existingAccount = false;
        	}
        }
    }
    
    
    // Edit User
    UserManagementEditDialogController.$inject = ['$stateParams', '$uibModalInstance', 'ContactEnt', 'Contact'];

    function UserManagementEditDialogController ($stateParams, $uibModalInstance, ContactEnt, Contact) {
        var vm = this;

        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        ContactEnt.$promise.then(function(contact){
        	vm.contact = contact;
        	if (contact.receive_coa && contact.receive_invoice) {
        		vm.receive = 'both';
        	} else if (contact.receive_coa) {
        		vm.receive = 'coa';
        	} else if (contact.receive_invoice) {
        		vm.receive = 'inv';
        	} else {
        		vm.receive = 'none';
        	}
        });

        vm.setReceive = function() {
            if(vm.receive == 'coa'){
                vm.contact.receive_coa = true;
                vm.contact.receive_invoice = false;
            } else if(vm.receive == 'inv'){
            	vm.contact.receive_coa = false;
                vm.contact.receive_invoice = true;
            } else if(vm.receive == 'both'){
                vm.contact.receive_coa = true;
                vm.contact.receive_invoice = true;
            } else {
            	vm.contact.receive_coa = false;
            	vm.contact.receive_invoice = false;
            }
        }

        function clear() { $uibModalInstance.dismiss('cancel'); }
        function onSaveSuccess(result) { vm.isSaving = false; $uibModalInstance.close(result); }
        function onSaveError(error) { vm.isSaving = false; vm.error = error && error.data ? error.data : 'Something went wrong.'; }
        
        function save() {
        	Contact.updateContactAndUser(vm.contact, onSaveSuccess, onSaveError);
        }
    }
})();
