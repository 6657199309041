(function() {
    'use strict';

    angular.module('anrescowebApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider.state('publicOld', {
            parent: 'app',
            url: '/public/samples/{hash}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/public/public.html',
                    controller: 'PublicPageController',
                    controllerAs: 'vm'
                }
            },
             resolve: {
                 data: function ($state, $stateParams, SampleService) {
                    var sampleHash = $stateParams.hash;
                    var token = null;
                    return SampleService.get({hash: sampleHash, token: token})
                      .$promise.then(
                            function(data){
                              return data
                              },
                             function(error){
                                $state.go('publicSampleNotFound', {}, {location: 'replace'}); });
                }
             }
        });

        $stateProvider.state('publicWithTokenOld', {
                    parent: 'app',
                    url: '/public/samples/{hash}/{token}',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/pages/public/public.html',
                            controller: 'PublicPageController',
                            controllerAs: 'vm'
                        }
                    },
                     resolve: {
                         data: function ($state, $stateParams, SampleService) {
                            var sampleHash = $stateParams.hash;
                            var token = $stateParams.token;
                            return SampleService.get({hash: sampleHash, token: token})
                              .$promise.then(
                                    function(data){
                                      return data
                                      },
                                     function(error){
                                        $state.go('publicSampleNotFound', {}, {location: 'replace'}); });
                        }
                     }
                });


        $stateProvider.state('publicSampleNotFound', {
            parent: 'app',
            url: '/public/samples-not-found',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/public/not-found.html'
                }
            }
        });

        $stateProvider.state('public', {
            parent: 'app',
            url: '/ps/{hash}',
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                size: 20,
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,

                productCategoryId: {
                    value: '0',
                    squash: true
                }
            },
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/public/public.html',
                    controller: 'PublicPageController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                data: function ($state, $stateParams, SampleService) {
                    var sampleHash = $stateParams.hash;
                    var token = null;
                    return SampleService.get({hash: sampleHash, token: token})
                        .$promise.then(
                            function(data){
                                return data
                            },
                            function(error){
                                $state.go('publicSampleNotFound', {}, {location: 'replace'}); });
                }
            }

        });


        // $stateProvider.state('customerPublic', {
        //     parent: 'app',
        //     url: '/customer/{hash}',
        //     data: {
        //         authorities: []
        //     },
        //     views: {
        //         'content@': {
        //             templateUrl: 'app/pages/public/customer-public.html',
        //             controller: 'PublicPageController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     resolve: {
        //         data: function ($state, $stateParams, SampleService) {
        //             var sampleHash = $stateParams.hash;
        //             var token = null;
        //             return SampleService.get({hash: sampleHash, token: token})
        //                 .$promise.then(
        //                     function(data){
        //                         return data
        //                     },
        //                     function(error){
        //                         $state.go('publicSampleNotFound', {}, {location: 'replace'}); });
        //         }
        //     }
        //
        // });

        $stateProvider.state('publicWithToken', {
                    parent: 'app',
                    url: '/ps/{hash}/{token}',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/pages/public/public.html',
                            controller: 'PublicPageController',
                            controllerAs: 'vm'
                        }
                    },
                     resolve: {
                         data: function ($state, $stateParams, SampleService) {
                            var sampleHash = $stateParams.hash;
                            var token = $stateParams.token;
                            return SampleService.get({hash: sampleHash, token: token})
                              .$promise.then(
                                    function(data){
                                      return data
                                      },
                                     function(error){
                                        $state.go('publicSampleNotFound', {}, {location: 'replace'}); });
                        }
                     }

                });


    }
})();
