(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('InvoiceLogDialogController', InvoiceLogDialogController);

    InvoiceLogDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$rootScope', '$uibModalInstance', 'entity', 'Invoice', 'InvoiceAttachDownload', 'InvoiceAttachDownloadBytes'];

    function InvoiceLogDialogController ($timeout, $scope, $rootScope,  $stateParams, $uibModalInstance, entity, Invoice, InvoiceAttachDownload, InvoiceAttachDownloadBytes) {
        var vm = this;

        vm.invoice = entity;

        //console.log(vm.invoice.sampleList)

        vm.invoiceItemsArray = [];
        vm.subtotal;
        vm.flatSurCharge;
        vm.percentDiscount;
        vm.discount;
        vm.percentSurcharge;
        vm.surcharges = 0;
        vm.total

        vm.downloadInvoicePdf = downloadInvoicePdf;

        var i;
        for(i = 0; i < vm.invoice.invoice.invoiceItems.length; i++) {
          if (vm.invoice.invoice.invoiceItems[i].invoice_item_type == "INVOICE_ITEM") {
                vm.invoiceItemsArray.push(vm.invoice.invoice.invoiceItems[i])
          } else if(vm.invoice.invoice.invoiceItems[i].invoice_item_type == "SUBTOTAL") {
                vm.subtotal = vm.invoice.invoice.invoiceItems[i].amount
          } else if(vm.invoice.invoice.invoiceItems[i].invoice_item_type == "FLAT_SURCHARGE") {
        	  	vm.flatSurcharge = vm.invoice.invoice.invoiceItems[i].amount;
          } else if(vm.invoice.invoice.invoiceItems[i].invoice_item_type == "PERCENT_DISCOUNT") {
                vm.percentDiscount = vm.invoice.invoice.invoiceItems[i].discount
                vm.discount = vm.invoice.invoice.invoiceItems[i].amount
          } else if(vm.invoice.invoice.invoiceItems[i].invoice_item_type == "PERCENT_SURCHARGE") {
                vm.percentSurcharge = vm.invoice.invoice.invoiceItems[i].amount
          }
        }

        if (vm.flatSurcharge){
        	vm.surcharges += vm.flatSurcharge;
        }
        if (vm.percentSurcharge) {
        	vm.surcharges += vm.percentSurcharge;
        }

        vm.samplingAddress;
        if (vm.invoice.customerOrder.samplingAddress){
        	vm.samplingAddress = vm.invoice.customerOrder.samplingAddress;
        }
        var input = vm.invoice.customerOrder.samplingAddress ? vm.invoice.customerOrder.samplingAddress : vm.invoice.customer.address;
        var fields = input.split('@');
         vm.address = fields[0];
         vm.city = fields[1];
         vm.state = fields[2];
         vm.country = fields[3];
         vm.zip = fields[4];

//        console.log("items: " + vm.invoiceItemsArray)
//        console.log("subtotal: " + vm.subtotal)
//        console.log("flat surcharge: "+vm.flatSurCharge)
//        console.log("percent discount:"+vm.percentDiscount)
//        console.log("percent surcharge:" +vm.percentSurcharge)
//        console.log("total:" +vm.invoice.invoice.total)
//        console.log("surcharges:" +vm.surcharges)

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.isOutdatedInvoice = function(invoice) { 
            var invoiceDate = new Date(invoice.invoice_date); 
            var referenceDate = new Date("8/21/2019"); 
             
            return (invoiceDate < referenceDate); 
        } 
        
        $scope.downloadAttachment = function (id) {
            if(id == ''){return}
            InvoiceAttachDownload.get({id: id}, onSuccess, onError);
            function onSuccess(data, headers) {
	            var a = document.createElement("a");
	            document.body.appendChild(a);
	            a.style = "display: none";

	            var fileURL = window.URL.createObjectURL(data.data);
	            a.href = fileURL;
	            a.download = headers('content-disposition').substring(21);
	            a.click();

            }
            function onError(error) {

            }
        }

        $scope.printAttachment = function (id) {


            if(id == ''){return}

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            InvoiceAttachDownload.get({id: id}, onSuccess, onError);
            function onSuccess(data, headers) {
                var fileURL = URL.createObjectURL(data.data);
                $('#invoicePdfIframe').attr('src', fileURL);
            }
            
            function onError(error) {
                if(error.status === 401){
                    clear();
                }
            }
        }




        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function downloadInvoicePdf () {
              window.location.href = vm.invoice.invoicePdfApi.url;

        }

        function save () {
            vm.isSaving = true;
            if (vm.invoice.id !== null) {
                Invoice.update(vm.invoice, onSaveSuccess, onSaveError);
            } else {
                Invoice.save(vm.invoice, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:invoiceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.paymentDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
