(function() {
    'use strict';

    var app = angular
        .module('anrescowebApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAnimate',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ui.select',
            'ngSanitize',
            'angular-bind-html-compile',
            'ui.tinymce',
            'ui.sortable',
            'ui.calendar',
            'oitozero.ngSweetAlert',
            'pascalprecht.translate'
        ])
        .run(run);

        app.config(["$translateProvider", function($translateProvider){

            var en_translations = {};

            var jp_translations = {
                'greeting': '332423423',
                'Account': 'アカウント',
                'Open Orders': '進行中のオーダー数',
                'View Orders': 'オーダーの確認',
                'Samples In-Progress': '到着待ち検体数',
                'Member Since': 'メンバー登録年',
                'Compliance Statistics': 'コンプライアンス統計',
                'No Statistics Available For This Time Range': '選択期間中のデータはありません',
                'Last 3 Months': '過去３ヶ月',
                'Last 6 Months': '過去６ヶ月',
                'Year To Date': '過去１年',
                'All': '全て',
                'Estimated Due Date Calendar': '納期予定日カレンダー',
                'Filter by': 'フィルター',
                'R&D': 'R&D',
                'Compliance': 'コンプライアンス',
                'Plant': 'Plant',
                'Concentrate': 'コンセントレート',
                'Edible': '飲食物',
                'Topical': '化粧品',
                'Environmental': 'Environmental',
                'Other': 'その他',
                'Go To Calendar': 'カレンダーを見る',
                'Your profile info': 'ご登録情報',
                'Business Name': '会社名',
                'Primary Contact Name': 'お名前',
                'Direct Phone': '電話番号',
                'Primary Contact Title': '役職名',
                'Website': 'ウェブサイト',
                'Email': 'メールアドレス',
                'Edit Profile': '登録情報変更',
                'Anresco contact info': 'Anresco担当者情報',
                'Primary Representative': '担当者名',
                'Primary Rep Email': '担当者メールアドレス',
                'Primary Rep Phone': '電話番号',
                'General Email': '一般メールアドレス',
                'General Phone': '一般電話番号',
                'Visit Our Site': 'Anrescoウェブサイトはこちら',
                'Home': 'ホーム',
                'Orders': 'オーダー履歴',
                'Samples': '検体履歴',
                'Public Gallery': 'パブリックギャラリー',
                'Calendar': 'カレンダー',
                'Manage': 'アカウント情報',
                'Search': '検索',
                'Number of entries': '合計オーダー数',
                'Filters': 'フィルター',
                'Apply Filters': '適用',
                'Close': '閉じる',
                'Order No.': 'オーダー番号',
                '# of Samples': '検体数',
                'Turnaround Time': '納期',
                'Receive Date Range': '到着日範囲',
                'Status': 'ステータス',
                'Created': 'オーダー済み',
                'Samples Received': '検体到着',
                'Preliminary Results': '中間結果',
                'Receive Date': '到着日',
                'Downloads': 'ダウンロード',
                '3-4 day (standard)': '3〜4日（通常）',
                'Load': '読み込む',
                'Download Samples': '一括ダウンロード',
                'All Samples': '全ての検体',
                'In Progress': '進行中',
                'Completed': '完了',
                'Table View': 'テーブルビュー',
                'Gallery View': 'ギャラリービュー',
                'Sample No.': '検体番号',
                'Lot #': 'ロット番号',
                'Name': '検体名',
                'Completed Date': '完了日',
                'Starred': 'お気に入り',
                'Sample Name': '検体番号',
                'Enter Number': '番号を入力',
                'e.g.': '例）',
                'Category': 'カテゴリ',
                'Subcategory': 'サブカテゴリ',
                'Order Type': 'オーダー内容',
                '(range)': '範囲指定',
                'Pass/Fail Status': '合否判定',
                'Pass': '合格',
                'Fail': '不合格',
                'Undetermined': '未判定',
                'Completed Date Range': '完了日範囲指定',
                'Start Date': '開始日',
                'End Date': '終了日',
                'Date Range': '日付範囲',
                'Submit': 'ダウンロード',
                'Retrieves by order complete date': 'オーダー作成日での検索',
                'Category (Sub)': 'サブカテゴリー',
                'Total Cannabinoids': 'カンナビノイド合計',
                'Order Number': 'オーダー番号',
                'Received Date': '到着日',
                'Estimated Due Date': '納期予定日',
                'Share': 'シェア',
                'Samples Tab': '検体履歴タブ',
                'TABLE VIEW': 'テーブルビュー',
                'unique features': 'の特徴',
                'View the results of more samples at one time': '一度に多くの検体情報や結果を閲覧可能',
                'Star samples for easy viewing later': '後で見返す時に便利なお気に入り登録機能',
                'Configure columns to quickly view the data that is important to you': '重要なデータを素早く表示するためのカラム設定',
                'GALLERY VIEW': 'ギャラリービュー',
                'View sample images': '検体の画像を表示',
                'For more granular information on a given sample, click on the hyperlinked Sample ID number': '特定の検体に関する詳細な情報については、リンク先の検体 ID番号をクリックしてください。',
                'Total': '合計',
                'Download': 'ダウンロード',
                'Tests': '詳細',
                'Sample ID': '検体ID',
                'Tested': '検査済み',
                'Plant': 'Plant',
                'Concentrate': 'コンセントレート',
                'Edible': '飲食物',
                'Topical': '化粧品',
                'Select': '選択',
                'Hash': 'ハッシュ',
                'Wax': 'ワックス',
                'Shatter': 'シャッター',
                'Kief': 'キーフ',
                'Rosin': 'ロジン',
                'Isolate': 'アイソレート',
                'Distillate': 'ディスティレート',
                'Crystalline': '結晶',
                'Cartridge': 'カートリッジ',
                'Terpene': 'テルペン',
                'Orally Consumed Concentrate': '経口摂取用濃縮物',
                'Crude': 'クルード',
                'Emulsion': '乳化加工物',
                'Baked Good': '焼き菓子',
                'Hard Candy/Lozenge': 'ハードキャンディ',
                'Soft Chew': 'ソフトキャンディ',
                'Chocolate': 'チョコレート',
                'Solid Fats (Butter, Sat. Fats)': '固形脂（バター、飽和脂肪）',
                'Capsule': 'カプセル',
                'Suppository': '座薬',
                'Syrup/Honey': 'シロップ/ハチミツ',
                'Beverage': '清涼飲料',
                'Carbonated Beverage': '炭酸飲料',
                'Tincture': 'ティンクチャー',
                'Liquid Fats (Oils)': '液状油（オイル）',
                'Orally Dissolving Product': '口腔内崩壊製品',
                'Gummy': 'グミ',
                'Powder': 'パウダー',
                'Infused Product': '含有製品',
                'Lotion/Balm': 'ローション/バーム',
                'Salve': '軟膏',
                'Transdermal Patch': '経皮パッチ',
                'Nanoemulsion': 'ナノエマルジョン',
                'Paper/Wrap': '紙/ラップ',
                'Cones/Tubes': 'コーン/チューブ',
                'Oil': 'オイル',
                'Inactive Ingredient': '添加物',
                'Select Action': 'アクションを選択',
                'Move Up': '上に移動',
                'Move Down': '下に移動',
                'Add Samples': '検体を追加',
                'Remove Selected': '選択アイテムを削除',
                'Set Criteria': '条件の設定',
                'Select Action': 'アクションを選択',
                'Reposition': '並び替え',
                'Select Type': '選択してください',
                'Order Type': 'オーダー内容',
                'Save': '保存',
                'Cancel': 'キャンセル',
                'Sample Number': '検体番号',
                'Lot Number': 'ロット番号',
                'Reset': 'リセット',
                'Search Samples': '検索',
                'RESULTS': '検索結果',
                'Type': '検体種類',
                'see list': '詳細表示',
                'No Samples': '該当なし',
                'CONTACT LIST': '連絡先',
                'COMPANY INFO': '会社情報',
                'Type of Business': '業種',
                'Business Description': '会社概要',
                'Street Address': '住所',
                'State': '都道府県',
                'Phone Number': '電話番号',
                'City': '市区町村',
                'Zip Code': '郵便番号',
                'Country': '国',
                'Fax': 'ファックス',
                'Contact Name': '連絡先氏名',
                'Phone': '電話番号',
                'Title': '役職',
                'Reporting': '結果報告',
                'Billing': '請求',
                'Edit Contact': '連絡先の変更',
                'First Name': 'ファーストネーム',
                'Direct Phone': '直通電話番号',
                'Last Name': 'ラストネーム',
                'To Receive': '受け取り',
                'Admin User': '管理者ユーザー',
                'Username': 'ユーザー名',
                'Password': 'パスワード',
                "leave blank if don't want to change": '変更しない場合は空白のまま',
                'New password': '新規パスワード',
                'Confirm the new password': '新規パスワードを再度入力してください',
                'Password strength': 'パスワードの安全性',
                'Recent Invoices': '直近の請求書',
                'Invoice No.': '請求書番号',
                'Invoiced Date': '発行日',
                'Amount': '金額',
                'recent orders submitted': '直近のオーダー状況',
                'Submit New': '新規オーダー',
                'Remove Sample?': '検体を削除しますか？',
                'Remove Samples?': '検体を削除しますか？',
                "Remove samples from Public Gallery? You can't undo this.": '一度削除すると復元することはできません。',
                "Remove sample from Public Gallery? You can't undo this.": '一度削除すると復元することはできません。',
                "Don't remove": 'キャンセル',
                'Yes, remove': '削除する',
                'Add New Contact': '新規連絡先追加',
                'This action cannot be undone.': '度削除すると復元することはできません。',
                'Delete':'削除'



            }

            $translateProvider.translations('en',en_translations);

            $translateProvider.translations('jp',jp_translations);

            $translateProvider.preferredLanguage('en');

          }]);

    app.filter('highlightMatch', function() {
        return function(words, search) {
            return (search ? words.replace(new RegExp('(' + search + ')', 'i'), '<span class="filter-match">$1</span>') : words);
        }
    });

    app.filter('lngFilterOptions', function() {
        return function (options, lng) {
            if (lng == 'jp') {
                var filteredOptions = [];
                var ignore = ['Plant', 'Environmental']
                if (options && options.length) {
                    options.forEach( function(op) {
                        if (!ignore.includes(op.text || op.name)) filteredOptions.push(op);
                    });
                }
                return filteredOptions
            } else return options;

        }
    });

    app.filter('isEmpty', [function() {

        return function(objectOrArray) {

            if(Array.isArray(objectOrArray)) {

                return !objectOrArray.length > 0;

            }

            return angular.equals({}, objectOrArray);

        };

    }]);

    app.filter('numberFormat', function() {
        return function(format, input) {
          var lastChar;

          format= '(___) ___-____';

          if (!input) {
            return;
          }

          for (var index = 0; index < input.length; index++) {
            var char = input.charAt(index);
            if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(char))) {
              if (format.indexOf('_') >= 0) {
                format = format.replace('_', char);
                lastChar = char;
              }
            }
          }

          if (lastChar) {
            return format.substring(0, format.lastIndexOf(lastChar) + 1);
          }
        }
      });

      app.directive('phoneNumberFormat', ['$filter', function($filter) {
        return {
          resolve: 'A',
          replace: true,
          link: function(scope, ele, attrs) {
            var modelName = attrs.ngModel;
            var lastNestedObj = attrs.lastnestedobject
            var formatValue = attrs.format;

            scope.$watch(modelName, function(newValue, oldValue) {
              Object.byString(scope, modelName)[lastNestedObj] = $filter('numberFormat')(formatValue, newValue);
            });
          }
        }
      }]);

    app.filter('utcToLocal', ['$filter', function($filter) {
    	return function(utcDateString, format) {
    		if (!utcDateString) return;

    		if (utcDateString.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) utcDateString += 'Z';

    		return $filter('date')(utcDateString, format);
    	}
    }]);

    app.directive('collapse', ['$animate', '$q', '$parse', '$injector', function ($animate, $q, $parse, $injector) {
        var $animateCss = $injector.has('$animateCss') ? $injector.get('$animateCss') : null;
        return {
            link: function(scope, element, attrs) {
                var expandingExpr = $parse(attrs.expanding),
                    expandedExpr = $parse(attrs.expanded),
                    collapsingExpr = $parse(attrs.collapsing),
                    collapsedExpr = $parse(attrs.collapsed),
                    horizontal = false,
                    css = {},
                    cssTo = {};

                init();

                function init() {
                    horizontal = !!('horizontal' in attrs);
                    if (horizontal) {
                        css = {
                            width: ''
                        };
                        cssTo = {width: '0'};
                    } else {
                        css = {
                            height: ''
                        };
                        cssTo = {height: '0'};
                    }
                    if (!scope.$eval(attrs.collapse)) {
                        element.addClass('in')
                            .addClass('collapse')
                            .attr('aria-expanded', true)
                            .attr('aria-hidden', false)
                            .css(css);
                    }
                }

                function getScrollFromElement(element) {
                    if (horizontal) {
                        return {width: element.scrollWidth + 'px'};
                    }
                    return {height: element.scrollHeight + 'px'};
                }

                function expand() {
                    if (element.hasClass('collapse') && element.hasClass('in')) {
                        return;
                    }

                    $q.resolve(expandingExpr(scope))
                        .then(function() {
                            element.removeClass('collapse')
                                .addClass('collapsing')
                                .attr('aria-expanded', true)
                                .attr('aria-hidden', false);

                            if ($animateCss) {
                                $animateCss(element, {
                                    addClass: 'in',
                                    easing: 'ease',
                                    css: {
                                        overflow: 'hidden'
                                    },
                                    to: getScrollFromElement(element[0])
                                }).start()['finally'](expandDone);
                            } else {
                                $animate.addClass(element, 'in', {
                                    css: {
                                        overflow: 'hidden'
                                    },
                                    to: getScrollFromElement(element[0])
                                }).then(expandDone);
                            }
                        }, angular.noop);
                }

                function expandDone() {
                    element.removeClass('collapsing')
                        .addClass('collapse')
                        .css(css);
                    expandedExpr(scope);
                }

                function collapse() {
                    if (!element.hasClass('collapse') && !element.hasClass('in')) {
                        return collapseDone();
                    }

                    $q.resolve(collapsingExpr(scope))
                        .then(function() {
                            element
                            // IMPORTANT: The width must be set before adding "collapsing" class.
                            // Otherwise, the browser attempts to animate from width 0 (in
                            // collapsing class) to the given width here.
                                .css(getScrollFromElement(element[0]))
                                // initially all panel collapse have the collapse class, this removal
                                // prevents the animation from jumping to collapsed state
                                .removeClass('collapse')
                                .addClass('collapsing')
                                .attr('aria-expanded', false)
                                .attr('aria-hidden', true);

                            if ($animateCss) {
                                $animateCss(element, {
                                    removeClass: 'in',
                                    to: cssTo
                                }).start()['finally'](collapseDone);
                            } else {
                                $animate.removeClass(element, 'in', {
                                    to: cssTo
                                }).then(collapseDone);
                            }
                        }, angular.noop);
                }

                function collapseDone() {
                    element.css(cssTo); // Required so that collapse works when animation is disabled
                    element.removeClass('collapsing')
                        .addClass('collapse');
                    collapsedExpr(scope);
                }

                scope.$watch(attrs.collapse, function(shouldCollapse) {
                    if (shouldCollapse) {
                        collapse();
                    } else {
                        expand();
                    }
                });
            }
        };
    }]).directive('dropdownCheckboxes', dropdownCheckboxes);

    function dropdownCheckboxes() {
    	return {
        	link: function(scope, element, attrs) {
        		var $btn = $(element).find('button');
        		var $div = $(element).find('div');

        		$btn.click(function(){
        			$(this).toggleClass('btn-active');
        			$div.toggle();
        		});

        	}
    	}
    }

    run.$inject = ['stateHandler'];

    Object.byString = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1');
        s = s.replace(/^\./, '');
        var a = s.split('.');
        for (var i = 0, n = a.length-1; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    function run(stateHandler) {
        stateHandler.initialize();
    }
})();
