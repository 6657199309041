
(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('PublicViewController', PublicViewController)

    PublicViewController.$inject = ['$state','$stateParams','$scope','$rootScope', '$filter', '$timeout', 'AlertService', 'pagingParams', 'Auth', 'Principal', 'SampleFilter',
        'CustomerByUser','SampleAttach', 'ProductCategory', 'PublicView', 'SweetAlert', 'QRCode', '$translate'];

    function PublicViewController($state, $stateParams,$scope, $rootScope, $filter, $timeout, AlertService, pagingParams, Auth, Principal, SampleFilter,
                                 CustomerByUser, SampleAttach, ProductCategory, PublicView, SweetAlert, QRCode, $translate) {

		var vm = this;
		vm.currentLanguage = $translate.proposedLanguage();							
        $rootScope.showSearch = pagingParams.search;
        vm.search = search;
        vm.clear = clear;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.pagingParams = pagingParams;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.getAttachmentsLoader = false;
        vm.itemsPerPage = "20";

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.setpagesize = setpagesize;
		var first_shuffel_edit = false

        setPageTitle();
        $scope.range = new Array(20);

        function setPageTitle(){
            $rootScope.pageTitle = "Public Gallery";
            $rootScope.searchVm = vm;
        }

        function setpagesize(pagesize) {
            vm.itemsPerPage = pagesize;
            loadAll ();
        }

		/* Page loads */
        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.sampleFilters = {};
            vm.populateTypes();
            vm.transition();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	var params = createFilterQueryParams();

            $state.transitionTo($state.$current, params);
        }

        function createFilterQueryParams() {
        	var params = {};
            var dateFields = ["completeDateLower", "completeDateUpper", "estimatedDueDateLower", "estimatedDueDateUpper"];
        	for (var key in vm.sampleFilters) {
        		if (dateFields.indexOf(key) !== -1) {
         			params[key] = convertLocalDateToServer(vm.sampleFilters[key]);
         		} else if (vm.sampleFilters[key] !== "" && vm.sampleFilters[key] !== null && vm.sampleFilters[key] !== undefined) {
        			params[key] = vm.sampleFilters[key];
        		}
        	}

        	if ($scope.starred)
        		params['starred'] = true;

        	params.page = vm.page;
        	params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            params.search = vm.currentSearch;
            params.size = vm.itemsPerPage;
            params.items = params.size;
            params.productCategoryId = vm.productCategoryId;

        	return params;
        }



        /* Login/Logout */
        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        /* Navbar Toggle */
        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function LinkedListNode (sample, prev, next) {
    		this.sample = sample;
    		this.prev = prev;
    		this.next = next;

    		this.getSample = function() {
        		return this.sample;
        	}

        	this.extractNodes = function() {
        		return {id: this.sample.id, previous_sample_id: this.sample.previous_sample_id, next_sample_id: this.sample.next_sample_id, is_public: this.sample.is_public}
        	}
        }

        vm.nodesRemoved = [];

        function SampleLinkedList() {
    		this.head = null;
    		this.tail = null;
    		this.length = 0;

    		this.outerSampleIDPrev = null;
    		this.outerSampleIDNext = null;
    		this.outerSampleIDPrevPrev = null;
    		this.outerSampleIDNextNext = null;

        	this.getHead = function () {
        		return this.head;
        	}

        	this.getTail = function () {
        		return this.tail;
        	}

        	this.setTail = function (node) {
        		this.tail = node;
        	}

        	this.getNode = function(index) {
        		var currentNode = this.head;

        		for (var i = 0; i < this.length; i++) {
        			if (i == index) {
        				return currentNode;
        			}

        			if (currentNode.next != null)
        				currentNode = currentNode.next;
        		}
        	}

        	this.setOuterSampleIDPrev = function(value) {
        		this.outerSampleIDPrev = value;
        	}

        	this.setOuterSampleIDNext = function(value) {
        		this.outerSampleIDNext = value;
        	}

        	this.push = function(node) {
        		this.tail.next = node;
        		this.length++;
        	}

        	this.pushSample = function(sample) {

        		if (this.length == 0) {
            		this.head = new LinkedListNode(sample, null, null);
            		this.tail = this.head

            		this.outerSampleIDPrev = sample.previous_sample_id;
            		this.outerSampleIDNext = sample.next_sample_id;

        		} else {
        			var oldTail = this.tail;
        			var newTail = new LinkedListNode(sample, this.tail, null);

        			oldTail.next = newTail;
        			this.tail = newTail;
        			this.outerSampleIDNext = sample.next_sample_id;
        		}

        		this.length++;
        	}

        	this.addToHeadSample = function(sample) {
        		var oldHead = this.head;

        		this.head = new LinkedListNode(node, null, oldHead);
        		oldHead.prev = this.head;

        		this.length++;
        	}

        	this.addToHead = function(node, newSample) {
        		var oldHead = this.head;

        		if (node.prev != null)
        			node.prev.next = node.next;

        		this.head = node;
        		this.head.prev = null;

        		this.head.next = oldHead;
        		oldHead.prev = this.head;

        		if (newSample)
        			this.length++;
        	}

        	this.printLinkedList = function() {
        		var currentNode = this.head;

        		for (var i = 0; i < this.length; i++) {
        			if (currentNode == null)
        				break;

        			var previous_id = currentNode.prev ? currentNode.prev.sample.id : "None"
        			var next_id = currentNode.next ? currentNode.next.sample.id : "None"
        			console.log("Index " + i + ": " + currentNode.sample.id + " | Prev: " + previous_id + " | Next: " + next_id);

        			currentNode = currentNode.next;
        		}
        	}

        	this.insert = function(node, index, backward) {
        		var currentNode = this.head;

        		for (var i = 0; i < this.length; i++) {
        			if (i == index) { //figure this out
        				var nextNode = null;
        				var prevNode = null;


        				//If moving node is the next node
        				if (currentNode.next && currentNode.next.sample.id == node.sample.id) {
        					nextNode = currentNode;
        					prevNode = currentNode.prev;

        					currentNode.prev.next = node;
        					currentNode.prev = node;
        					currentNode.next = node.next;
        					node.next.prev = currentNode;

        					node.prev = prevNode;
            				node.next = nextNode;

        				}
        				else if (currentNode.next && backward) {
        					nextNode = currentNode.next;
        					prevNode = currentNode.prev;

        					var tempNext = node.next;
        					var tempPrev = node.prev;

        					//remove that node
        					if (tempNext != null)
        						tempNext.prev = tempPrev;

        					if (tempPrev != null) {
        						tempPrev.next = tempNext;
        					} else {
        						this.head = currentNode;
        					}

        					node.next = currentNode;
        					node.prev = prevNode;
        					prevNode.next = node;

        					currentNode.prev = node;

        				}

        				//If moving node is the prev node
        				else if (currentNode.prev && currentNode.prev.sample.id == node.sample.id) {
        					nextNode = currentNode.next;
        					prevNode = currentNode;

        					currentNode.prev = node.prev;
        					node.prev.next = currentNode;

        					currentNode.next = node;

        					node.prev = prevNode;
            				node.next = nextNode;
        				}
        				else if (currentNode.prev) {

        					nextNode = currentNode.next;
        					prevNode = currentNode.prev;

        					var tempNext = node.next;
        					var tempPrev = node.prev;

        					if (tempPrev)
        						tempPrev.next = tempNext;
        					else
        						this.head = tempNext;

        					if (tempNext != null) {
        						tempNext.prev = tempPrev;
        					} else {
        						this.tail = nextNode;
        					}

        					node.next = nextNode;
        					node.prev = currentNode;


        					if (nextNode != null)
        						nextNode.prev = node;

        					currentNode.next = node;
        				}

        				break;
        			}

        			currentNode = currentNode.next;
        		}
        	}

        	this.remove = function(index) {
        		var currentNode = this.head;
        		for (var i = 0; i < this.length; i++) {
        			if (i == index) {
        				var prevNode = currentNode.prev ? currentNode.prev : null;
        				var nextNode = currentNode.next ? currentNode.next : null;

        				if (prevNode != null) {
        					prevNode.next = nextNode;
        					if (index == this.length-1)
        						this.tail = prevNode;
        				}

        				if (nextNode != null) {
        					nextNode.prev = prevNode;
        					if (index == 0)
        						this.head = nextNode;
        				}

        				this.length--;

        				currentNode.sample.is_public = false;
        				currentNode.sample.previous_sample_id = null;
        				currentNode.sample.next_sample_id = null;

        				vm.nodesRemoved.push(currentNode.extractNodes());

        				break;
        			}

        			if (currentNode != null)
        				currentNode = currentNode.next;
        		}
        		this.resortSamples();

        	}

        	this.moveNodesUp = function(indices, iterations) {
        		//Swap the Nodes above node
        		var controller = this;

        		indices.forEach(function(index){
        			var node = controller.getNode(index);

        			if (node.prev != null) {
        				var prevNode = node.prev;
        				var nextNode = node.next;

        				prevNode.next = nextNode;

        				if (prevNode.prev) {
        					prevNode.prev.next = node;
        				} else {
        					controller.head = node;
        				}

        				node.prev = prevNode.prev;

        				prevNode.prev = node;


        				if (index < controller.length - 1)
        					nextNode.prev = prevNode;

        				node.next = prevNode;

        			} else if (index == 0 && !controller.outerSampleIDPrev) {
        				console.log('Can No Longer Move Up Further.');
        			} else if (index == 0) {
        				var newSampleItem = new LinkedListNode({id: controller.outerSampleIDPrev}, null, node.next);
        				var nextNode = node.next;
        				controller.setOuterSampleIDPrev(node.sample.id);

        				controller.head = newSampleItem;

        				if (nextNode)
        					nextNode.prev = controller.head;

        			}
        		});

        		this.resortSamples();
        		return;
        	}

        	this.moveNodesDown = function(indices, iterations) {
        		var controller = this;

        		//Swap the Nodes below node
        		indices.forEach(function(index){
        			var node = controller.getNode(index);

        			if (node.next != null) {
        				var prevNode = node.prev;
        				var nextNode = node.next;

        				if (nextNode.next == null)
        					controller.tail = node;
        				else
        					nextNode.next.prev = node;

        				node.next = nextNode.next;
        				nextNode.next = node

        				if (index > 0) {
	        				prevNode.next = nextNode;
	        				nextNode.prev = prevNode;
        				}
        				else {
        					nextNode.prev = null;
        					controller.head = nextNode;
        				}

        				node.prev = nextNode;

        			}
        			else if (index == controller.length - 1 && !controller.outerSampleIDNext) {
        				console.log('Can No Longer Move Down Further.')
        			}
        			else if (index == controller.length - 1){
        				var newSampleItem = new LinkedListNode({id: controller.outerSampleIDNext}, node.prev, null);
        				var prevNode = node.prev;

        				controller.setOuterSampleIDNext(node.sample.id);
        				controller.setTail(newSampleItem);
        				prevNode.next = controller.tail;


    				}
        		});

        		this.resortSamples();
        		return;
        	}

        	this.moveNode = function(fromIndex, toIndex) {
        		var node = this.getNode(fromIndex);
        		var backwards = false;

        		if (fromIndex == toIndex)
        			return

        		if(fromIndex > toIndex)
        			backwards = true;

        		if (toIndex == 0)
        			this.addToHead(node, false);
        		else
        			this.insert(node, toIndex, backwards);

        		this.resortSamples();

        	}

        	this.pop = function(){
        		this.tail = this.tail.prev;

        		this.tail.next = null;
        		this.length--;
        	}

        	this.resortSamples = function() {
        		var currentNode = this.head;

        		for (var i = 0; i < this.length; i++) {

        			if (i == 0) {
        				currentNode.sample.previous_sample_id = this.outerSampleIDPrev;
        				currentNode.sample.next_sample_id = currentNode.next ? currentNode.next.sample.id : this.outerSampleIDNext;
        			} else if (i == this.length-1) {
        				currentNode.sample.previous_sample_id = currentNode.prev ? currentNode.prev.sample.id : this.outerSampleIDPrev;
        				currentNode.sample.next_sample_id = this.outerSampleIDNext;
        			} else {
        				currentNode.sample.next_sample_id = currentNode.next.sample.id;
        				currentNode.sample.previous_sample_id = currentNode.prev.sample.id;
        			}

        			currentNode = currentNode.next;
        		}


        	}

        	this.returnLinkedListAsArray = function() {
        		var sampleList = [];

        		var currentNode = this.head;
        		sampleList.push(currentNode)

        		while (currentNode.next != null) {
        			currentNode = currentNode.next;
        			sampleList.push(currentNode)
        		}

        		return sampleList
        	}

        	this.returnLinkedListAsDict = function() {
        		var sampleDict = {};

        		var currentNode = this.head;
        		sampleDict[currentNode.sample.id] = currentNode;

        		while (currentNode.next != null) {
        			currentNode = currentNode.next;
        			sampleDict[currentNode.sample.id] = currentNode;
        		}

        		return sampleDict
        	}

        	this.returnLinkedListAsSampleArray = function() {
        		var sampleList = [];

        		var currentNode = this.head;

        		var i = 0;
        		while (currentNode != null && i < this.length) {
        			sampleList.push(currentNode.extractNodes());
        			currentNode = currentNode.next;

        			i++;
        		}

        		var boundaries = this.getOuterSamplesAsSamples();

        		if (boundaries.prevprev) {
        			sampleList.push(boundaries.prevprev)
        		}

        		if (boundaries.prev) {
        			sampleList.push(boundaries.prev)

        		}


        		if (boundaries.next)
        			sampleList.push(boundaries.next)

        		if (boundaries.nextnext)
        			sampleList.push(boundaries.nextnext)

        		return sampleList
        	}

        	this.getOuterSamplesAsSamples = function() {
        		var boundaries = null;

        		if (this.length == 0) { //In the case where we remove like everything.
        			boundaries = {};

        			if (this.outerSampleIDPrev) {
        				boundaries.prev = {id: this.outerSampleIDPrev, next_sample_id: this.outerSampleIDNext}
        			}

        			if (this.outerSampleIDNext) {
        				boundaries.next = {id: this.outerSampleIDNext, previous_sample_id: this.outerSampleIDPrev}
        			}
        		}
        		else {
        			boundaries = {};

        			if (this.outerSampleIDPrevPrev)
        				boundaries.prevprev = {id: this.outerSampleIDPrevPrev, next_sample_id: this.outerSampleIDPrev};

        			if (this.outerSampleIDPrev)
        				boundaries.prev = {id: this.outerSampleIDPrev, previous_sample_id: this.outerSampleIDPrevPrev, next_sample_id: this.head.sample.id};

        			if (this.outerSampleIDNext)
        				boundaries.next = {id: this.outerSampleIDNext, previous_sample_id: this.tail.sample.id, next_sample_id: this.outerSampleIDNextNext};

        			if (this.outerSampleIDNextNext)
        				boundaries.nextnext = {id: this.outerSampleIDNextNext, previous_sample_id: this.outerSampleIDNext};
        		}

        		return boundaries
        	}

        	this.returnLinkedListAsSampleDict = function() {
        		var sampleDict = {};

        		var currentNode = this.head;
        		sampleDict[currentNode.sample.id] = currentNode.sample;

        		while (currentNode.next != null) {
        			currentNode = currentNode.next;
        			sampleDict[currentNode.sample.id] = currentNode.sample;
        		}

        		return sampleDict
        	}

        }

        function setOuterBoundaries() {

        	PublicView.getSampleBoundaries({prev_sample_id: vm.sampleLinkedList.outerSampleIDPrev,next_sample_id: vm.sampleLinkedList.outerSampleIDNext}, onSuccess, onError);
        	function onSuccess(data, headers) {

        		vm.sampleLinkedList.outerSampleIDPrevPrev = data.previous_sample;
        		vm.sampleLinkedList.outerSampleIDNextNext = data.next_sample;

        	};

        	function onError(error) {
	               AlertService.error(error.data.message);
	           }
        }

        function loadAll () {
        	PublicView.query({
                   page: pagingParams.page - 1,
                   size: vm.itemsPerPage,
               }, onSuccess, onError);

	           function sort() {
	               var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
	               if (vm.predicate !== 'id') {
	                   result.push('id');
	               }

	               return result;
	           }

	           function onSuccess(data, headers) {
	               vm.totalItems = headers('X-Total-Count');
	               vm.queryCount = vm.totalItems;
	               vm.samples = data;
	               vm.page = pagingParams.page;
	               vm.pagingParams = pagingParams;
				   data.forEach((element,i) => {
						if(element.next_sample_id === null && element.previous_sample_id === null){
							first_shuffel_edit = true
						}
					})
	               loadAllPictures();
	               vm.sampleLinkedList = new SampleLinkedList();

	               vm.samples.forEach(function(sample) {
	            	   vm.sampleLinkedList.pushSample(sample);
	           		});

	               extractPackageValues(vm.samples);

	               if (vm.samples)
	            	   setOuterBoundaries();

	               $( "#sortable li" ).each(function() {$(this).show();});

	           }

	           function onError(error) {
	               AlertService.error(error.data.message);
	           }

	           function loadAllPictures() {
	            	var sample_ids = []
	            	var pictures = []
	    	        for (var i = 0; i < vm.samples.length; i++) {
	    	        	sample_ids.push(vm.samples[i].hash);
	    	        }
	            	SampleAttach.getBulkPictures({ids: sample_ids},onSuccess,onError);

	            	function onSuccess(pictures) {

	                	for (var i = 0; i < vm.samples.length; i++) {
	                		var pic = pictures[vm.samples[i].id][0].slice(-1)[0] // Cannot read property '0' of undefined
	        	        	vm.samples[i].picture = pic ? pic.url : "content/images/no_image_available-small.png";
	        	        }
	            	}

	            	function onError(error) {

	            	}
	            }


       }

        vm.hash = 0;
	    function getCustomerData () {
	        CustomerByUser.get({}, onSuccess, onError);
	        function onSuccess(data, headers) {
				vm.customerData = data;
				vm.hash = vm.customerData.hash

				var type_of_business = data.businessType.name.toLowerCase();

				if (type_of_business.includes('food')) {
					vm.customerData.consolidated_business_type = 'food';
				}
				else {
					vm.customerData.consolidated_business_type = 'cannabis';
				}

				$("#copyEmbedLink").prop("value", "<iframe src='"+ window.location.origin +"/#/companypage?hash=" + vm.hash + "' style='height: 800px; width: 1000px'></iframe>" )
				vm.publicSampleCriteria = vm.customerData.public_sample_criteria ? JSON.parse(vm.customerData.public_sample_criteria) : null;
				//Select2 Selector for Criteria
				$('#category').select2({
				tags: true,
				placeholder: "Select Type",
				});

				generateQRCode()
	        }
	        function onError(error) {

	        }
	    }

	    vm.resetCriteria = function() {
	    	if (vm.publicSampleCriteria) {
		       	 $('#category').val(vm.publicSampleCriteria.category).change();
		    	 $('#ALL').prop("checked", vm.publicSampleCriteria.order_type == "ALL");
		    	 $('#RD').prop("checked", vm.publicSampleCriteria.order_type == "RD");
		    	 $('#COMPLIANCE').prop("checked", vm.publicSampleCriteria.order_type == "COMPLIANCE");
		    	 $('#THC_LOW').val(vm.publicSampleCriteria.thc_low);
		    	 $('#THC_HIGH').val(vm.publicSampleCriteria.thc_high);
		    	 $('#CBD_LOW').val(vm.publicSampleCriteria.cbd_low);
		    	 $('#CBD_HIGH').val(vm.publicSampleCriteria.cbd_high);
		    	 $('#sampleStatusPass').prop("checked", vm.publicSampleCriteria.status.indexOf("PASS") != -1);
		    	 $('#sampleStatusFail').prop("checked", vm.publicSampleCriteria.status.indexOf("FAIL") != -1);
		    	 $('#sampleStatusUndetermined').prop("checked", vm.publicSampleCriteria.status.indexOf("UNDETERMINED") != -1);
		    	 $('#sampleDateRange').val(vm.publicSampleCriteria.date_range);
            } else {
            	$('#COMPLIANCE').prop("checked", true);
            	$('#sampleStatusPass').prop("checked", true);
            }
	    }

	    getCustomerData();

        vm.copyLink = function(id) {
      	  /* Get the text field */
      	  var copyText = document.getElementById(id);
      	  /* Select the text field */
      	  copyText.select();
      	  copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      	  /* Copy the text inside the text field */
      	  document.execCommand("copy");
        }

        vm.getEmbedLink = function() {
        	return window.location.origin + '/#/companypage?hash=' + vm.hash
		}
		function generateQRCode() {
			QRCode.generate({text: vm.getEmbedLink()},
				function(data) {
					vm.shareQRCode = data.qr_code;
				},
				function(e) {
					console.log(e)
				}
			);
		}


       loadAll();
       vm.startIndex = 0;
       vm.endIndex = 0;

       vm.searchedSamplesLength = 0;
       vm.productCategories = ProductCategory.query();

       vm.searchResultsState = "INITIAL";

       vm.searchSamples = function(form){
    	   var inputs = $(form).serializeArray()
    	   var params = {visibility: 'PRIVATE'};

    	   inputs.forEach(function(item, index) {
    		   params[item.name] = item.value;
    	   });

           SampleFilter.query(params, onSuccess, onError);
           function onSuccess(result, headers) {
               vm.searchedSamples = result;
               vm.searchedSamplesLength = headers('X-Total-Count');

               if (vm.searchedSamplesLength > 0)
            	   vm.searchResultsState = "FOUND";
               else
            	   vm.searchResultsState = "NONE";

           }
           function onError(error) {
           	console.log("Error applying filter");
           	console.log(error);
           }

       }

       vm.addSamples = function() {
    	   var checkedSamples = $(':checkbox.selectedSearchSample:checked');
    	   var idsToAdd = []
    	   checkedSamples.each(function() {
    		   var sample = JSON.parse($(this).attr('sample'));
    		   idsToAdd.push(sample.id);
    	   });

    	   PublicView.addSamples({sample_ids: idsToAdd},onSuccess, onError)

    	   function onSuccess(result, headers) {
    		   loadAll();
    		   $('#addSamplesModal').modal('hide');

           }

           function onError(error) {
           	console.log(error);
           }
       }

       vm.clearAddSampleForm = function(form) {
    	   vm.searchResultsState = "INITIAL";
    	   vm.searchedSamples = null;
           vm.searchedSamplesLength = 0;
           $(form)[0].reset();
       }

       $('#addSamplesModal').on('hide.bs.modal', function() {
          //clear out all of the forms.
    	   vm.clearAddSampleForm('#searchSamplesForm');
    	   vm.searchResultsState = "INITIAL";
       });

       vm.selectAllSearchedSamplesToggle = function() {
    	   if ($('#selectAllSearchedSamples').prop('checked')) {
    		   $(':checkbox.selectedSearchSample').prop('checked',true);
    	   } else
    		   $(':checkbox.selectedSearchSample').prop('checked',false);
	   }

	   vm.selectAllPublicViewToggle = function() {
		if ($('#selectAllPubicView').prop('checked')) {
			$(':checkbox.public-view-item-checkbox').prop('checked',true);
		} else
			$(':checkbox.public-view-item-checkbox').prop('checked',false);
	}



       vm.setCriteria = function() {
    	   var criteriaObject = {
    		category: $('#category').val(),
    		order_type: $('input:radio[name="criteriaOrderType"]:checked').val(),
    		status: [],
    		date_range: $('#sampleDateRange').val(),
    		thc_low: $('#THC_LOW').val(),
    		thc_high: $('#THC_HIGH').val(),
    		cbd_low: $('#CBD_LOW').val(),
    		cbd_high: $('#CBD_HIGH').val()
    	   }

    	   if ($('#sampleStatusPass').prop("checked"))
    		   criteriaObject.status.push('PASS');

    	   if ($('#sampleStatusFail').prop("checked"))
    		   criteriaObject.status.push('FAIL');

    	   if ($('#sampleStatusUndetermined').prop("checked"))
    		   criteriaObject.status.push('UNDETERMINED');

    	   PublicView.setCriteria({criteria: criteriaObject},onSuccess, onError);

    	   function onSuccess(result, headers) {
    		   $('#setCriteriaModal').modal('hide');
    		   vm.publicSampleCriteria = result;
           }

           function onError(error) {
        	   console.log(error);
           }

       }

       $('#setCriteriaModal').on('show.bs.modal', function() {
    	  vm.resetCriteria();
       });

       function extractPackageValues(samples) {
			samples.forEach( function (sample){
				sample.sampleTests.forEach( function (test){
					if (test.worksheetData){
						if (test.worksheetData['total_thc_mgpp']){
							sample.thc_mgpp = test.worksheetData['total_thc_mgpp'].value;
						}
						if (test.worksheetData['total_thc_mgpp']){
							sample.cbd_mgpp = test.worksheetData['total_thc_mgpp'].value;
						}
						if (test.worksheetData['total_mgpp']){
							sample.total_mgpp = test.worksheetData['total_mgpp'].value;
						}
					}
				});
			});
		}


       $( "#sortable" ).sortable({
       	handle: '.handler',
       	start: function(event, ui) {
       		vm.startIndex = ui.item.index();
       	},
       	stop: function(event, ui) {
       		vm.endIndex = ui.item.index();

       		vm.sampleLinkedList.moveNode(vm.startIndex, vm.endIndex);

       		var samples = vm.sampleLinkedList.returnLinkedListAsSampleArray();

       		vm.saveSampleStates(samples, vm.nodesRemoved, null, false);

       	}
       });

       vm.saveSampleStates = function (samples, remove_samples, new_sample_list, reload) {
    	   var params = {}

    	   params['samples'] = samples;
    	   params['remove_samples'] = remove_samples;
		   samples.forEach(e => {
			   if(e.previous_sample_id !== null && e.next_sample_id !== null){
				   first_shuffel_edit = false
				}
				e.first_shuffel = first_shuffel_edit
		   })
    	   PublicView.saveSamples({samples: samples, remove_samples: remove_samples}, onSuccess, onError);

    	   function onSuccess(result, headers) {
    		   vm.nodesRemoved = []; //Empty the list of removed nodes

    		   if (reload) {
    			  if (vm.sampleLinkedList.length == 0 && vm.page > 1 && !vm.sampleLinkedList.outerSampleIDNext) {
    				  vm.page--;
    				  vm.transition();
    			  }
    			   loadAll();
    		   }
           }

           function onError(error) {
        	   console.log(error);
           }
       }

       vm.moveSamplesUp = function() {
    	   var indices = [];
    	   var idsToRemove = [];
    	   var boundaryReached = false;

    	   $('.public-view-item-checkbox:checked').each(function() {
    		   var listItem = $(this).closest('li');

    		   if (listItem.index() != 0) {
	    		   var toSwap = listItem.prev();
	    		   indices.push(listItem.index());
	    		   listItem.insertBefore(toSwap);
    		   } else if (vm.sampleLinkedList.outerSampleIDPrev) {
    			   indices.push(listItem.index());
    			   boundaryReached = true;
    		   }
    	   });
    	   vm.sampleLinkedList.moveNodesUp(indices)

    	   var samples = vm.sampleLinkedList.returnLinkedListAsSampleArray();

    	   vm.saveSampleStates(samples, vm.nodesRemoved, null, boundaryReached);
       }

       vm.moveSamplesDown = function() {
    	   var indices = [];
    	   var idsToRemove = [];
    	   var boundaryReached = false;

    	   $($('.public-view-item-checkbox:checked').get().reverse()).each(function() {
    		   var listItem = $(this).closest('li');

    		   //This is for moving up. Adjust Accordingly.
    		   if (listItem.index() != vm.sampleLinkedList.length-1) {
    			   var toSwap = listItem.next();
    			   indices.push(listItem.index());
	    		   listItem.insertAfter(toSwap);
    		   } else if (vm.sampleLinkedList.outerSampleIDNext) {
    			   indices.push(listItem.index());
    			   boundaryReached = true;
    		   }

    	   });

    	   vm.sampleLinkedList.moveNodesDown(indices)
    	   var samples = vm.sampleLinkedList.returnLinkedListAsSampleArray();

    	   vm.saveSampleStates(samples, vm.nodesRemoved, null, boundaryReached);
       }

		vm.confirmRemove = function(element){
			var text = '';
			$('#confirmRemoveSampleModal').modal('show');

			if (element) {
				$('#confirmRemoveTitle').html($translate.instant("Remove Sample?"))
				$('#confirmRemoveMessage').html($translate.instant("Remove sample from Public Gallery? You can't undo this."))
			} else {
				$('#confirmRemoveTitle').html(""+$translate.instant("Remove Samples?"));
				$('#confirmRemoveMessage').html($translate.instant("Remove samples from Public Gallery? You can't undo this."));
			}

			$('#confirmRemoveSampleButton').on('click', function() {
				(element) ? vm.removeItem(element) : vm.removeMultipleItems();
			});
	   }


		vm.removeMultipleItems = function() {
			var indices = [];
			var new_sample_list = vm.samples;
			var idsToRemove = [];

			$('.public-view-item-checkbox:checked').each(function() {
				$(this).prop('checked',false);
				indices.push($(this).closest('li').index());
				//More reliable: remove the actual sample ids instead
			});

			$('.public-view-item-checkbox').prop('checked', false);

			indices.forEach(function(value, index) {
				vm.sampleLinkedList.remove(value-index);
			});

			vm.nodesRemoved.forEach(function(sample) {
				idsToRemove.push(sample.id);
			});

			new_sample_list = new_sample_list.filter(function(sample){
				return idsToRemove.indexOf(sample.id) == -1;
			});

			var samples = vm.sampleLinkedList.returnLinkedListAsSampleArray();

			vm.saveSampleStates(samples, vm.nodesRemoved, new_sample_list, true);
    	}

       vm.removeItem = function(element) {
    	   var item = $(element.currentTarget).parent().closest('li');
    	   var index = item.index();
    	   vm.sampleLinkedList.remove(index);

    	   var samples = vm.sampleLinkedList.returnLinkedListAsSampleArray();
    	   //Make Post Request to update Sample List State

    	   var idsToRemove = [];

    	   var new_sample_list = vm.samples;

    	   vm.nodesRemoved.forEach(function(sample) {
    		   idsToRemove.push(sample.id);
    	   });

    	   new_sample_list = new_sample_list.filter(function(sample){
    		   return idsToRemove.indexOf(sample.id) == -1;
    	   });
    	   $(item).hide();
    	   vm.saveSampleStates(samples, vm.nodesRemoved, new_sample_list, true);

    	   //Refresh the page.
       }

		$('#testtaginput').tagsinput({
			tagClass: function(item) {
				return 'custom-label label-email'
			}
		});

		$('#testtaginput').on('beforeItemAdd', function(event) {
			var email_re = "^\S+@\S+$";
			if (!event.item.match(/\S+@\S+\.\S+/)) {
				$('#emailValidation').show();
				event.cancel = true;
			} else {
				$('#emailValidation').hide();
				$('#testtaginput').tagsinput('input')[0].placeholder = '';
			}
		});

		$('#testtaginput').on('beforeItemRemove', function(event) {
			if ($('#testtaginput').tagsinput('input')[0].value.length > 0)
				event.cancel = true;
			  // event.item: contains the item
			  // event.cancel: set to true to prevent the item getting removed
		});

		$('#testtaginput').tagsinput('input')[0].type = 'email'

       vm.emailsPending = [""];
       vm.emailMessage = "";

       vm.sendEmail = function (form) {
       	vm.emailsPending = $('#testtaginput').tagsinput('items')
       	vm.emailSubject = $('#emailSubject').val();
       	//Send The Email
       	if (form.$valid && vm.emailsPending.length > 0){
       		$('#sendPublicSampleButton').attr("disabled", true);
       		PublicView.sendEmail({
       			emails: vm.emailsPending,
       			message: vm.emailMessage,
       			public_link: vm.getEmbedLink(),
       			subject: vm.emailSubject,
       			customer_hash: vm.customerData.hash
       		}, onSuccess, onError);
       	}
       	else {
       		if (vm.emailsPending.length == 0)
       			$('#emailValidation').show();
       		return
       	}
       	function onSuccess(data, headers) {
       		$('#emailModal').modal('hide');
       		$('#emailModalSuccess').modal('show');
       		$('#sendPublicSampleButton').attr("disabled", false);

	        }

	        function onError(error) {
	        	AlertService.error(error.data.message);
	        	vm.emailsPending = [""];
	            vm.emailMessage = "";
	            $('#sendPublicSampleButton').attr("disabled", false);

	        }
       }

       $('#emailModalSuccess').on('hidden.bs.modal', function() {
	       	$('#testtaginput').tagsinput('removeAll');
	       	$('#testtaginput').tagsinput('input')[0].placeholder = 'email@example.com';
	   });

    }

})();
