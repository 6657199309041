(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('CountryState', CountryState);

    CountryState.$inject = ['$resource'];

    function CountryState ($resource) {
        var resourceUrl =  'api/countries/:id/states';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
