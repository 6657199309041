(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('order-details', {
            parent: 'entity',
            url: '/order-details/{orderId}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Order Details'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/order-details/order-details.html',
                    controller: 'OrderDetailsController',
                    controllerAs: 'vm'
                }
            },
        });
    }

})();
