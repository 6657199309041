(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customermanage', {
            parent: 'entity',
            url: '/customer-manage',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Customer-Manage'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/customer-manage/customer-manage.html',
                    controller: 'CustomerManageController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('customermanage.new', {
                    parent: 'customermanage',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_USER']

                    },
                    onEnter: ['$stateParams','$state', '$uibModal', function($stateParams,$state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/pages/customer-manage/dialog.customer-manage.html',
                            controller: 'DialogCustomerManageController',
                            controllerAs: 'vm',
                            size: 'lg',
                            resolve: {

                                entity: function () {
                                    return {
                                        id: null,
                                        name: null,
                                        title: null,
                                        phone: null,
                                        email: null,
                                        receive_invoice: null,
                                        receive_coa: null,
                                        first_name: null,
                                        last_name: null,
                                        mobile: null,
                                        address: null,
                                        managedUser: null
                                    };
                                }
                            }
                        }).result.then(function() {
                            $state.go('customermanage', null, { reload: true });
                        }, function() {
                            $state.go('customermanage');
                        });
                    }]
                })
                .state('customermanage.edit', {
                            parent: 'customermanage',
                            url: '/{id}/edit',
                            data: {
                                authorities: ['ROLE_USER']
                            },
                            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                                $uibModal.open({
                                    templateUrl: 'app/pages/customer-manage/dialog.customer-manage.html',
                                    controller: 'DialogCustomerManageController',
                                    controllerAs: 'vm',
                                    size: 'lg',
                                    resolve: {
                                        entity: ['Contact', function(Contact) {
                                            return Contact.get({id : $stateParams.id}).$promise;
                                        }]
                                    }
                                }).result.then(function() {
                                    $state.go('customermanage', null, { reload: 'customermanage' });
                                }, function() {
                                    $state.go('^');
                                });
                            }]
                        })
                .state('customermanage.delete', {
                            parent: 'customermanage',
                            url: '/{id}/delete',
                            data: {
                                authorities: ['ROLE_USER']
                            },
                            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                                $uibModal.open({
                                    templateUrl: 'app/pages/customer-manage/contact-delete-dialog.html',
                                    controller: 'CustomerContactDeleteController',
                                    controllerAs: 'vm',
                                    size: 'md',
                                    resolve: {
                                        entity: ['Contact', function(Contact) {
                                            return Contact.get({id : $stateParams.id}).$promise;
                                        }]
                                    }
                                }).result.then(function() {
                                    $state.go('customermanage', null, { reload: 'customermanage' });
                                }, function() {
                                    $state.go('^');
                                });
                            }]
                        });
    }

})();
