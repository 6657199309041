(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('PaymentDetailsController', PaymentDetailsController);

    PaymentDetailsController.$inject = ['$stateParams', 'PaymentById', 'Customer', 'PaymentReceipt', 'AlertService'];

    function PaymentDetailsController ($stateParams, PaymentById, Customer, PaymentReceipt, AlertService) {

        var vm = this;
        vm.static = {
            anresco_address: ['Anreso Laboratories', '1375 Van Dyke Avenue', 'San Francisco, CA 94124']
        }

        PaymentById.getPayment({paymentId: $stateParams.paymentId},
            function(data) {
                vm.payment = data;
                getCustomer(data.customer_id);
                processInvoicesDate();
                processAppliedAmounts();

            },
            function(e) {
                console.log(e);
            }
        )

        function processAppliedAmounts() {
            var invoice_index = null;

            vm.payment.payment_invoices.forEach( function(payment_invoice) {
                invoice_index = findWithAttr(vm.payment.invoices, 'id', payment_invoice.invoice_id);

                if(invoice_index != -1) {
                    vm.payment.invoices[invoice_index].applied_amount = payment_invoice.applied_amount;
                }
            });
        }

        function processInvoicesDate() {
            var date_array = null;
            vm.payment.invoices.forEach( function(invoice) {
                date_array = invoice.invoice_date ? invoice.invoice_date.split('-') : null;

                if(date_array) {
                    invoice.invoice_date = date_array[1] + '/' + date_array[2] + '/' + date_array[0];
                }

                date_array = null;
            });
        }

        function parseAddress(addressString) {
            vm.customer.procesed_address = addressString ? addressString.split('\n') : [];
        }

        function getCustomer(id) {
            Customer.get({id: id},
                function(data) {
                    vm.customer = data;
                    parseAddress(data.address);
                },
                function(e) {
                    console.log(e);
                }
            )
        }

        vm.downloadPaymentReceipt = function() {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            PaymentReceipt.get({id: vm.payment.id},
                function(data){
                    var url = window.URL.createObjectURL(data.data);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = 'Payment_Receipt_' + vm.payment.id + '.pdf';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                },
                function(e) {
                    console.log(e)
                }
            );
        }

        vm.printPaymentReceipt = function() {
            PaymentReceipt.merged({payments: [vm.payment.id]},
                function (data, headers) {
                    if (data.data.byteLength == 0){
                        return;
                    }
                    var url = URL.createObjectURL(data.data);

                    setTimeout(function() {
                        window.open(url, '_blank');
                    }, 200);

                },
                function (e) {
                    AlertService.error("Could not print payments");
                }
            );
        }
    }

    function findWithAttr(array, attr, value) {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
})();
