(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils($filter) {

        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            utcToLocal: utcToLocal,
            dateformat: dateformat
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }
        
        // Converts a datetime from server to client's local timezone. Date parameter must have format "2019-01-01T00:00:00"
        function utcToLocal(date) {
        	if (date) {
        		if (date.indexOf('Z') === -1 && date.indexOf('+') === -1) date += 'Z';
        		return new Date(date);
        	}
        	return null;
        }
        

        function dateformat() {
            return 'yyyy-MM-dd';
        }
    }

})();
