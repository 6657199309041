(function() { 
    'use strict'; 
    angular 
        .module('anrescowebApp') 
        .factory('OrderAttachDownload', OrderAttachDownload); 
 
    OrderAttachDownload.$inject = ['$resource']; 
 
    function OrderAttachDownload ($resource) { 
        var resourceUrl =  '/api/customer-orders/attachment/:id'; 
 
        return $resource(resourceUrl, {id_hash: '@id_hash'}, { 
            'query': { method: 'GET', isArray: true}, 
            'get': { 
                method: 'GET', 
                responseType: 'blob', 
                //params: {id_hash: '@id_hash'}, 
                transformResponse: function(data) { 
                    return { data: data } 
                } 
            } 
        }); 
    } 
})(); 
 