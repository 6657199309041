(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$rootScope'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, $rootScope) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAccbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.toggleAccbar = toggleAccbar;
        vm.collapseAccbar = collapseAccbar;
        vm.$state = $state;

        $rootScope.showSearch = false;
        vm.search = search;
        vm.toggleSearch = toggleSearch;
        vm.closeSearch = closeSearch;


        function login() {
            collapseNavbar();
            //LoginService.open();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function toggleAccbar() {
            vm.isAccbarCollapsed = !vm.isAccbarCollapsed;
        }

        function collapseAccbar() {
            vm.isAccbarCollapsed = true;
        }

        function search() {
            $rootScope.searchVm.search(vm.searchQuery);
        }

        function toggleSearch() {
            vm.searchQuery = null;
            $rootScope.showSearch = true;
            $rootScope.$broadcast("focus-search");
            $rootScope.class = "open-search";
        }

        function closeSearch() {
            $rootScope.showSearch = false;
            if (vm.searchQuery) {
                vm.searchQuery = null;
                $rootScope.searchVm.clear();
            }
            $rootScope.class = "close-search";
        }

        $rootScope.$on('$viewContentLoaded', function() {
          angular.element(document).ready(function() {

            $('.scrollbox').enscroll({
              showOnHover: false,
              verticalTrackClass: 'track',
              verticalHandleClass: 'handle'
            });

          });
        });
    }
})();
