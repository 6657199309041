(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('SampleLogDialogController', SampleLogDialogController);

    SampleLogDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Sample', 'ProductType', 'CustomerOrder'];

    function SampleLogDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Sample, ProductType, CustomerOrder) {
        var vm = this;

        vm.sample = entity;
        vm.clear = clear;
        vm.save = save;
        vm.producttypes = ProductType.query({filter: 'sample-is-null'});
        $q.all([vm.sample.$promise, vm.producttypes.$promise]).then(function() {
            if (!vm.sample.productType || !vm.sample.productType.id) {
                return $q.reject();
            }
            return ProductType.get({id : vm.sample.productType.id}).$promise;
        }).then(function(productType) {
            vm.producttypes.push(productType);
        });
        vm.customerorders = CustomerOrder.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.sample.id !== null) {
                Sample.update(vm.sample, onSaveSuccess, onSaveError);
            } else {
                Sample.save(vm.sample, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('anrescowebApp:sampleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
