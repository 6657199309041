(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('NavbarHeaderController', NavbarHeaderController);

    NavbarHeaderController.$inject = [ '$state', '$stateParams', '$timeout', '$filter', '$sce','focus',
                                        'Auth', 'Principal', 'ProfileService','$location', '$rootScope', '$scope',
                                        '$http', '$localStorage', 'NotificationService', 'Contact2ForUser', 'STAGE', '$translate'];

    function NavbarHeaderController ($state,  $stateParams, $timeout, $filter, $sce, focus,
                                    Auth, Principal, ProfileService, $location, $rootScope, $scope,
                                    $http, $localStorage, NotificationService, Contact2ForUser, STAGE, $translate) {

        var vm = this;
        $translate.use($localStorage.language=="Japanese" ? 'jp' : 'en');
        $rootScope.showSearch = false;
        vm.$state = $state;
        vm.location = $location.path();
        vm.isAuthenticated = true;
        vm.url = $rootScope.toState.name;
        vm.usersList = [];
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        $scope.to_trusted = function(html_code) {
            return $sce.trustAsHtml(html_code);
        }

        vm.logoutCompletely = logoutCompletely

        function logoutCompletely() {
            $localStorage.removeItem('oldUserFirstName')
            $localStorage.removeItem('oldUserLogin')
            logout();
        }


        vm.login = login;
        vm.logout = logout;
        vm.search = search;
        vm.getTopNotifications = getTopNotifications;

        vm.toggleSearch = toggleSearch;
        vm.closeSearch = closeSearch;

        $rootScope.isActive = false;
        $rootScope.toggleSidebar = function() {
             $rootScope.isActive = !$rootScope.isActive;
        }

// YEET: Hiding for now
//        setInterval(function(){
//            if(Principal.isAuthenticated()){
//                getTopNotifications();
//            }
//        }, 60000);

        vm.customers = [];
        Contact2ForUser.get({}, function(res){
        	vm.customers = [];
        	if (res) {
                vm.contact = res;
                if(vm.contact.first_name) {
                    vm.contact.first_name_letter = vm.contact.first_name[0].toUpperCase()
                }
                if(vm.contact.last_name) {
                    vm.contact.last_name_letter = vm.contact.last_name[0].toUpperCase()
                }


        		for (var i = 0; i < res.contactCustomers.length; i++) {
        			vm.customers.push(res.contactCustomers[i].customer);
        			if ($localStorage.customerId == res.contactCustomers[i].customer.id) {
                        vm.selectedCustomer = res.contactCustomers[i].customer;
        				$('#companyPageButton').prop('href', "#/companypage?hash=" + vm.selectedCustomer.hash)
        			}
        		}
        	}
        });

        vm.switchCustomer = function(customer) {
            vm.selectedCustomer = customer;
            $localStorage.customerId = customer.id;
            $rootScope.customer = {};
            $rootScope.customer.customer_type = customer.businessType ? customer.businessType.name : 'Cannabis';
            $rootScope.customer.customer_discount = customer.customer_discount ? customer.customer_discount :  null;
            $localStorage.language = customer.language ? customer.language : 'English';
            $state.reload();
        }

        function getTopNotifications() {
            NotificationService.getTopNotifications({}, onSuccess, onError);

            function onSuccess(data, headers) {
                $rootScope.topNotifications = data;

                    if(parseInt($state.params.page) === 1){

                    if($rootScope.allNotifications){
                        for( var i = 0; i < $rootScope.topNotifications.length; i++ ) {


                            const resoult = $rootScope.allNotifications.filter( function(element) {
                               return element.id === $rootScope.topNotifications[i].id
                            } )


                            if(resoult.length === 0) {
                                $rootScope.allNotifications.unshift($rootScope.topNotifications[i])
                                $rootScope.allNotifications.pop()
                            }
                        }
                    }

                }

                $rootScope.newNotification =  $filter('filter')( $rootScope.topNotifications, {notificationRead: false}).length
            }

            function onError(error) {}
        }

        $scope.notificationSettings = function(){
            $rootScope.notificationTab = 2;
            $location.path('customer-manage');
        }

        $scope.readNotification = function(id){
                $http.put('api/notifications/mark-as-read/'+id).then(
                    function(response) {

                        for( var i = 0; i < $rootScope.topNotifications.length; i++ ) {
                            if ($rootScope.topNotifications[i].id === id ){
                                $rootScope.topNotifications[i] = response.data;
                            }
                        }

                        for( var j = 0; j < $rootScope.allNotifications.length; j++ ) {
                            if ($rootScope.allNotifications[j].id === id ){
                                $rootScope.allNotifications[j] = response.data;
                            }
                        }

                        $rootScope.newNotification =  $filter('filter')( $rootScope.topNotifications, {notificationRead: false}).length
                    }
            );
        }

		$scope.toggleGalleryView = function (galleryState) {
        	$rootScope.galleryView = galleryState;
        };

        /*
        $rootScope.showPublicViewMenu = false;

        $scope.toggleShowPublicViewMenu = function () {
        	$rootScope.showPublicViewMenu = !$rootScope.showPublicViewMenu;
        };
        */

        $scope.markAllAsRead = function(){
            NotificationService.markAllAsRead({}, onSuccess, onError);
            function onSuccess(data, headers) {
                $rootScope.topNotifications = data;

                for( var j = 0; j < $rootScope.topNotifications.length; j++ ) {
                    $rootScope.topNotifications[j].topNotifications = true;
                }

                $scope.newNotification =  $filter('filter')( $rootScope.topNotifications, {notificationRead: false}).length
            }
            function onError(error) {}
        }


        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function search() {
            $rootScope.searchVm.search(vm.searchQuery);
        }

        function toggleSearch() {
            vm.searchQuery = null;
            $rootScope.showSearch = true;
            $rootScope.$broadcast("focus-search");
            $rootScope.class = "open-search";
        }

        function closeSearch() {
            $rootScope.showSearch = false;
            if (vm.searchQuery) {
                vm.searchQuery = null;
                $rootScope.searchVm.clear();
            }
            $rootScope.class = "close-search";
        }

        function logout() {
            NotificationService.markAllAsRead({}, callback, callback);
            function callback(data, headers) {
                collapseNavbar();
                $localStorage.$reset();
                Auth.logout();
                $state.go('login', {reload:true});
                vm.customers = [];
            }
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        $rootScope.$on('$viewContentLoaded', function() {
          angular.element(document).ready(function() {

            $('.scrollbox').enscroll({
              showOnHover: false,
              verticalTrackClass: 'track',
              verticalHandleClass: 'handle'
            });

          });

        });

    }

})();

$(document).click(function(e) {


	  if( e.target.id != 'publicPageMenu' && e.target.id != 'selectPublicAction' ) {
	    $("#publicPageMenu").hide();
	  }
});