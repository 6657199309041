(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Sample', Sample);

    Sample.$inject = ['$resource'];

    function Sample ($resource) {
        var resourceUrl =  'api/samples/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': { method:'PUT' },
            passFailCount:{
            	url: 'api/samples/passfail/count',
            	method: 'GET'
            }
        });
    }
})();
