(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('FileUpload', FileUpload);

  FileUpload.$inject = ['$http'];

    function FileUpload($http) {
        this.uploadFileToUrl = function(file, uploadUrl){
            var fd = new FormData();
            /*
             *notice 1st param 'file', which will be used to
             * rettrive file from the java class.
             */
            fd.append('file', file);
            $http.post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
            .success(function(response){
            } )
            .error(function(error){
            });


        }

            return this;

    }
})();
