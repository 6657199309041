//(function() {
//    'use strict';
//    angular.module('anrescowebApp').factory('CustomerPublicService', CustomerPublicService);
//
//    CustomerPublicService.$inject = ['$resource'];
//
//    function CustomerPublicService ($resource) {
//        var resourceUrl =  'api/public/customers/:dbaName';
//
//        return $resource(resourceUrl, {}, {
//            'query': { method: 'GET',
//                isArray: false
//            },
//            'getInformationByCompanyName': {
//                method: 'GET',
//                url: 'api/public/customers/:name',
//                isArray: false
//            },
//            'searchCustomer': {
//                method: 'GET',
//                url: 'api/customers/search/:searchString',
//                isArray: true
//            }
//        });
//    }
//})();


(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('CustomerPublicService', CustomerPublicService);

    CustomerPublicService.$inject = ['$resource'];

    function CustomerPublicService ($resource) {

        return $resource('api/public/customers/:dbaName', {dbaName: '@dbaName'}, {
            query: { method: 'GET', isArray: true },
            get: {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });

    }

})();
