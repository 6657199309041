//https://stackoverflow.com/questions/21628378/angularjs-display-blob-pdf-in-an-angular-app
angular.module('anrescowebApp')

    .factory('SampleCoaPdfService',['$http', function ($http) {
             return {
                 downloadPdf: function (hash) {
                 return $http.get('api/public/samples/coa/' + hash, { responseType: 'arraybuffer', timeout: 300000 }).then(function (response) {
                     return response;
                 });
             }
         };
     }]);
