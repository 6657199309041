(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('OrdersController', OrdersController);

OrdersController.$inject = ['$state', '$stateParams','$scope','$rootScope', '$filter', 'CustomerOrder','CustomerOrderSearch',
    'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','Auth', 'Principal', 'ProfileService',
    'LoginService','CustomerByUser', 'OrderAttach', 'OrderAttachDownload'];

    function OrdersController($state, $stateParams, $scope,$rootScope, $filter, CustomerOrder,CustomerOrderSearch, ParseLinks,
                              AlertService, paginationConstants, pagingParams, Auth, Principal, ProfileService,
                              LoginService,CustomerByUser, OrderAttach, OrderAttachDownload) { /*,Auth, Principal, ProfileService, LoginService*/

        var vm = this;
        vm.state = {};
        $rootScope.showSearch = pagingParams.search;
        vm.search = search;
        vm.clear = clear;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
    	vm.queryParams = $stateParams.queryParams;

        $scope.getAttachments = function (order,id) {
	        vm.getAttachmentsLoader = true;
	        vm.idOfSelectedOrder = id;

	        if(order.attachments != null){
		        vm.getAttachmentsLoader = false;
		        return;
	        }

	        OrderAttach.query({id: id}, onSuccess, onError);
	        function onSuccess(data, headers) {
		        vm.getAttachmentsLoader = false;
		        order.attachments = data;
	        }
	        function onError(error) {
	        	AlertService.error(error.data.message);
	        }
        }

		$scope.downloadAttachment = function (id_hash,order) {
		    OrderAttachDownload.get({id: id_hash}, onSuccess, onError);

		    function onSuccess(data, headers) {
	            var a = document.createElement("a");
	            document.body.appendChild(a);
	            a.style = "display: none";

	            var fileURL = window.URL.createObjectURL(data.data);
	            a.href = fileURL;
	            a.download = headers('content-disposition').substring(21);
	            a.click();
		        order.select = "";
		    }

		    function onError(error) {

		    }
		}

        $scope.itemArray = [
            {id: 0, name: 'All', value: 'ALL'},
            {id: 1, name: 'Created', value: 'CREATED'},
            {id: 2, name: 'Samples Received', value: 'SAMPLES_RECEIVED'},
            {id: 3, name: 'Preliminary Results', value: 'PRELIMINARY_RESULTS'},
            {id: 4, name: 'Completed', value: 'COMPLETED'},
            {id: 5, name: 'Retesting', value: 'RETESTING'}
            // {id: 5, name: 'Invoiced', value: 'INVOICED'}
        ];

        $scope.selected = { value: $scope.itemArray[0] };

        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "Orders";
            $rootScope.searchVm = vm;
        }

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.itemsPerPage = "20";
        vm.state = "ALL";
        if(pagingParams.items != null){
            vm.itemsPerPage = pagingParams.items;
        }

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.setpagesize = setpagesize;
        vm.setFilter = setFilter;

        if(!vm.isAuthenticated()){
             $state.go('login');
        }

        if ($rootScope.sampleOrderFilter) {
            vm.state = $rootScope.sampleOrderFilter.value;
            $scope.selected = { value: $rootScope.sampleOrderFilter };
        }

        // Datepicker
        vm.datePickerOpenStatus = {};

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // Order Filters
        vm.toggleWidget = function(name) {
        	vm.activeWidget = name === vm.activeWidget ? null : name;
        }

        vm.listFilters = {
        		"1 day (rush)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"2 day (rush)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"3 day (rush)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"4-5 day (standard)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"TATOther" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},

        		"CREATED": {
        			selected: false,
        			field: "states"
        		},
        		"SAMPLES_RECEIVED": {
        			selected: false,
        			field: "states"
        		},
        		"PRELIMINARY_RESULTS": {
        			selected: false,
        			field: "states"
        		},
        		"COMPLETED": {
        			selected: false,
        			field: "states"
        		}
        }
        vm.toggleListFilter = function(field) {
        	var values = [];

        	for (var key in vm.listFilters) {
        		if (vm.listFilters[key].field === field && vm.listFilters[key].selected) {
        			values.push(key);
        		}
        	}
        	vm.orderFilters[field] = values;
        }

        vm.orderFilters = {
        		id: pagingParams.id,
        		sampleCount: pagingParams.sampleCount,
        		turnaroundTimes: pagingParams.turnaroundTimes,
        		dateReceivedLower: pagingParams.dateReceivedLower ? convertLocalDateFromServer(pagingParams.dateReceivedLower) : null,
        		dateReceivedUpper: pagingParams.dateReceivedUpper ? convertLocalDateFromServer(pagingParams.dateReceivedUpper) : null,
        		states: pagingParams.states
        }
        if (pagingParams.turnaroundTimes) {
        	if (Array.isArray(pagingParams.turnaroundTimes)) {
            	for (var i = 0; i < pagingParams.turnaroundTimes.length; i++) {
            		if (vm.listFilters[pagingParams.turnaroundTimes[i]]) {
            			vm.listFilters[pagingParams.turnaroundTimes[i]].selected = true;
            		}
            	}
        	} else {
        		vm.listFilters[pagingParams.turnaroundTimes].selected = true;
        	}
        }
        if (pagingParams.states) {
        	if (Array.isArray(pagingParams.states)) {
            	for (var i = 0; i < pagingParams.states.length; i++) {
            		if (vm.listFilters[pagingParams.states[i]]) {
            			vm.listFilters[pagingParams.states[i]].selected = true;
            		}
            	}
        	} else {
        		vm.listFilters[pagingParams.states].selected = true;
        	}

        }



        vm.applyFilters = applySampleFilters;
        function applySampleFilters() {
        	// Parse filter criteria and add paging params
        	var params = createFilterQueryParams();

        	// Send query
            Order.filter(params, onSuccess, onError);
            function onSuccess(result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.orders = result;
                vm.page = 0;
                vm.pagingParams = pagingParams;
            }
            function onError(error) {
            	console.log("Error applying filter");
            	console.log(error);
            }
        }

        function getFilterCount() {
        	var totalFilters = 0;
        	for (var key in vm.orderFilters) {
        		if (vm.orderFilters[key] !== null && vm.orderFilters[key] !== "" && vm.orderFilters[key] !== undefined && vm.orderFilters[key] !== false) {
        			if (Array.isArray(vm.orderFilters[key])) {
        				if (vm.orderFilters[key].length > 0) totalFilters++;
        			} else {
        				totalFilters++;
        			}
        		}
        	}
        	vm.totalFilters = totalFilters;
        	return totalFilters;
        }
        getFilterCount();
        vm.getFilterCount = getFilterCount;
        vm.filterSubmit = function() {
        	vm.page = 1;
        	vm.transition();
        }

        function createFilterQueryParams() {
        	var params = {};
            var dateFields = ["dateReceivedLower", "dateReceivedUpper"];
        	for (var key in vm.orderFilters) {
        		if (dateFields.indexOf(key) !== -1) {
         			params[key] = convertLocalDateToServer(vm.orderFilters[key]);

         		} else if (vm.orderFilters[key] !== "" && vm.orderFilters[key] !== null && vm.orderFilters[key] !== undefined) {
        			params[key] = vm.orderFilters[key];
        		}
        	}

        	params.page = vm.page;
        	params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            params.size = vm.itemsPerPage;
            params.items = params.size;
            params.search = vm.currentSearch;

        	return params;
        }


        loadAll();

        function loadAll () {

        	if (vm.totalFilters > 0) {
				 var params = createFilterQueryParams();
				 params.page = pagingParams.page - 1;
				 CustomerOrder.filter(params, onSuccess, onError);

        	} else if (pagingParams.search) {
                CustomerOrderSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            else {
                CustomerOrder.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    state: vm.state
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'dateReceived') {
                    result.push('dateReceived');
                }

                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customerOrders = data;
                vm.page = pagingParams.page;

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getCustomerData () {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                 vm.customerData = data;
            }
            function onError(error) {

            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

//        function transition() {
//            $state.transitionTo($state.$current, {
//                page: vm.page,
//                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
//                search: vm.currentSearch,
//                items: vm.itemsPerPage,
//                state: vm.state
//            });
//        }

        function transition() {
        	var params = createFilterQueryParams();

            $state.transitionTo($state.$current, params);
        }

        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function setpagesize(pagesize) {
            vm.itemsPerPage = pagesize;
            loadAll ();
        }

        function setFilter(filter) {
            vm.page = 0;
            pagingParams.page = 0;
            $stateParams['page']= 0;
            $rootScope.sampleOrderFilter =  filter;
            vm.state = filter.value;
            loadAll ();
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'dateReceived';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.orderFilters = {};
            vm.transition();
        }

        /* Date Utils (import DateUtils service doesn't work) */
        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function padToTwo(number) {
            return `0${number}`.slice(-2);
          }

        function formatDate(date) {
            var month = date.getUTCMonth() + 1;
            var day = date.getUTCDate();
            var year = date.getUTCFullYear();

            return `${padToTwo(month)}/${padToTwo(day)}/${year}`;
        }

        vm.parseDueDate = function (order) {
            var current_due_date = null;
            var sample = null;
            var tests = null;
            var test = null;

            for(var sample_index = 0; sample_index < order.samples.length; sample_index++) {
                sample = order.samples[sample_index];
                tests = order.samples[sample_index].sampleTests;

                for(var test_index = 0; test_index < tests.length; test_index++) {
                    test = tests[test_index];
                    if(current_due_date == null) current_due_date = test.estimatedCompleteDate;
                    if(test.estimatedCompleteDate != current_due_date) return "Various";
                }
            }

            if(!current_due_date) return "Not Set";

			try {
                current_due_date = new Date(current_due_date);
                if(current_due_date == 'Invalid Date') throw 'Invalid Date'

				return formatDate(current_due_date);
			}
			catch(e) {
				console.log(e);
                return current_due_date;
			}
        }

        angular.element(document).ready(function() {
            setTimeout(function () {

                $("#table tr:first > th").each(function(index, element) {
                    index++;
                    var addLi = "<li class='tbl-dot-" + index + "'><span class='tbl-dot'></span></li>";
                    $("ul.tbl-dots").append(addLi);
                });

                var num_col = $("#table tr:first > th").length;
                var constant = 2;
                var constant2 = constant + 1;

                var col_width = 100 / num_col;
                $("#table tr:first > th").css("width", col_width+"%");

                $("#col-prev").click(function() {
                    constant -= 1;
                    constant2 -= 1;
                    if (constant == 2 || constant2 == 2) {
                        $("#col-prev").attr('disabled', 'disabled');
                        $("#col-next").prop("disabled", false);
                    }
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    var x = constant2 + 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if(constant == 2 || constant2 >= 2){
                        $("#col-next").prop("disabled", false);
                    }
                });

                $("#col-next").click(function() {
                    constant += 1;
                    constant2 += 1;
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    $('.tbl-dot-' + constant2).removeClass('disabled').addClass('active');
                    var x = constant - 1;
                    var x2 = constant2 - 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if (constant >= num_col || constant2 >= num_col) {
                        $("#col-next").attr('disabled', 'disabled');
                        $("#col-prev").prop("disabled", false);
                    }
                    $("#col-prev").prop("disabled", false);
                });

            }, 1000);
        });
        
        vm.SampleDetail = function(samples){
            $('#rdAddRequestedTest').modal('show');
            vm.modalsamples = samples
        }
        vm.redirectSamples = function(orderId){
            $('#rdAddRequestedTest').modal('hide');
        	$state.go('samplelog', {orderId});
        }
        vm.openPublicSample = function(event, jsEvent, view) {
        	$state.go('public', {hash: event.hash});
        }
    }
})();
