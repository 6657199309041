(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice-details', {
            parent: 'entity',
            url: '/invoice-details/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Invoices Details'
            },
            views: {
               'content@': {
                    templateUrl: 'app/pages/invoice-details/invoice-details.html',
                    controller: 'InvoiceDetailsController',
                    controllerAs: 'vm'
                }
            },
        })
    }

})();
