(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('PaymentReceipt', PaymentReceipt);

    PaymentReceipt.$inject = ['$resource'];

    function PaymentReceipt ($resource) {
        var resourceUrl =  '/api/payment';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                url: 'api/payment/receipt/print',
                responseType: 'blob',
                transformResponse: function (data) {
                    return { data: data };
                }
            },
            'merged': {
                method: 'GET',
                url: '/api/payment/receipt/print/merged',
                responseType: 'blob',
                transformResponse: function (data) {
                    return { data: data };
                }
            }
        });
    }
})();
