(function() {
    'use strict';
    angular
        .module('anrescowebApp')
        .factory('Weedmaps', Weedmaps);

    Weedmaps.$inject = ['$resource'];

    function Weedmaps ($resource) {
        var resourceUrl =  'api/sample/:id/publish/weedmaps';

        return $resource(resourceUrl, {id: "@id"}, {
            'publish': { method:'POST' }
        });
    }
})();
