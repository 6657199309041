(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('notifications', {
            parent: 'entity',
            url: '/notifications?page&type',
            data: {
              authorities: ['ROLE_USER'],
              pageTitle: 'Notifications'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/notifications/notifications.html',
                    controller: 'NotificationsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                search: null,
                state: {
                    value: 'ALL',
                    squash: true
                },
                items: null

            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        items: $stateParams.items
                    };
                }],
            }
        });
    }
})();
